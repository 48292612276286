import { lazy } from "react";
import { SignInBtn, SignUpBtn, SignOutBtn, ProfileBtn } from "./ActionBtns";
import RequireAuth from "./RequireAuth";

const SignTemplate = lazy(() => import("./SignTemplate"));
const SignInForm = lazy(() => import("./SignInForm"));
const SignUpForm = lazy(() => import("./SignUpForm"));

export {
  RequireAuth,
  SignTemplate,
  SignInForm,
  SignUpForm,
  SignInBtn,
  SignUpBtn,
  ProfileBtn,
  SignOutBtn,
};

import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./HomeSection.scss";
import { toggleSnackbar } from "../../redux/reducers/snackbar";
import { useDispatch } from "react-redux";
import { useGenerateCheckoutMutation } from "../../redux/packagesApi";

function PackagesSection() {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();
  const packages = [
    {
      id: 1,
      type: "package_1",
      title: "Single package",
      price: "$20 USD",
      list: [
        "20 treatment plans",
        "Prosthetic module",
        "Therapeutic module",
        "Orthodontic module",
        "Aesthetic module",
        "Media library",
        "Automatic creation of PDF treatment plan",
        "View a 3D treatment plan via the link",
      ],
    },
    {
      id: 2,
      type: "package_2",
      title: "Family package",
      price: "$250 USD",
      list: [
        "300 treatment plans",
        "Prosthetic module",
        "Therapeutic module",
        "Orthodontic module",
        "Aesthetic module",
        "Media library",
        "Automatic creation of PDF treatment plan",
        "View 3D treatment plan by reference",
        "Adding family members to your account",
        "Access for your doctor to the professional version",
        "Access to all improvements and updates",
      ],
    },
    {
      id: 3,
      type: "package_3",
      title: "Golden package",
      price: "$500 USD",
      list: [
        "Unlimited Treatment Plans 10 years",
        "Prosthetic module",
        "Therapeutic module",
        "Orthodontic module",
        "Aesthetic module",
        "Media library",
        "Automatic creation of PDF treatment plan",
        "View 3D treatment plan by reference",
        "Adding family members to your account",
        "Access for your doctor to the professional version",
        "Access to all improvements and updates",
        "Your wishes to improve the application in priority",
      ],
    },
  ];

  const [generateCheckout, { isLoading }] = useGenerateCheckoutMutation();
  const [checkoutUrl, setCheckoutUrl] = useState("");

  function onPackageSelect(packId) {
    generateCheckout(packId)
      .unwrap()
      .then((response) => {
        setCheckoutUrl(response.data.checkout_url);
        setTimeout(() => {
          window.open(response.data.checkout_url, "_blank");
        }, 500);

        dispatch(
          toggleSnackbar({
            isShow: true,
            type: "success",
            message: _t("Order was successfully updated"),
          })
        );
      })
      .catch((error) => {
        dispatch(
          toggleSnackbar({
            isShow: true,
            type: "error",
            message: _t("Error with order creation"),
          })
        );
      });
  }

  return (
    <Grid container spacing={2}>
      {packages.map((pack) => (
        <Grid key={pack.id} item xs={12} md={4}>
          <Card sx={{ position: "relative" }}>
            <CardMedia
              component="img"
              height="140"
              image={require(`./assets/package_${pack.id}.png`)}
              alt=""
            />
            <Typography
              variant="h4"
              component="div"
              sx={{
                position: "absolute",
                top: 50,
                left: 20,
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {pack.title}
            </Typography>
            <CardContent>
              <Typography variant="h6" component="div" gutterBottom>
                {pack.price}
              </Typography>
              <Typography variant="subtitle" component="div">
                {_t("Included:")}
              </Typography>
              <List dense>
                {pack.list.map((el) => (
                  <ListItem key={el}>
                    <ListItemText primary={`- ${el};`} />
                  </ListItem>
                ))}
              </List>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  onPackageSelect(pack.type);
                }}
              >
                {_t("Buy now")}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default PackagesSection;

export const toothTypes = [{
  sort: 8,
  sortRender: 9,
  name: "11",
  position: "top",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 278,
      y: 11,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    decayedTooth: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    cariousTooth: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    therapyRestoration: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    boneAugmentation: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    emptyTooth: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    dentalBar: {
      x: 265,
      y: 120,
      width: 96,
      height: 30,
      zIndex: 2
    },
    syntheticGum: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    plasticCrown: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    plasticTooth: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    veneerTab: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 2
    },
    implantPosition: {
      x: 298,
      y: 123,
      width: 40,
      height: 41,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 298,
      y: 123,
      width: 40,
      height: 41,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 298,
      y: 123,
      width: 40,
      height: 41,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 298,
      y: 123,
      width: 40,
      height: 41,
      zIndex: 3
    },
    telescopicCrown: {
      x: 298,
      y: 123,
      width: 40,
      height: 41,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 279,
      y: 99,
      width: 74,
      height: 67,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 298,
      y: 123,
      width: 40,
      height: 41,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 298,
      y: 123,
      width: 40,
      height: 41,
      zIndex: 3
    },
    sealedCanal: {
      x: 299,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    stumpTabs: {
      x: 299,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    pulpitis: {
      x: 299,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    badSealedCanal: {
      x: 299,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 299,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    treatedCanal: {
      x: 299,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 299,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    toothRemoval: {
      x: 298,
      y: 123,
      width: 28,
      height: 21,
      zIndex: 3
    }
  }
}, {
  sort: 7,
  sortRender: 10,
  name: "12",
  position: "top",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 198,
      y: 51,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    decayedTooth: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    cariousTooth: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    therapyRestoration: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    boneAugmentation: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    emptyTooth: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    dentalBar: {
      x: 222,
      y: 123,
      width: 71,
      height: 62,
      zIndex: 2
    },
    syntheticGum: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    plasticCrown: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    plasticTooth: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    veneerTab: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 2
    },
    implantPosition: {
      x: 249,
      y: 147,
      width: 33,
      height: 33,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 249,
      y: 147,
      width: 33,
      height: 33,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 249,
      y: 147,
      width: 33,
      height: 33,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 249,
      y: 147,
      width: 33,
      height: 33,
      zIndex: 3
    },
    telescopicCrown: {
      x: 249,
      y: 147,
      width: 33,
      height: 33,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 231,
      y: 126,
      width: 57,
      height: 60,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 249,
      y: 147,
      width: 33,
      height: 33,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 249,
      y: 147,
      width: 33,
      height: 33,
      zIndex: 3
    },
    sealedCanal: {
      x: 250,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    stumpTabs: {
      x: 250,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    pulpitis: {
      x: 250,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    badSealedCanal: {
      x: 250,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 250,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    treatedCanal: {
      x: 250,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 250,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    toothRemoval: {
      x: 249,
      y: 147,
      width: 22,
      height: 21,
      zIndex: 3
    }
  }
}, {
  sort: 6,
  sortRender: 11,
  name: "13",
  position: "top",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 136,
      y: 117,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    decayedTooth: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    cariousTooth: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    therapyRestoration: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    boneAugmentation: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    emptyTooth: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    dentalBar: {
      x: 198,
      y: 151,
      width: 54,
      height: 73,
      zIndex: 2
    },
    syntheticGum: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    plasticCrown: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    plasticTooth: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    veneerTab: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 2
    },
    implantPosition: {
      x: 211,
      y: 186,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 211,
      y: 186,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 211,
      y: 186,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 211,
      y: 186,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 211,
      y: 186,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 188,
      y: 159,
      width: 67,
      height: 69,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 211,
      y: 186,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 211,
      y: 186,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 204,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    stumpTabs: {
      x: 204,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    pulpitis: {
      x: 204,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    badSealedCanal: {
      x: 204,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 204,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    treatedCanal: {
      x: 204,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 204,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    toothRemoval: {
      x: 211,
      y: 186,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 5,
  sortRender: 12,
  name: "14",
  position: "top",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 99,
      y: 177,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    decayedTooth: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    cariousTooth: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    therapyRestoration: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    boneAugmentation: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    emptyTooth: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    dentalBar: {
      x: 179,
      y: 210,
      width: 37,
      height: 79,
      zIndex: 2
    },
    syntheticGum: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    plasticCrown: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    plasticTooth: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    veneerTab: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 2
    },
    implantPosition: {
      x: 185,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 185,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 185,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 185,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 185,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 163,
      y: 216,
      width: 78,
      height: 63,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 185,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 185,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 183,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    stumpTabs: {
      x: 183,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    pulpitis: {
      x: 183,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    badSealedCanal: {
      x: 183,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 183,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    treatedCanal: {
      x: 183,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 183,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    toothRemoval: {
      x: 185,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 4,
  sortRender: 13,
  name: "15",
  position: "top",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 68,
      y: 243,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    decayedTooth: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    cariousTooth: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    therapyRestoration: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    boneAugmentation: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    emptyTooth: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    dentalBar: {
      x: 166,
      y: 266,
      width: 35,
      height: 86,
      zIndex: 2
    },
    syntheticGum: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    plasticCrown: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    plasticTooth: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    veneerTab: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 2
    },
    implantPosition: {
      x: 167,
      y: 283,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 167,
      y: 283,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 167,
      y: 283,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 167,
      y: 283,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 167,
      y: 283,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 146,
      y: 267,
      width: 80,
      height: 65,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 167,
      y: 283,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 167,
      y: 283,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 167,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    stumpTabs: {
      x: 167,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    pulpitis: {
      x: 167,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    badSealedCanal: {
      x: 167,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 167,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    treatedCanal: {
      x: 167,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 167,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    toothRemoval: {
      x: 167,
      y: 283,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 3,
  sortRender: 14,
  name: "16",
  position: "top",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 45,
      y: 317,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    decayedTooth: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    cariousTooth: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    therapyRestoration: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    boneAugmentation: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    emptyTooth: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    dentalBar: {
      x: 154,
      y: 311,
      width: 38,
      height: 116,
      zIndex: 2
    },
    syntheticGum: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    plasticCrown: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    plasticTooth: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    veneerTab: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 2
    },
    implantPosition: {
      x: 147,
      y: 353,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 147,
      y: 353,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 147,
      y: 353,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 147,
      y: 353,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 147,
      y: 353,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 124,
      y: 316,
      width: 97,
      height: 104,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 147,
      y: 353,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 147,
      y: 353,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 146,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    stumpTabs: {
      x: 146,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    pulpitis: {
      x: 146,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    badSealedCanal: {
      x: 146,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 146,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    treatedCanal: {
      x: 146,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 146,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    toothRemoval: {
      x: 147,
      y: 353,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 2,
  sortRender: 15,
  name: "17",
  position: "top",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 28,
      y: 402,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    decayedTooth: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    cariousTooth: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    therapyRestoration: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    boneAugmentation: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    emptyTooth: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    dentalBar: {
      x: 149,
      y: 388,
      width: 26,
      height: 104,
      zIndex: 2
    },
    syntheticGum: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    plasticCrown: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    plasticTooth: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    veneerTab: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 2
    },
    implantPosition: {
      x: 132,
      y: 424,
      width: 41,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 132,
      y: 424,
      width: 41,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 132,
      y: 424,
      width: 41,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 132,
      y: 424,
      width: 41,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 132,
      y: 424,
      width: 41,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 115,
      y: 398,
      width: 87,
      height: 87,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 132,
      y: 424,
      width: 41,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 132,
      y: 424,
      width: 41,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 133,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    stumpTabs: {
      x: 133,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    pulpitis: {
      x: 133,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    badSealedCanal: {
      x: 133,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 133,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    treatedCanal: {
      x: 133,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 133,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    toothRemoval: {
      x: 132,
      y: 424,
      width: 41,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 1,
  sortRender: 16,
  name: "18",
  position: "top",
  side: "right",
  isEighth: true,
  actionBtn: {
    position: {
      x: 22,
      y: 481,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    decayedTooth: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    cariousTooth: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    therapyRestoration: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    boneAugmentation: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    emptyTooth: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    dentalBar: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    // not used
    syntheticGum: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    plasticCrown: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    plasticTooth: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    veneerTab: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 2
    },
    implantPosition: {
      x: 130,
      y: 490,
      width: 35,
      height: 34,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 130,
      y: 490,
      width: 35,
      height: 34,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 130,
      y: 490,
      width: 35,
      height: 34,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 130,
      y: 490,
      width: 35,
      height: 34,
      zIndex: 3
    },
    telescopicCrown: {
      x: 130,
      y: 490,
      width: 35,
      height: 34,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 113,
      y: 470,
      width: 79,
      height: 76,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 130,
      y: 490,
      width: 35,
      height: 34,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 130,
      y: 490,
      width: 35,
      height: 34,
      zIndex: 3
    },
    sealedCanal: {
      x: 130,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    stumpTabs: {
      x: 130,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    pulpitis: {
      x: 130,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    badSealedCanal: {
      x: 130,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 130,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    treatedCanal: {
      x: 130,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 130,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    toothRemoval: {
      x: 130,
      y: 490,
      width: 35,
      height: 34,
      zIndex: 3
    }
  }
}, {
  sort: 9,
  sortRender: 1,
  name: "21",
  position: "top",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 377,
      y: 11,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    decayedTooth: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    cariousTooth: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    therapyRestoration: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    boneAugmentation: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    emptyTooth: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    dentalBar: {
      x: 341,
      y: 120,
      width: 95,
      height: 31,
      zIndex: 2
    },
    syntheticGum: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    plasticCrown: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    plasticTooth: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    veneerTab: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 2
    },
    implantPosition: {
      x: 366,
      y: 123,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 366,
      y: 123,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 366,
      y: 123,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 366,
      y: 123,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 366,
      y: 123,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 352,
      y: 100,
      width: 73,
      height: 68,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 366,
      y: 123,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 366,
      y: 123,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 366,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    stumpTabs: {
      x: 366,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    pulpitis: {
      x: 366,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    badSealedCanal: {
      x: 366,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 366,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    treatedCanal: {
      x: 366,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 366,
      y: 126,
      width: 39,
      height: 29,
      zIndex: 3
    },
    toothRemoval: {
      x: 366,
      y: 123,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 10,
  sortRender: 2,
  name: "22",
  position: "top",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 460,
      y: 51,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    decayedTooth: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    cariousTooth: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    therapyRestoration: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    boneAugmentation: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    emptyTooth: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    dentalBar: {
      x: 409,
      y: 123,
      width: 72,
      height: 66,
      zIndex: 2
    },
    syntheticGum: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    plasticCrown: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    plasticTooth: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    veneerTab: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 2
    },
    implantPosition: {
      x: 421,
      y: 146,
      width: 34,
      height: 33,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 421,
      y: 146,
      width: 34,
      height: 33,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 421,
      y: 146,
      width: 34,
      height: 33,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 421,
      y: 146,
      width: 34,
      height: 33,
      zIndex: 3
    },
    telescopicCrown: {
      x: 421,
      y: 146,
      width: 34,
      height: 33,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 416,
      y: 126,
      width: 59,
      height: 60,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 421,
      y: 146,
      width: 34,
      height: 33,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 421,
      y: 146,
      width: 34,
      height: 33,
      zIndex: 3
    },
    sealedCanal: {
      x: 423,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    stumpTabs: {
      x: 423,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    pulpitis: {
      x: 423,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    badSealedCanal: {
      x: 423,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 423,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    treatedCanal: {
      x: 423,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 423,
      y: 149,
      width: 30,
      height: 29,
      zIndex: 3
    },
    toothRemoval: {
      x: 421,
      y: 146,
      width: 34,
      height: 33,
      zIndex: 3
    }
  }
}, {
  sort: 11,
  sortRender: 3,
  name: "23",
  position: "top",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 522,
      y: 117,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    decayedTooth: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    cariousTooth: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    therapyRestoration: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    boneAugmentation: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    emptyTooth: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    dentalBar: {
      x: 453,
      y: 151,
      width: 52,
      height: 75,
      zIndex: 2
    },
    syntheticGum: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    plasticCrown: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    plasticTooth: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    veneerTab: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 2
    },
    implantPosition: {
      x: 454,
      y: 182,
      width: 41,
      height: 41,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 454,
      y: 182,
      width: 41,
      height: 41,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 454,
      y: 182,
      width: 41,
      height: 41,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 454,
      y: 182,
      width: 41,
      height: 41,
      zIndex: 3
    },
    telescopicCrown: {
      x: 454,
      y: 182,
      width: 41,
      height: 41,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 448,
      y: 159,
      width: 66,
      height: 69,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 454,
      y: 182,
      width: 41,
      height: 41,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 454,
      y: 182,
      width: 41,
      height: 41,
      zIndex: 3
    },
    sealedCanal: {
      x: 462,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    stumpTabs: {
      x: 462,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    pulpitis: {
      x: 462,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    badSealedCanal: {
      x: 462,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 462,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    treatedCanal: {
      x: 462,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 462,
      y: 178,
      width: 36,
      height: 40,
      zIndex: 3
    },
    toothRemoval: {
      x: 454,
      y: 182,
      width: 41,
      height: 41,
      zIndex: 3
    }
  }
}, {
  sort: 12,
  sortRender: 4,
  name: "24",
  position: "top",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 559,
      y: 177,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    decayedTooth: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    cariousTooth: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    therapyRestoration: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    boneAugmentation: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    emptyTooth: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    dentalBar: {
      x: 487,
      y: 210,
      width: 37,
      height: 81,
      zIndex: 2
    },
    syntheticGum: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    plasticCrown: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    plasticTooth: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    veneerTab: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 2
    },
    implantPosition: {
      x: 481,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 481,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 481,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 481,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 481,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 462,
      y: 213,
      width: 77,
      height: 64,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 481,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 481,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 483,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    stumpTabs: {
      x: 483,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    pulpitis: {
      x: 483,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    badSealedCanal: {
      x: 483,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 483,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    treatedCanal: {
      x: 483,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 483,
      y: 236,
      width: 38,
      height: 32,
      zIndex: 3
    },
    toothRemoval: {
      x: 481,
      y: 232,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 13,
  sortRender: 5,
  name: "25",
  position: "top",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 591,
      y: 244,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    decayedTooth: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    cariousTooth: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    therapyRestoration: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    boneAugmentation: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    emptyTooth: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    dentalBar: {
      x: 502,
      y: 266,
      width: 35,
      height: 84,
      zIndex: 2
    },
    syntheticGum: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    plasticCrown: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    plasticTooth: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    veneerTab: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 2
    },
    implantPosition: {
      x: 500,
      y: 284,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 500,
      y: 284,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 500,
      y: 284,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 500,
      y: 284,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 500,
      y: 284,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 477,
      y: 265,
      width: 81,
      height: 64,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 500,
      y: 284,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 500,
      y: 284,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 499,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    stumpTabs: {
      x: 499,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    pulpitis: {
      x: 499,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    badSealedCanal: {
      x: 499,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 499,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    treatedCanal: {
      x: 499,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 499,
      y: 287,
      width: 38,
      height: 32,
      zIndex: 3
    },
    toothRemoval: {
      x: 500,
      y: 284,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 14,
  sortRender: 6,
  name: "26",
  position: "top",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 613,
      y: 317,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    decayedTooth: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    cariousTooth: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    therapyRestoration: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    boneAugmentation: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    emptyTooth: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    dentalBar: {
      x: 512,
      y: 311,
      width: 39,
      height: 118,
      zIndex: 2
    },
    syntheticGum: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    plasticCrown: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    plasticTooth: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    veneerTab: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 2
    },
    implantPosition: {
      x: 516,
      y: 351,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 516,
      y: 351,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 516,
      y: 351,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 516,
      y: 351,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 516,
      y: 351,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 480,
      y: 315,
      width: 100,
      height: 105,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 516,
      y: 351,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 516,
      y: 351,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 511,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    stumpTabs: {
      x: 511,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    pulpitis: {
      x: 511,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    badSealedCanal: {
      x: 511,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 511,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    treatedCanal: {
      x: 511,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 511,
      y: 347,
      width: 46,
      height: 54,
      zIndex: 3
    },
    toothRemoval: {
      x: 516,
      y: 351,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 15,
  sortRender: 7,
  name: "27",
  position: "top",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 630,
      y: 402,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    decayedTooth: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    cariousTooth: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    therapyRestoration: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    boneAugmentation: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    emptyTooth: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    dentalBar: {
      x: 527,
      y: 388,
      width: 28,
      height: 102,
      zIndex: 2
    },
    syntheticGum: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    plasticCrown: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    plasticTooth: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    veneerTab: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 2
    },
    implantPosition: {
      x: 533,
      y: 425,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 533,
      y: 425,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 533,
      y: 425,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 533,
      y: 425,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 533,
      y: 425,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 503,
      y: 396,
      width: 86,
      height: 90,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 533,
      y: 425,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 533,
      y: 425,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 531,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    stumpTabs: {
      x: 531,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    pulpitis: {
      x: 531,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    badSealedCanal: {
      x: 531,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 531,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    treatedCanal: {
      x: 531,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 531,
      y: 425,
      width: 39,
      height: 44,
      zIndex: 3
    },
    toothRemoval: {
      x: 533,
      y: 425,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 16,
  sortRender: 8,
  name: "28",
  position: "top",
  side: "left",
  isEighth: true,
  actionBtn: {
    position: {
      x: 637,
      y: 481,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    decayedTooth: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    cariousTooth: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    therapyRestoration: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    boneAugmentation: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    emptyTooth: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    dentalBar: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    // not used
    syntheticGum: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    plasticCrown: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    plasticTooth: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    veneerTab: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 2
    },
    implantPosition: {
      x: 540,
      y: 491,
      width: 35,
      height: 34,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 540,
      y: 491,
      width: 35,
      height: 34,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 540,
      y: 491,
      width: 35,
      height: 34,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 540,
      y: 491,
      width: 35,
      height: 34,
      zIndex: 3
    },
    telescopicCrown: {
      x: 540,
      y: 491,
      width: 35,
      height: 34,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 510,
      y: 467,
      width: 79,
      height: 77,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 540,
      y: 491,
      width: 35,
      height: 34,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 540,
      y: 491,
      width: 35,
      height: 34,
      zIndex: 3
    },
    sealedCanal: {
      x: 542,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    stumpTabs: {
      x: 542,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    pulpitis: {
      x: 542,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    badSealedCanal: {
      x: 542,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 542,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    treatedCanal: {
      x: 542,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 542,
      y: 490,
      width: 32,
      height: 38,
      zIndex: 3
    },
    toothRemoval: {
      x: 540,
      y: 491,
      width: 35,
      height: 34,
      zIndex: 3
    }
  }
}, {
  sort: 24,
  sortRender: 24,
  name: "31",
  position: "bottom",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 355,
      y: 1031,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    decayedTooth: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    cariousTooth: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    therapyRestoration: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    boneAugmentation: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    emptyTooth: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    dentalBar: {
      x: 346,
      y: 978,
      width: 57,
      height: 15,
      zIndex: 2
    },
    syntheticGum: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    plasticCrown: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    plasticTooth: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    veneerTab: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 2
    },
    implantPosition: {
      x: 353,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 353,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 353,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 353,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    telescopicCrown: {
      x: 353,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 349,
      y: 962,
      width: 41,
      height: 60,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 353,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 353,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    sealedCanal: {
      x: 356,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    stumpTabs: {
      x: 356,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    pulpitis: {
      x: 356,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    badSealedCanal: {
      x: 356,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 356,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    treatedCanal: {
      x: 356,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 356,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    toothRemoval: {
      x: 353,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    }
  }
}, {
  sort: 23,
  sortRender: 23,
  name: "32",
  position: "bottom",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 409,
      y: 1017,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    decayedTooth: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    cariousTooth: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    therapyRestoration: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    boneAugmentation: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    emptyTooth: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    dentalBar: {
      x: 380,
      y: 962,
      width: 61,
      height: 31,
      zIndex: 2
    },
    syntheticGum: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    plasticCrown: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    plasticTooth: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    veneerTab: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 2
    },
    implantPosition: {
      x: 386,
      y: 961,
      width: 32,
      height: 31,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 386,
      y: 961,
      width: 32,
      height: 31,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 386,
      y: 961,
      width: 32,
      height: 31,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 386,
      y: 961,
      width: 32,
      height: 31,
      zIndex: 3
    },
    telescopicCrown: {
      x: 386,
      y: 961,
      width: 32,
      height: 31,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 383,
      y: 948,
      width: 48,
      height: 66,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 386,
      y: 961,
      width: 32,
      height: 31,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 386,
      y: 961,
      width: 32,
      height: 31,
      zIndex: 3
    },
    sealedCanal: {
      x: 390,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    stumpTabs: {
      x: 390,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    pulpitis: {
      x: 390,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    badSealedCanal: {
      x: 390,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 390,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    treatedCanal: {
      x: 390,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 390,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    toothRemoval: {
      x: 386,
      y: 961,
      width: 32,
      height: 31,
      zIndex: 3
    }
  }
}, {
  sort: 22,
  sortRender: 22,
  name: "33",
  position: "bottom",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 460,
      y: 989,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    decayedTooth: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    cariousTooth: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    therapyRestoration: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    boneAugmentation: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    emptyTooth: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    dentalBar: {
      x: 416,
      y: 924,
      width: 57,
      height: 59,
      zIndex: 2
    },
    syntheticGum: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    plasticCrown: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    plasticTooth: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    veneerTab: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 2
    },
    implantPosition: {
      x: 423,
      y: 940,
      width: 35,
      height: 35,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 423,
      y: 940,
      width: 35,
      height: 35,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 423,
      y: 940,
      width: 35,
      height: 35,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 423,
      y: 940,
      width: 35,
      height: 35,
      zIndex: 3
    },
    telescopicCrown: {
      x: 423,
      y: 940,
      width: 35,
      height: 35,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 419,
      y: 928,
      width: 52,
      height: 68,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 423,
      y: 940,
      width: 35,
      height: 35,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 423,
      y: 940,
      width: 35,
      height: 35,
      zIndex: 3
    },
    sealedCanal: {
      x: 423,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    stumpTabs: {
      x: 423,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    pulpitis: {
      x: 423,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    badSealedCanal: {
      x: 423,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 423,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    treatedCanal: {
      x: 423,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 423,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    toothRemoval: {
      x: 423,
      y: 940,
      width: 35,
      height: 35,
      zIndex: 3
    }
  }
}, {
  sort: 21,
  sortRender: 21,
  name: "34",
  position: "bottom",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 502,
      y: 948,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    decayedTooth: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    cariousTooth: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    therapyRestoration: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    boneAugmentation: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    emptyTooth: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    dentalBar: {
      x: 453,
      y: 887,
      width: 39,
      height: 66,
      zIndex: 2
    },
    syntheticGum: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    plasticCrown: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    plasticTooth: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    veneerTab: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 2
    },
    implantPosition: {
      x: 449,
      y: 903,
      width: 34,
      height: 34,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 449,
      y: 903,
      width: 34,
      height: 34,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 449,
      y: 903,
      width: 34,
      height: 34,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 449,
      y: 903,
      width: 34,
      height: 34,
      zIndex: 3
    },
    telescopicCrown: {
      x: 449,
      y: 903,
      width: 34,
      height: 34,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 445,
      y: 897,
      width: 57,
      height: 60,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 449,
      y: 903,
      width: 34,
      height: 34,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 449,
      y: 903,
      width: 34,
      height: 34,
      zIndex: 3
    },
    sealedCanal: {
      x: 453,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    stumpTabs: {
      x: 453,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    pulpitis: {
      x: 453,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    badSealedCanal: {
      x: 453,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 453,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    treatedCanal: {
      x: 453,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 453,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    toothRemoval: {
      x: 449,
      y: 903,
      width: 34,
      height: 34,
      zIndex: 3
    }
  }
}, {
  sort: 20,
  sortRender: 20,
  name: "35",
  position: "bottom",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 541,
      y: 893,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    decayedTooth: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    cariousTooth: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    therapyRestoration: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    boneAugmentation: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    emptyTooth: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    dentalBar: {
      x: 465,
      y: 843,
      width: 43,
      height: 76,
      zIndex: 2
    },
    syntheticGum: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    plasticCrown: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    plasticTooth: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    veneerTab: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 2
    },
    implantPosition: {
      x: 463,
      y: 855,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 463,
      y: 855,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 463,
      y: 855,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 463,
      y: 855,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 463,
      y: 855,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 454,
      y: 844,
      width: 68,
      height: 62,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 463,
      y: 855,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 463,
      y: 855,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 465,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    stumpTabs: {
      x: 465,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    pulpitis: {
      x: 465,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    badSealedCanal: {
      x: 465,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 465,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    treatedCanal: {
      x: 465,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 465,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    toothRemoval: {
      x: 463,
      y: 855,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 19,
  sortRender: 19,
  name: "36",
  position: "bottom",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 580,
      y: 819,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    decayedTooth: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    cariousTooth: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    therapyRestoration: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    boneAugmentation: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    emptyTooth: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    dentalBar: {
      x: 487,
      y: 761,
      width: 45,
      height: 107,
      zIndex: 2
    },
    syntheticGum: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    plasticCrown: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    plasticTooth: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    veneerTab: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 2
    },
    implantPosition: {
      x: 479,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 479,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 479,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 479,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 479,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 464,
      y: 764,
      width: 88,
      height: 92,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 479,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 479,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 480,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    stumpTabs: {
      x: 480,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    pulpitis: {
      x: 480,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    badSealedCanal: {
      x: 480,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 480,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    treatedCanal: {
      x: 480,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 480,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    toothRemoval: {
      x: 479,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 18,
  sortRender: 18,
  name: "37",
  position: "bottom",
  side: "left",
  isEighth: false,
  actionBtn: {
    position: {
      x: 611,
      y: 729,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    decayedTooth: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    cariousTooth: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    therapyRestoration: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    boneAugmentation: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    emptyTooth: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    dentalBar: {
      x: 512,
      y: 693,
      width: 31,
      height: 87,
      zIndex: 2
    },
    syntheticGum: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    plasticCrown: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    plasticTooth: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    veneerTab: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 2
    },
    implantPosition: {
      x: 500,
      y: 716,
      width: 40,
      height: 41,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 500,
      y: 716,
      width: 40,
      height: 41,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 500,
      y: 716,
      width: 40,
      height: 41,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 500,
      y: 716,
      width: 40,
      height: 41,
      zIndex: 3
    },
    telescopicCrown: {
      x: 500,
      y: 716,
      width: 40,
      height: 41,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 489,
      y: 693,
      width: 83,
      height: 86,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 500,
      y: 716,
      width: 40,
      height: 41,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 500,
      y: 716,
      width: 40,
      height: 41,
      zIndex: 3
    },
    sealedCanal: {
      x: 502,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    stumpTabs: {
      x: 502,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    pulpitis: {
      x: 502,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    badSealedCanal: {
      x: 502,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 502,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    treatedCanal: {
      x: 502,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 502,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    toothRemoval: {
      x: 500,
      y: 716,
      width: 40,
      height: 41,
      zIndex: 3
    }
  }
}, {
  sort: 17,
  sortRender: 17,
  name: "38",
  position: "bottom",
  side: "left",
  isEighth: true,
  actionBtn: {
    position: {
      x: 630,
      y: 642,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    decayedTooth: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    cariousTooth: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    therapyRestoration: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    boneAugmentation: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    emptyTooth: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    dentalBar: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    // not used
    syntheticGum: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    plasticCrown: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    plasticTooth: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    veneerTab: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 2
    },
    implantPosition: {
      x: 516,
      y: 650,
      width: 35,
      height: 34,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 516,
      y: 650,
      width: 35,
      height: 34,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 516,
      y: 650,
      width: 35,
      height: 34,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 516,
      y: 650,
      width: 35,
      height: 34,
      zIndex: 3
    },
    telescopicCrown: {
      x: 516,
      y: 650,
      width: 35,
      height: 34,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 504,
      y: 624,
      width: 77,
      height: 82,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 516,
      y: 650,
      width: 35,
      height: 34,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 516,
      y: 650,
      width: 35,
      height: 34,
      zIndex: 3
    },
    sealedCanal: {
      x: 517,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    stumpTabs: {
      x: 517,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    pulpitis: {
      x: 517,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    badSealedCanal: {
      x: 517,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 517,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    treatedCanal: {
      x: 517,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 517,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    toothRemoval: {
      x: 516,
      y: 650,
      width: 35,
      height: 34,
      zIndex: 3
    }
  }
}, {
  sort: 25,
  sortRender: 32,
  name: "41",
  position: "bottom",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 299,
      y: 1031,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    decayedTooth: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    cariousTooth: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    therapyRestoration: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    boneAugmentation: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    emptyTooth: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    dentalBar: {
      x: 294,
      y: 978,
      width: 57,
      height: 15,
      zIndex: 2
    },
    syntheticGum: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    plasticCrown: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    plasticTooth: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    veneerTab: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 2
    },
    implantPosition: {
      x: 320,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 320,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 320,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 320,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    telescopicCrown: {
      x: 320,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 312,
      y: 962,
      width: 41,
      height: 59,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 320,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 320,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    },
    sealedCanal: {
      x: 326,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    stumpTabs: {
      x: 326,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    pulpitis: {
      x: 326,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    badSealedCanal: {
      x: 326,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 326,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    treatedCanal: {
      x: 326,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 326,
      y: 977,
      width: 21,
      height: 27,
      zIndex: 3
    },
    toothRemoval: {
      x: 320,
      y: 978,
      width: 30,
      height: 30,
      zIndex: 3
    }
  }
}, {
  sort: 26,
  sortRender: 31,
  name: "42",
  position: "bottom",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 246,
      y: 1016,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    decayedTooth: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    cariousTooth: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    therapyRestoration: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    boneAugmentation: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    emptyTooth: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    dentalBar: {
      x: 266,
      y: 962,
      width: 59,
      height: 31,
      zIndex: 2
    },
    syntheticGum: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    plasticCrown: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    plasticTooth: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    veneerTab: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 2
    },
    implantPosition: {
      x: 287,
      y: 962,
      width: 32,
      height: 32,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 287,
      y: 962,
      width: 32,
      height: 32,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 287,
      y: 962,
      width: 32,
      height: 32,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 287,
      y: 962,
      width: 32,
      height: 32,
      zIndex: 3
    },
    telescopicCrown: {
      x: 287,
      y: 962,
      width: 32,
      height: 32,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 273,
      y: 947,
      width: 51,
      height: 67,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 287,
      y: 962,
      width: 32,
      height: 32,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 287,
      y: 962,
      width: 32,
      height: 32,
      zIndex: 3
    },
    sealedCanal: {
      x: 289,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    stumpTabs: {
      x: 289,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    pulpitis: {
      x: 289,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    badSealedCanal: {
      x: 289,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 289,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    treatedCanal: {
      x: 289,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 289,
      y: 965,
      width: 24,
      height: 30,
      zIndex: 3
    },
    toothRemoval: {
      x: 287,
      y: 962,
      width: 32,
      height: 32,
      zIndex: 3
    }
  }
}, {
  sort: 27,
  sortRender: 30,
  name: "43",
  position: "bottom",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 197,
      y: 990,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    decayedTooth: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    cariousTooth: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    therapyRestoration: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    boneAugmentation: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    emptyTooth: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    dentalBar: {
      x: 235,
      y: 924,
      width: 53,
      height: 56,
      zIndex: 2
    },
    syntheticGum: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    plasticCrown: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    plasticTooth: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    veneerTab: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 2
    },
    implantPosition: {
      x: 247,
      y: 936,
      width: 35,
      height: 35,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 247,
      y: 936,
      width: 35,
      height: 35,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 247,
      y: 936,
      width: 35,
      height: 35,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 247,
      y: 936,
      width: 35,
      height: 35,
      zIndex: 3
    },
    telescopicCrown: {
      x: 247,
      y: 936,
      width: 35,
      height: 35,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 233,
      y: 923,
      width: 54,
      height: 71,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 247,
      y: 936,
      width: 35,
      height: 35,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 247,
      y: 936,
      width: 35,
      height: 35,
      zIndex: 3
    },
    sealedCanal: {
      x: 248,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    stumpTabs: {
      x: 248,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    pulpitis: {
      x: 248,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    badSealedCanal: {
      x: 248,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 248,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    treatedCanal: {
      x: 248,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 248,
      y: 942,
      width: 31,
      height: 34,
      zIndex: 3
    },
    toothRemoval: {
      x: 247,
      y: 936,
      width: 35,
      height: 35,
      zIndex: 3
    }
  }
}, {
  sort: 28,
  sortRender: 29,
  name: "44",
  position: "bottom",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 158,
      y: 948,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    decayedTooth: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    cariousTooth: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    therapyRestoration: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    boneAugmentation: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    emptyTooth: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    dentalBar: {
      x: 215,
      y: 887,
      width: 40,
      height: 70,
      zIndex: 2
    },
    syntheticGum: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    plasticCrown: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    plasticTooth: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    veneerTab: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 2
    },
    implantPosition: {
      x: 221,
      y: 901,
      width: 34,
      height: 34,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 221,
      y: 901,
      width: 34,
      height: 34,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 221,
      y: 901,
      width: 34,
      height: 34,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 221,
      y: 901,
      width: 34,
      height: 34,
      zIndex: 3
    },
    telescopicCrown: {
      x: 221,
      y: 901,
      width: 34,
      height: 34,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 203,
      y: 892,
      width: 56,
      height: 60,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 221,
      y: 901,
      width: 34,
      height: 34,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 221,
      y: 901,
      width: 34,
      height: 34,
      zIndex: 3
    },
    sealedCanal: {
      x: 222,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    stumpTabs: {
      x: 222,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    pulpitis: {
      x: 222,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    badSealedCanal: {
      x: 222,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 222,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    treatedCanal: {
      x: 222,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 222,
      y: 906,
      width: 29,
      height: 33,
      zIndex: 3
    },
    toothRemoval: {
      x: 221,
      y: 901,
      width: 34,
      height: 34,
      zIndex: 3
    }
  }
}, {
  sort: 29,
  sortRender: 28,
  name: "45",
  position: "bottom",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 117,
      y: 893,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    decayedTooth: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    cariousTooth: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    therapyRestoration: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    boneAugmentation: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    emptyTooth: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    dentalBar: {
      x: 199,
      y: 843,
      width: 43,
      height: 78,
      zIndex: 2
    },
    syntheticGum: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    plasticCrown: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    plasticTooth: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    veneerTab: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 2
    },
    implantPosition: {
      x: 201,
      y: 854,
      width: 41,
      height: 41,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 201,
      y: 854,
      width: 41,
      height: 41,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 201,
      y: 854,
      width: 41,
      height: 41,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 201,
      y: 854,
      width: 41,
      height: 41,
      zIndex: 3
    },
    telescopicCrown: {
      x: 201,
      y: 854,
      width: 41,
      height: 41,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 185,
      y: 843,
      width: 64,
      height: 67,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 201,
      y: 854,
      width: 41,
      height: 41,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 201,
      y: 854,
      width: 41,
      height: 41,
      zIndex: 3
    },
    sealedCanal: {
      x: 207,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    stumpTabs: {
      x: 207,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    pulpitis: {
      x: 207,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    badSealedCanal: {
      x: 207,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 207,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    treatedCanal: {
      x: 207,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 207,
      y: 858,
      width: 32,
      height: 36,
      zIndex: 3
    },
    toothRemoval: {
      x: 201,
      y: 854,
      width: 41,
      height: 41,
      zIndex: 3
    }
  }
}, {
  sort: 30,
  sortRender: 27,
  name: "46",
  position: "bottom",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 79,
      y: 819,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    decayedTooth: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    cariousTooth: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    therapyRestoration: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    boneAugmentation: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    emptyTooth: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    dentalBar: {
      x: 174,
      y: 761,
      width: 47,
      height: 105,
      zIndex: 2
    },
    syntheticGum: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    plasticCrown: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    plasticTooth: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    veneerTab: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 2
    },
    implantPosition: {
      x: 183,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 183,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 183,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 183,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 183,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 153,
      y: 762,
      width: 86,
      height: 91,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 183,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 183,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 185,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    stumpTabs: {
      x: 185,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    pulpitis: {
      x: 185,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    badSealedCanal: {
      x: 185,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 185,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    treatedCanal: {
      x: 185,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 185,
      y: 788,
      width: 38,
      height: 49,
      zIndex: 3
    },
    toothRemoval: {
      x: 183,
      y: 792,
      width: 40,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 31,
  sortRender: 26,
  name: "47",
  position: "bottom",
  side: "right",
  isEighth: false,
  actionBtn: {
    position: {
      x: 50,
      y: 730,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    decayedTooth: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    cariousTooth: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    therapyRestoration: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    boneAugmentation: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    emptyTooth: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    dentalBar: {
      x: 164,
      y: 693,
      width: 34,
      height: 97,
      zIndex: 2
    },
    syntheticGum: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    plasticCrown: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    plasticTooth: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    veneerTab: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 2
    },
    implantPosition: {
      x: 164,
      y: 715,
      width: 41,
      height: 40,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 164,
      y: 715,
      width: 41,
      height: 40,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 164,
      y: 715,
      width: 41,
      height: 40,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 164,
      y: 715,
      width: 41,
      height: 40,
      zIndex: 3
    },
    telescopicCrown: {
      x: 164,
      y: 715,
      width: 41,
      height: 40,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 134,
      y: 689,
      width: 82,
      height: 84,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 164,
      y: 715,
      width: 41,
      height: 40,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 164,
      y: 715,
      width: 41,
      height: 40,
      zIndex: 3
    },
    sealedCanal: {
      x: 167,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    stumpTabs: {
      x: 167,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    pulpitis: {
      x: 167,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    badSealedCanal: {
      x: 167,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 167,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    treatedCanal: {
      x: 167,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 167,
      y: 713,
      width: 34,
      height: 44,
      zIndex: 3
    },
    toothRemoval: {
      x: 164,
      y: 715,
      width: 41,
      height: 40,
      zIndex: 3
    }
  }
}, {
  sort: 32,
  sortRender: 25,
  name: "48",
  position: "bottom",
  side: "right",
  isEighth: true,
  actionBtn: {
    position: {
      x: 31,
      y: 642,
      width: 50,
      height: 50
    },
    isActive: false,
    type: "actionBtn"
  },
  prostheticsTypeSelect: false,
  implantsTypeSelect: false,
  fillingsSelect: false,
  pathologiesSelect: false,
  optionSelect: false,
  toothSegments: {},
  toothElementsActive: ["healthyTooth"],
  toothElements: {
    healthyTooth: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    decayedTooth: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    cariousTooth: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    therapyRestoration: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    boneAugmentation: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    emptyTooth: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    dentalBar: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    // not used
    syntheticGum: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    oldEsteticTooth: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 1
    },
    newEsteticTooth: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    plasticCrown: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    plasticTooth: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    veneerTab: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 2
    },
    implantPosition: {
      x: 154,
      y: 648,
      width: 35,
      height: 35,
      zIndex: 3
    },
    screwFixationImplants: {
      x: 154,
      y: 648,
      width: 35,
      height: 35,
      zIndex: 3
    },
    individualZirconiumAbutment: {
      x: 154,
      y: 648,
      width: 35,
      height: 35,
      zIndex: 3
    },
    individualTitaniumAbutment: {
      x: 154,
      y: 648,
      width: 35,
      height: 35,
      zIndex: 3
    },
    telescopicCrown: {
      x: 154,
      y: 648,
      width: 35,
      height: 35,
      zIndex: 3
    },
    prostheticsTooth: {
      x: 124,
      y: 621,
      width: 76,
      height: 82,
      zIndex: 0
    },
    multiunitAbutment: {
      x: 154,
      y: 648,
      width: 35,
      height: 35,
      zIndex: 3
    },
    multiunitZirconiumAbutment: {
      x: 154,
      y: 648,
      width: 35,
      height: 35,
      zIndex: 3
    },
    sealedCanal: {
      x: 155,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    stumpTabs: {
      x: 155,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    pulpitis: {
      x: 155,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    badSealedCanal: {
      x: 155,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    brokenToolCanal: {
      x: 155,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    treatedCanal: {
      x: 155,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    toothStumpRestoration: {
      x: 155,
      y: 646,
      width: 32,
      height: 40,
      zIndex: 3
    },
    toothRemoval: {
      x: 154,
      y: 648,
      width: 35,
      height: 35,
      zIndex: 3
    }
  }
}];
import { CDN_DOMAIN } from "../settings";
import { translate } from "../i18n";

const MediaLibraryContent = language => {
  const _t = key => translate(key, language);

  return [{
    id: "prosthesisMedia",
    title: _t("Протезирование"),
    description: _t("Протезирование на имплантах, мостовидное протезирование, съемное протезирование."),
    preview: require("./Images/media_prot.png"),
    sections: [{
      id: "implantationPartialMedia",
      title: _t("Имплантация при частичной адентии"),
      preview: require("./Images/Дентальная-мплантация.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/ТотИмплантация Col.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/ТотИмплантация-Col.png`,
        title: _t("Дентальная имплантация")
      }, {
        video: `${CDN_DOMAIN}/Videos/Crown On Implant With Individual Titanium Abutment.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Crown On Implant With Individual Titanium Abutment.png`,
        title: _t("Имплантация на нижней челюсти")
      }, {
        video: `${CDN_DOMAIN}/Videos/Имплантация.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Имплантация.png`,
        title: _t("Имплантация на верхней челюсти")
      }, {
        video: `${CDN_DOMAIN}/Videos/Implantaciya S Pomoshyu Hirurgicheskogo Shablona.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Implantaciya-S-Pomoshyu-Hirurgicheskogo-Shablona.png`,
        title: _t("Хирургический шаблон на верхнюю челюсть")
      }, {
        video: `${CDN_DOMAIN}/Videos/Шаблон Пилотный Протокол-26.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Шаблон-Пилотный-Протокол-26.png`,
        title: _t("Хирургический шаблон, пилотный протокол")
      }, {
        video: `${CDN_DOMAIN}/Videos/Шаблон Полный Протокол-27.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Шаблон-Полный-Протокол-27.png`,
        title: _t("Хирургический шаблон, полный протокол")
      }, {
        video: `${CDN_DOMAIN}/Videos/Подвесные зубы.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Подвесные-зубы.png`,
        title: _t("Подвесные зубы")
      }, {
        video: `${CDN_DOMAIN}/Videos/Соединения-1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Соединения-1.png`,
        title: _t("Соединения в мосте на имплантах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Тотал Все На 4 Соединения-1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Тотал Все На 4 Соединения-1.jpg`,
        title: _t("Соединения")
      }],
      photo: [{
        photo: require("./Photos/Фото1_yXMoWOU.jpg"),
        title: _t("Имплантация 21 зуб")
      }, {
        photo: require("./Photos/Фото2_v0Hu4WI.jpg"),
        title: _t("Имплантация 45 зуб")
      }, {
        photo: require("./Photos/Фото4_bzw7oGH.jpg"),
        title: _t("Имплантация 46 зуб")
      }, {
        photo: require("./Photos/Фото4_1cuZIzf.jpg"),
        title: _t("Имплантация 45-46 зуб")
      }, {
        photo: require("./Photos/Фото7_vxsKpiR.jpg"),
        title: _t("Имплантация 25 зуб")
      }, {
        photo: require("./Photos/шаблон.jpg"),
        title: _t("Имплантация через хирургический шаблон")
      }, {
        photo: require("./Photos/шаблон3.jpg"),
        title: _t("Хирургический шаблон")
      }, {
        photo: require("./Photos/Фото_v4FA9Mb.jpg"),
        title: _t("Хирургический шаблон на 6 имплантов")
      }, {
        photo: require("./Photos/Фото9_DqA0bXI.jpg"),
        title: _t("Хирургический шаблон 14 зуб")
      }, {
        photo: require("./Photos/Фото10_CZEVBLq.jpg"),
        title: _t("Протез с имплантом на модели")
      }, {
        photo: require("./Photos/Подвесные зубы 1.jpg"),
        title: _t("Подвесные зубы")
      }, {
        photo: require("./Photos/Подвесные зубы 2.jpg"),
        title: _t("Подвесные зубы")
      }, {
        photo: require("./Photos/Подвесные зубы 3.jpg"),
        title: _t("Подвесные зубы")
      }, {
        photo: require("./Photos/Соединения 1.jpg"),
        title: _t("Соединения в мосте на имплантах")
      }, {
        photo: require("./Photos/Соединения 2.jpg"),
        title: _t("Соединения в мосте на имплантах")
      }, {
        photo: require("./Photos/Соединения 3.jpg"),
        title: _t("Соединения в мосте на имплантах")
      }],
      vr: [{
        folder: "Circon_abutment",
        xCount: 105,
        yCount: 0,
        title: _t("Имплант в области центрального зуба"),
        preview: require("./VRPacks/Circon_abutment/0_1.jpg")
      }, {
        folder: "Titan_abutment_46",
        xCount: 89,
        yCount: 0,
        title: _t("Имплант в области жевательного зуба"),
        preview: require("./VRPacks/Titan_abutment_46/0_1.jpg")
      }, {
        folder: "multiunit_44-46",
        xCount: 91,
        yCount: 0,
        title: _t("Импланты в боковом участке"),
        preview: require("./VRPacks/multiunit_44-46/0_1.jpg")
      }]
    }, {
      id: "implantationFullMedia",
      title: _t("Имплантация при полной адентии"),
      preview: require("./Images/Имплантация-обе-чел.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах 1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах 1.jpg`,
        title: _t("Протез на мульти-юнитах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах.png`,
        title: _t("Протез на мульти-юнитах Owerpress")
      }, {
        video: `${CDN_DOMAIN}/Videos/Винтовая_Фиксация-Временная-конструкция.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Винтовая_Фиксация-Временная-конструкция.png`,
        title: _t("Протез на имплантах")
      }, {
        video: `${CDN_DOMAIN}/Videos/ТотИмплантация Col.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/ТотИмплантация-Col.png`,
        title: _t("Протезы на имплантах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Тотал Все На 4 Мультиюниты-1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Тотал Все На 4 Мультиюниты-1.jpg`,
        title: _t("Протезы на имплантах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент титановый 3.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент титановый 3.png`,
        title: _t("Протезы на имплантах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Removable_Prosthesis_On_Implants_iy2iBaK.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Removable_Prosthesis_On_Implants.png`,
        title: _t("Протезы на имплантах")
      }],
      photo: [{
        photo: require("./Photos/Протез на мультиюнитах 1.jpg"),
        title: _t("Протез на имплантах")
      }, {
        photo: require("./Photos/Фото8_mhANL1s.jpg"),
        title: _t("Все на шести")
      }, {
        photo: require("./Photos/Фото8_mhANL2s.jpg"),
        title: _t("Все на шести")
      }, {
        photo: require("./Photos/Протез на мультиюнитах 2.jpg"),
        title: _t("Все на четырех")
      }, {
        photo: require("./Photos/Фото8_mhANL3s.jpg"),
        title: _t("Все на шести")
      }, {
        photo: require("./Photos/Протез на мультиюнитах 3.jpg"),
        title: _t("Все на четырех")
      }],
      vr: [{
        folder: "Total_multiunit_low",
        xCount: 107,
        yCount: 0,
        title: _t("Полный съемный протез на имплантах"),
        preview: require("./VRPacks/Total_multiunit_low/0_107.jpg")
      }, {
        folder: "Total_multiunit_up",
        xCount: 105,
        yCount: 0,
        title: _t("Протез на мульти-юнитах"),
        preview: require("./VRPacks/Total_multiunit_up/0_32.jpg")
      }, {
        folder: "Circon_abutment_total",
        xCount: 105,
        yCount: 0,
        title: _t("Протез на мульти-юнитах"),
        preview: require("./VRPacks/Circon_abutment_total/0_32.jpg")
      }]
    }, {
      id: "tipeFixationPartialMedia",
      title: _t("Типы фиксации к имплантам при частичной адентии"),
      preview: require("./Images/Винтовая-фиксация.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Винтовая фиксация.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Винтовая фиксация.jpg`,
        title: _t("Коронки винтовой фиксации")
      }, {
        video: `${CDN_DOMAIN}/Videos/46_Винтовая_Фиксация-1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/46_Винтовая_Фиксация-1.png`,
        title: _t("Коронка винтовой фиксации")
      }, {
        video: `${CDN_DOMAIN}/Videos/Crowns_On_Screw_Fixation_Implant.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Crowns_On_Screw_Fixation_Implant.png`,
        title: _t("Коронка винтовой фиксации на 36 зуб")
      }, {
        video: `${CDN_DOMAIN}/Videos/Винтовая_Фиксация-Временная-конструкция.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Винтовая_Фиксация-Временная-конструкция.png`,
        title: _t("Временный протез винтовой фиксации")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент стандартный 1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент стандартный 1.png`,
        title: _t("Абатмент стандартный")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент стандартный 2.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент стандартный 2.png`,
        title: _t("Коронка на стандартном абатменте")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент стандартный 3.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент стандартный 3.png`,
        title: _t("Мост на стандартных абатментах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент титановый 1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент титановый 1.png`,
        title: _t("Абатмент титановый")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент титановый 2.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент титановый 2.png`,
        title: _t("Коронка на титановом абатменте")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент титановый 3.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент титановый 3.png`,
        title: _t("Мост на титановых абатментах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент цирконевый 1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент цирконевый 1.png`,
        title: _t("Абатмент циркониевый")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент цирконевый 2.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент цирконевый 2.png`,
        title: _t("Коронка на циркониевом абатменте")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент цирконевый 3.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент цирконевый 3.png`,
        title: _t("Мост на циркониевых абатментах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах 1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах 1.jpg`,
        title: _t("Протез на мульти-юнитах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах.png`,
        title: _t("Протез на мульти-юнитах Owerpress")
      }, {
        video: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах 2.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах 2.png`,
        title: _t("Мост на мульти-юнитах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Гингива Импланты.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Гингива Импланты.jpg`,
        title: _t("Пластика десны при использовании мульти-юнитов")
      }],
      photo: [{
        photo: require("./Photos/Винтовая фикс 1.jpg"),
        title: _t("Коронка винтовой фиксации на 46 зуб")
      }, {
        photo: require("./Photos/Винтовая фикс 2.jpg"),
        title: _t("Шахта коронки винтовой фиксации")
      }, {
        photo: require("./Photos/Винтовая фикс 3.jpg"),
        title: _t("Коронка винтовой фиксации")
      }, {
        photo: require("./Photos/Винтовая фикс 4.jpg"),
        title: _t("Коронка винтовой фиксации 2")
      }, {
        photo: require("./Photos/Винтовая фикс 5.jpg"),
        title: _t("Мост винтовой фиксации")
      }, {
        photo: require("./Photos/Абатмент стандартный фото 1.jpg"),
        title: _t("Абатмент стандартный")
      }, {
        photo: require("./Photos/Абатмент стандартный фото 2.jpg"),
        title: _t("Коронка на стандартном абатменте")
      }, {
        photo: require("./Photos/Абатмент стандартный фото 3.jpg"),
        title: _t("Абатмент стандартный с винтом")
      }, {
        photo: require("./Photos/Абатмент титановый фото 1.jpg"),
        title: _t("Абатмент титановый")
      }, {
        photo: require("./Photos/Абатмент титановый фото 2.jpg"),
        title: _t("Коронка на титановом абатменте")
      }, {
        photo: require("./Photos/Абатмент титановый фото 3.jpg"),
        title: _t("Абатмент титановый с коронкой и винтом")
      }, {
        photo: require("./Photos/Абатмент цирконевый фото 1.jpg"),
        title: _t("Абатменты циркониевые")
      }, {
        photo: require("./Photos/Абатмент цирконевый фото 2.jpg"),
        title: _t("Абатмент циркониевый с коронкой")
      }, {
        photo: require("./Photos/Абатмент цирконевый фото 3.jpg"),
        title: _t("Абатмент циркониевый")
      }, {
        photo: require("./Photos/Протез на мультиюнитах 1.jpg"),
        title: _t("Протез на мульти-юнитах")
      }, {
        photo: require("./Photos/Протез на мультиюнитах 2.jpg"),
        title: _t("Протез на мульти-юнитах")
      }, {
        photo: require("./Photos/Протез на мультиюнитах 3.jpg"),
        title: _t("Протез на мульти-юнитах")
      }, {
        photo: require("./Photos/Гингива импланты.5515.jpg"),
        title: _t("Формирование десны")
      }, {
        photo: require("./Photos/Гингива импланты.5516.jpg"),
        title: _t("Супраструктура")
      }, {
        photo: require("./Photos/Гингива импланты.5518.jpg"),
        title: _t("Коронки на супраструктуре")
      }],
      vr: [{
        folder: "Skrew_fixation_11-21",
        xCount: 90,
        yCount: 0,
        title: _t("Винтовая фиксация коронок к импланту"),
        preview: require("./VRPacks/Skrew_fixation_11-21/0_4.jpg")
      }, {
        folder: "Skrew_fixation",
        xCount: 89,
        yCount: 0,
        title: _t("Винтовая фиксация"),
        preview: require("./VRPacks/Skrew_fixation/0_1.jpg")
      }, {
        folder: "Circon_abutment",
        xCount: 105,
        yCount: 0,
        title: _t("Цирконевый абатмент"),
        preview: require("./VRPacks/Circon_abutment/0_1.jpg")
      }, {
        folder: "Titan_abutment_46",
        xCount: 89,
        yCount: 0,
        title: _t("Титановый абатмент"),
        preview: require("./VRPacks/Titan_abutment_46/0_1.jpg")
      }, {
        folder: "Titan_abutment_21",
        xCount: 90,
        yCount: 0,
        title: _t("Индивидуальный титановый абатмент"),
        preview: require("./VRPacks/Titan_abutment_21/0_17.jpg")
      }, {
        folder: "Circon_abutment_45",
        xCount: 90,
        yCount: 0,
        title: _t("Цельноцерконевый абатмент"),
        preview: require("./VRPacks/Circon_abutment_45/0_6.jpg")
      }, {
        folder: "Standart_abutment",
        xCount: 89,
        yCount: 0,
        title: _t("Стандартный абатмент"),
        preview: require("./VRPacks/Standart_abutment/0_12.jpg")
      }, {
        folder: "multiunit_44-46",
        xCount: 91,
        yCount: 0,
        title: _t("Фиксация протеза на мульти-юнитах"),
        preview: require("./VRPacks/multiunit_44-46/0_1.jpg")
      }, {
        folder: "Total_multiunit_up",
        xCount: 105,
        yCount: 0,
        title: _t("Протез на мульти-юнитах"),
        preview: require("./VRPacks/Total_multiunit_up/0_32.jpg")
      }]
    }, {
      id: "tipeFixationFullMedia",
      title: _t("Типы фиксации к имплантам при полной адентии"),
      preview: require("./Images/протез-на-телескопах-верх.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Верх тотал на цирконевых абатментах.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Верх тотал на цирконевых абатментах.jpg`,
        title: _t("Протез на цирконевых абатментах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Абатмент титановый 3.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент титановый 3.png`,
        title: _t("Мост на титановых абатментах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах 1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах 1.jpg`,
        title: _t("Протез на мульти-юнитах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах.png`,
        title: _t("Протез на мульти-юнитах Owerpress")
      }, {
        video: `${CDN_DOMAIN}/Videos/Тотал Все На 4 Мультиюниты-1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Тотал Все На 4 Мультиюниты-1.jpg`,
        title: _t("Условно-съемные протезы на мульти-юнитах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Тотал Все На 4 Соединения-1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Тотал Все На 4 Соединения-1.jpg`,
        title: _t("Нижний протез на мульти-юнитах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Тотал На Телескопах И Балках-1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Тотал На Телескопах И Балках-1.jpg`,
        title: _t("Мост на телескопических коронках и балках")
      }],
      photo: [{
        photo: require("./Photos/Абатмент стандартный фото 1.jpg"),
        title: _t("Абатмент стандартный")
      }, {
        photo: require("./Photos/Протез на мультиюнитах 1.jpg"),
        title: _t("Протез на мульти-юнитах")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5265.jpg"),
        title: _t("Телескопические коронки с балками")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5266.jpg"),
        title: _t("Телескопические коронки с балками")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5267.jpg"),
        title: _t("Телескопические коронки с балками")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5269.jpg"),
        title: _t("Телескопические коронки с балками")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5270.jpg"),
        title: _t("Телескопические коронки с балками")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5273.jpg"),
        title: _t("Телескопические коронки с балками")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5274.jpg"),
        title: _t("Телескопические коронки с балками")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5276.jpg"),
        title: _t("Телескопические коронки с балками")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5277.jpg"),
        title: _t("Телескопические коронки с балками")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5278.jpg"),
        title: _t("Телескопические коронки с балками")
      }, {
        photo: require("./Photos/Телескопы и балки на имплантах.5279.jpg"),
        title: _t("Телескопические коронки с балками")
      }],
      vr: [{
        folder: "Circon_abutment_total",
        xCount: 105,
        yCount: 0,
        title: _t("Протез на индивидуальных абатментах"),
        preview: require("./VRPacks/Circon_abutment_total/0_32.jpg")
      }, {
        folder: "Total_multiunit_up",
        xCount: 105,
        yCount: 0,
        title: _t("Протез на мульти-юнитах"),
        preview: require("./VRPacks/Total_multiunit_up/0_32.jpg")
      }, {
        folder: "teleskopOnImplants",
        xCount: 90,
        yCount: 0,
        title: _t("Тотальный протез на телескопических коронках и балках"),
        preview: require("./VRPacks/teleskopOnImplants/0_21.jpg")
      }]
    }, {
      id: "zygomaImplant",
      title: _t("Скуловые и субпериостальные импланты"),
      preview: require("./Images/zygoma_implant.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/zygoma_implant.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/zygoma_implant_prev.jpg`,
        title: _t("Протез на скуловых имлантах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Subperiosteal_Implant.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Subperiosteal_Implant.jpg`,
        title: _t("Субпериостальный имплант")
      }],
      photo: [{
        photo: require("./Photos/zygoma_implant.jpg"),
        title: _t("Скуловые имланты")
      }, {
        photo: require("./Photos/zygoma_implant1.jpg"),
        title: _t("Протез на скуловых имлантах")
      }, {
        photo: require("./Photos/zygoma_implant2.jpg"),
        title: _t("Протез на скуловых имлантах")
      }, {
        photo: require("./Photos/zygoma_implant3.jpg"),
        title: _t("Протез на скуловых имлантах")
      }, {
        photo: require("./Photos/Subperiosteal_Implant1.jpg"),
        title: _t("Протез на субпериостальном импланте")
      }, {
        photo: require("./Photos/Subperiosteal_Implant2.jpg"),
        title: _t("Субпериостальный имплант")
      }, {
        photo: require("./Photos/Subperiosteal_Implant3.jpg"),
        title: _t("Субпериостальный имплант на верхнюю челюсть")
      }, {
        photo: require("./Photos/Subperiosteal_Implant4.jpg"),
        title: _t("Протез на субпериостальном импланте")
      }]
    }, {
      id: "fixedProstheticsMedia",
      title: _t("Мостовидное протезирование"),
      preview: require("./Images/Несъемное-протезирование.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Bridges Of Zirconium.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Bridges-Of-Zirconium.png`,
        title: _t("Зубной мост на передние зубы")
      }, {
        video: `${CDN_DOMAIN}/Videos/Мост подкова Colleagial.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Мост подкова Colleagial.jpg`,
        title: _t("Мост подкова")
      }, {
        video: `${CDN_DOMAIN}/Videos/The Bridge Of Dental Zirconium.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/The Bridge Of Dental Zirconium.png`,
        title: _t("Зубной мост на жевательные зубы")
      }, {
        video: `${CDN_DOMAIN}/Videos/Dental Adhesive Bridge.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Dental-Adhesive-Bridge.png`,
        title: _t("Несъемный мост на вкладках")
      }, {
        video: `${CDN_DOMAIN}/Videos/Коронки зубы в промежутках.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Коронки зубы в промежутках.jpg`,
        title: _t("Коронки в мостовидных протезах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Мост 13-16 Colleagial.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Мост 13-16 Colleagial.jpg`,
        title: _t("Опорные коронки в мосте")
      }, {
        video: `${CDN_DOMAIN}/Videos/Мост подвесные зубы Colleagial.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Мост подвесные зубы Colleagial.jpg`,
        title: _t("Подвесные зубы в мосте")
      }, {
        video: `${CDN_DOMAIN}/Videos/alternativeBridges.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/alternativeBridges.png`,
        title: _t("Альтернатива мостовидным протезам")
      }, {
        video: `${CDN_DOMAIN}/Videos/Ceramic_Tabs.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Ceramic_Tabs.jpg`,
        title: _t("Керамические вкладки")
      }],
      photo: [{
        photo: require("./Photos/Фото5_1XB0pW7.jpg"),
        title: _t("Мост из диоксида циркония")
      }, {
        photo: require("./Photos/Фото8.jpg"),
        title: _t("Мост на жевательные зубы")
      }, {
        photo: require("./Photos/Фото6.jpg"),
        title: _t("Мост на передние зубы")
      }, {
        photo: require("./Photos/Фото4_SE3ZK9W.jpg"),
        title: _t("Адгезивный мост")
      }, {
        photo: require("./Photos/Фото7.jpg"),
        title: _t("Металлокерамический мост")
      }, {
        photo: require("./Photos/Фото.jpg"),
        title: _t("Мосты на жевательные зубы")
      }, {
        photo: require("./Photos/Мост подкова 2.jpg"),
        title: _t("Коронки в мосте")
      }, {
        photo: require("./Photos/110039.jpg"),
        title: _t("Коронки в протезе")
      }, {
        photo: require("./Photos/110037.jpg"),
        title: _t("Коронки в протезе")
      }, {
        photo: require("./Photos/110036.jpg"),
        title: _t("Зубной мост")
      }, {
        photo: require("./Photos/110038.jpg"),
        title: _t("Мосты на жевательные зубы")
      }, {
        photo: require("./Photos/110040.jpg"),
        title: _t("Мосты на жевательные зубы")
      }, {
        photo: require("./Photos/110042.jpg"),
        title: _t("Мосты на жевательные зубы")
      }, {
        photo: require("./Photos/110043.jpg"),
        title: _t("Мосты на жевательные зубы")
      }, {
        photo: require("./Photos/110044.jpg"),
        title: _t("Мосты на жевательные зубы")
      }, {
        photo: require("./Photos/110047.jpg"),
        title: _t("Мосты на жевательные зубы")
      }, {
        photo: require("./Photos/110048.jpg"),
        title: _t("Мосты на жевательные зубы")
      }, {
        photo: require("./Photos/110049.jpg"),
        title: _t("Мосты на жевательные зубы")
      }],
      vr: [{
        folder: "brige",
        xCount: 90,
        yCount: 0,
        title: _t("Мостовидный протез"),
        preview: require("./VRPacks/brige/0_6.jpg")
      }, {
        folder: "bridgePasterior",
        xCount: 90,
        yCount: 0,
        title: _t("Мостовидный протез на боковые зубы"),
        preview: require("./VRPacks/bridgePasterior/0_16.jpg")
      }]
    }, {
      id: "removableProstheticsMedia",
      title: _t("Съемное протезирование"),
      preview: require("./Images/Съемный-протез.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Dental Microprosthesis Removable.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Dental Microprosthesis Removable.png`,
        title: _t("Временный микропротез")
      }, {
        video: `${CDN_DOMAIN}/Videos/Съемные.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Съемные.jpg`,
        title: _t("Бюгельный протез")
      }, {
        video: `${CDN_DOMAIN}/Videos/Nylon Prosthesis.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Nylon-Prosthesis.png`,
        title: _t("Временный нейлоновый протез")
      }, {
        video: `${CDN_DOMAIN}/Videos/Prosthesis On Telescopic Crowns.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Prosthesis-On-Telescopic-Crowns.png`,
        title: _t("Протез на телескопических коронках")
      }, {
        video: `${CDN_DOMAIN}/Videos/Removable Prosthesis Fixation On The Beam.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Removable-Prosthesis-Fixation-On-The-Beam.png`,
        title: _t("Протез с фиксацией на балках")
      }, {
        video: `${CDN_DOMAIN}/Videos/Attachments.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Attachments.png`,
        title: _t("Протез с фиксацией на аттачментах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Akril_1_0.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Akril_1_0.jpg`,
        title: _t("Протез с фиксацией на кламмерах")
      }, {
        video: `${CDN_DOMAIN}/Videos/Akril_2_0.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Akril_2_0.png`,
        title: _t("Протез с фиксацией на кламмерах 2")
      }, {
        video: `${CDN_DOMAIN}/Videos/Complete Removable Dentures.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Complete-Removable-Dentures.png`,
        title: _t("Съемные протезу на беззубую челюсть")
      }, {
        video: `${CDN_DOMAIN}/Videos/AtrofiaFull.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/AtrofiaFull.jpg`,
        title: _t("Атрофия десны")
      }, {
        video: `${CDN_DOMAIN}/Videos/Атрофия десны Colleagial.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Атрофия десны Colleagial.jpg`,
        title: _t("Атрофия десны")
      }],
      photo: [{
        photo: require("./Photos/Mikro_prothrsis.jpg"),
        title: _t("Микропротез 11 зуб")
      }, {
        photo: require("./Photos/Фото3_HZ6YokS.jpg"),
        title: _t("Нейлоновый протез")
      }, {
        photo: require("./Photos/Фото3_aPbFYpa.jpg"),
        title: _t("Частичный съемный протез")
      }, {
        photo: require("./Photos/Фото1_MThM8i1.jpg"),
        title: _t("Съемный протез на челюсти")
      }, {
        photo: require("./Photos/Фото3_3FW9EL6.jpg"),
        title: _t("Протез на телескопических коронках")
      }, {
        photo: require("./Photos/Фото3_mJwxwnN.jpg"),
        title: _t("Протез с фиксацией на аттачментах")
      }, {
        photo: require("./Photos/Фото5_ao9ptHy.jpg"),
        title: _t("Съемные протезы")
      }, {
        photo: require("./Photos/Фото7_2u1GCuo.jpg"),
        title: _t("Протез с фиксацией на аттачментах 2")
      }, {
        photo: require("./Photos/Полный Съемный протез.jpg"),
        title: _t("Полные съемные протезы")
      }, {
        photo: require("./Photos/Полный Съемный протез 3.jpg"),
        title: _t("Полный съемный протез")
      }, {
        photo: require("./Photos/Полный Съемный протез 2.jpg"),
        title: _t("Полные съемные протезы")
      }],
      vr: [{
        folder: "Bugel_metal",
        xCount: 90,
        yCount: 0,
        title: _t("Бюгельный протез"),
        preview: require("./VRPacks/Bugel_metal/0_31.jpg")
      }, {
        folder: "Neilon_prot",
        xCount: 90,
        yCount: 0,
        title: _t("Нейлоновый протез"),
        preview: require("./VRPacks/Neilon_prot/0_31.jpg")
      }, {
        folder: "partialAcrylicDenture",
        xCount: 90,
        yCount: 0,
        title: _t("Акриловый пластинчатый протез"),
        preview: require("./VRPacks/partialAcrylicDenture/0_17.jpg")
      }, {
        folder: "bug_attach",
        xCount: 86,
        yCount: 0,
        title: _t("Протез на аттачментах"),
        preview: require("./VRPacks/bug_attach/0_31.jpg")
      }, {
        folder: "bugelonBims",
        xCount: 90,
        yCount: 0,
        title: _t("Протез на балках"),
        preview: require("./VRPacks/bugelonBims/0_31.jpg")
      }, {
        folder: "microprosthesis",
        xCount: 90,
        yCount: 0,
        title: _t("Съемный микропротез"),
        preview: require("./VRPacks/microprosthesis/0_31.jpg")
      }, {
        folder: "completeRemovableDenture",
        xCount: 90,
        yCount: 0,
        title: _t("Полный съемный протез"),
        preview: require("./VRPacks/completeRemovableDenture/0_56.jpg")
      }]
    }, {
      id: "crownRestavrationMedia",
      title: _t("Реставрация коронковой части зуба"),
      preview: require("./Images/media_root_restavration.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/культвая вкладка металл.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/культвая вкладка металл.jpg`,
        title: _t("Металлическая культевая вкладка")
      }, {
        video: `${CDN_DOMAIN}/Videos/Metal Root Tab.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Metal Root Tab.jpg`,
        title: _t("Разборная культевая вкладка")
      }, {
        video: `${CDN_DOMAIN}/Videos/композитная культивая вкладка.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/композитная культивая вкладка.jpg`,
        title: _t("Композитная культевая вкладка")
      }, {
        video: `${CDN_DOMAIN}/Videos/реставрация культи зуба.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/реставрация культи зуба.jpg`,
        title: _t("Реставрация культи зуба")
      }, {
        video: `${CDN_DOMAIN}/Videos/Pen Teeth.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Pen Teeth.jpg`,
        title: _t("Штифтовый зуб")
      }, {
        video: `${CDN_DOMAIN}/Videos/композитный штифтовый зуб.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/композитный штифтовый зуб.jpg`,
        title: _t("Композитный штифтовый зуб")
      }, {
        video: `${CDN_DOMAIN}/Videos/штифтовый зуб.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/штифтовый зуб.jpg`,
        title: _t("Штифтовый зуб")
      }],
      photo: [{
        photo: require("./Photos/культвая вкладка металл..jpg"),
        title: _t("Металлическая культевая вкладка 46 зуб")
      }, {
        photo: require("./Photos/культвая вкладка металл.1.jpg"),
        title: _t("Металлическая культевая вкладка 46 зуб")
      }, {
        photo: require("./Photos/культвая вкладка металл2..jpg"),
        title: _t("Металлическая культевая вкладка 46 зуб")
      }, {
        photo: require("./Photos/rootTab3.jpg"),
        title: _t("Металлическая культевая вкладка 45 зуб")
      }, {
        photo: require("./Photos/rootTab4.jpg"),
        title: _t("Металлическая культевая вкладка 45 зуб")
      }, {
        photo: require("./Photos/Культивая вкладка.jpg"),
        title: _t("Разборная культевая вкладка 26 зуб")
      }, {
        photo: require("./Photos/rootTab2.jpg"),
        title: _t("Разборная культевая вкладка 26 зуб")
      }, {
        photo: require("./Photos/rootTab.jpg"),
        title: _t("Разборная культевая вкладка 26 зуб")
      }, {
        photo: require("./Photos/реставрация культи зуба..jpg"),
        title: _t("Реставрация культи зуба")
      }, {
        photo: require("./Photos/культи зуб.jpg"),
        title: _t("Реставрация культи зуба")
      }, {
        photo: require("./Photos/культи зуб1.jpg"),
        title: _t("Реставрация культи зуба")
      }, {
        photo: require("./Photos/культи зуб2.jpg"),
        title: _t("Реставрация культи зуба")
      }, {
        photo: require("./Photos/культивая вкладка (2).jpg"),
        title: _t("Композитная культевая вкладка")
      }, {
        photo: require("./Photos/культивая вкладка1.jpg"),
        title: _t("Композитная культевая вкладка")
      }, {
        photo: require("./Photos/pinTooth.jpg"),
        title: _t("Штифтовый зуб")
      }, {
        photo: require("./Photos/штифтовый зуб..jpg"),
        title: _t("Штифтовый зуб")
      }, {
        photo: require("./Photos/штифтовый зуб1.jpg"),
        title: _t("Штифтовый зуб")
      }, {
        photo: require("./Photos/штифтовый зуб2.jpg"),
        title: _t("Штифтовый зуб")
      }, {
        photo: require("./Photos/штифтовый зуб композит.jpg"),
        title: _t("Композитный штифтовый зуб")
      }, {
        photo: require("./Photos/штифтовый зуб композит1.jpg"),
        title: _t("Композитный штифтовый зуб")
      }]
    }, {
      id: "othersMedia",
      title: _t("Другое"),
      preview: require("./Images/addition.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/partial-adentia.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/partial-adentia_preview.jpg`,
        title: _t("Последствия адентии")
      }, {
        video: `${CDN_DOMAIN}/Videos/Зубы человека.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Зубы-человека.png`,
        title: _t("Развитие временных и постоянных зубов")
      }, {
        video: `${CDN_DOMAIN}/Videos/Зубы человека3.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Зубы-человека3.png`,
        title: _t("Нервы и сосуды")
      }, {
        video: `${CDN_DOMAIN}/Videos/Частичная адентия.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Частичная-адентия.png`,
        title: _t("Частичная адентия")
      }, {
        video: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах.png`,
        title: _t("Протез на мульти-юнитах Owerpress")
      }, {
        video: `${CDN_DOMAIN}/Videos/Типы_кости.mp4`,
        videoPoster: require("./Photos/Типы_кости_2.jpg"),
        title: _t("Типы кости")
      }, {
        video: `${CDN_DOMAIN}/Videos/Implantaciya S Pomoshyu Hirurgicheskogo Shablona.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Implantaciya-S-Pomoshyu-Hirurgicheskogo-Shablona.png`,
        title: _t("Хирургический шаблон на верхнюю челюсть")
      }, {
        video: `${CDN_DOMAIN}/Videos/Шаблон Пилотный Протокол-26.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Шаблон-Пилотный-Протокол-26.png`,
        title: _t("Хирургический шаблон, пилотный протокол")
      }, {
        video: `${CDN_DOMAIN}/Videos/Шаблон Полный Протокол-27.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Шаблон-Полный-Протокол-27.png`,
        title: _t("Хирургический шаблон, полный протокол")
      }, {
        video: `${CDN_DOMAIN}/Videos/Синус_лифтинг_имплантация.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Синус_лифтинг_имплантация.jpg`,
        title: _t("Имплантация после синус-лифтинга")
      }, {
        video: `${CDN_DOMAIN}/Videos/Закрытый_синус_лифтинг.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Закрытый_синус_лифтинг.jpg`,
        title: _t("Закрытый синус-лифтинг")
      }, {
        video: `${CDN_DOMAIN}/Videos/Открытый_синус_лифтинг.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Открытый_синус_лифтинг.jpg`,
        title: _t("Открытый синус-лифтинг")
      }, {
        video: `${CDN_DOMAIN}/Videos/Восстановление_Костной_Ткани.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Восстановление_Костной_Ткани.jpg`,
        title: _t("Восстановление костной ткани после удаления")
      }, {
        video: `${CDN_DOMAIN}/Videos/Peremeshenie_Kostnogo_Bloka.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Peremeshenie_Kostnogo_Bloka.jpg`,
        title: _t("Восстановление костной ткани")
      }, {
        video: `${CDN_DOMAIN}/Videos/36 импл орто видео.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/36 импл орто видео.jpg`,
        title: _t("Создание места под имплант")
      }, {
        video: `${CDN_DOMAIN}/Videos/Гингива Импланты.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Гингива Импланты.jpg`,
        title: _t("Десневая пластика после имплантации")
      }, {
        video: `${CDN_DOMAIN}/Videos/Horizontalaugmentation.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Horizontalaugmentation.jpg`,
        title: _t("Горизонтальная аугментация")
      }, {
        video: `${CDN_DOMAIN}/Videos/Verticalaugmentation.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Verticalaugmentation.jpg`,
        title: _t("Вертикальная аугментация")
      }, {
        video: `${CDN_DOMAIN}/Videos/Ugmentationimplantation.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Ugmentationimplantation.jpg`,
        title: _t("Аугментация при имплантации")
      }],
      photo: [{
        photo: require("./Photos/teeth.jpg"),
        title: _t("Зубы человека")
      }, {
        photo: require("./Photos/Включенный дефект фронт.jpg"),
        title: _t("Включенный дефект во фронтальном участке")
      }, {
        photo: require("./Photos/Включенный дефект бок.jpg"),
        title: _t("Включенный дефект в боковом участке")
      }, {
        photo: require("./Photos/Концевой дефект бок.jpg"),
        title: _t("Концевой дефект в боковом участке")
      }, {
        photo: require("./Photos/Частичная-адентия-1.jpg"),
        title: _t("Частичная адентия 1")
      }, {
        photo: require("./Photos/Частичная-адентия-2.jpg"),
        title: _t("Частичная адентия 2")
      }, {
        photo: require("./Photos/Частичная-адентия-3.jpg"),
        title: _t("Частичная адентия 3")
      }, {
        photo: require("./Photos/overpress_1.jpg"),
        title: _t("Over press")
      }, {
        photo: require("./Photos/overpress.jpg"),
        title: _t("Over press")
      }, {
        photo: require("./Photos/мульти.30.jpg"),
        title: _t("Over press")
      }, {
        photo: require("./Photos/Типы_кости_2.jpg"),
        title: _t("Типы кости")
      }, {
        photo: require("./Photos/шаблон 1.jpg"),
        title: _t("Хирургический шаблон")
      }, {
        photo: require("./Photos/шаблон 3.jpg"),
        title: _t("Хирургический шаблон на 1 зуб")
      }, {
        photo: require("./Photos/синус 5.jpg"),
        title: _t("Синус-лифтинг")
      }, {
        photo: require("./Photos/синус 6.jpg"),
        title: _t("Синус-лифтинг")
      }, {
        photo: require("./Photos/синус 7.jpg"),
        title: _t("Синус-лифтинг")
      }, {
        photo: require("./Photos/Восстановление_костной_ткани.jpg"),
        title: _t("Восстановление кости")
      }, {
        photo: require("./Photos/Восстановление_костной_ткани2.jpg"),
        title: _t("Восстановление кости")
      }],
      vr: [{
        folder: "adentia",
        xCount: 90,
        yCount: 0,
        title: _t("Частичная адентия"),
        preview: require("./VRPacks/adentia/0_82.jpg")
      }]
    }]
  }, {
    id: "aestheticRestorationsMedia",
    title: _t("Эстетические реставрации"),
    description: _t("Виниринг, реставрации коронками, реставрации композитными материалами. Фасоны зубов."),
    preview: require("./Images/media_aestet.png"),
    sections: [{
      id: "veneersMedia",
      title: _t("Виниры"),
      preview: require("./Images/Винир.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/винир_1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/винир_превью_1.jpg`,
        title: _t("Винир")
      }, {
        video: `${CDN_DOMAIN}/Videos/винир_2.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/винир_превью_2.jpg`,
        title: _t("Виниры на передние зубы")
      }, {
        video: `${CDN_DOMAIN}/Videos/винир_3.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/винир_превью_3.jpg`,
        title: _t("Обработка зубов под виниры")
      }, {
        video: `${CDN_DOMAIN}/Videos/Ceramic_Tabs.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Ceramic_Tabs.jpg`,
        title: _t("Керамические вкладки")
      }],
      photo: [{
        photo: require("./Photos/виниры_0.jpg"),
        title: _t("Виниры")
      }, {
        photo: require("./Photos/виниры_1.jpg"),
        title: _t("Винир")
      }, {
        photo: require("./Photos/виниры_2.jpg"),
        title: _t("Виниры готовые")
      }, {
        photo: require("./Photos/виниры_3.jpg"),
        title: _t("Виниры на модели")
      }, {
        photo: require("./Photos/виниры_4.jpg"),
        title: _t("Виниры")
      }, {
        photo: require("./Photos/виниры_5.jpg"),
        title: _t("Фиксация виниров")
      }],
      vr: [{
        folder: "Veneer_21",
        xCount: 90,
        yCount: 0,
        title: _t("Винир"),
        preview: require("./VRPacks/Veneer_21/0_6.jpg")
      }, {
        folder: "Veneers",
        xCount: 38,
        yCount: 0,
        title: _t("Виниры на передних зубах"),
        preview: require("./VRPacks/Veneers/0_1.jpg")
      }, {
        folder: "Veneers_2",
        xCount: 91,
        yCount: 0,
        title: _t("Виниры"),
        preview: require("./VRPacks/Veneers_2/0_54.jpg")
      }]
    }, {
      id: "crownsMedia",
      title: _t("Коронки"),
      preview: require("./Images/Коронка.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/коронки_1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/коронки_превью_1.jpg`,
        title: _t("Коронки на передние зубы")
      }, {
        video: `${CDN_DOMAIN}/Videos/коронки_2.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/коронки_превью_2.jpg`,
        title: _t("Коронки на всю челюсть")
      }, {
        video: `${CDN_DOMAIN}/Videos/коронки_3.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/коронки_превью_3.jpg`,
        title: _t("Обработка зубов под коронки")
      }],
      photo: [{
        photo: require("./Photos/коронки_0.jpg"),
        title: _t("Коронка")
      }, {
        photo: require("./Photos/коронки_1.jpg"),
        title: _t("Коронки на верхнюю челюсть")
      }, {
        photo: require("./Photos/коронки_2.jpg"),
        title: _t("Коронки на нижнюю челюсть")
      }, {
        photo: require("./Photos/коронки_3.jpg"),
        title: _t("Коронки")
      }, {
        photo: require("./Photos/коронки_4.jpg"),
        title: _t("Обработка зубов под коронки")
      }, {
        photo: require("./Photos/коронки_5.jpg"),
        title: _t("Обработка зубов под коронки")
      }, {
        photo: require("./Photos/коронки_6.jpg"),
        title: _t("Коронки на верхнюю челюсть")
      }, {
        photo: require("./Photos/коронки_7.jpg"),
        title: _t("Коронки на нижнюю челюсть")
      }],
      vr: [{
        folder: "Crowns",
        xCount: 101,
        yCount: 0,
        title: _t("Коронки"),
        preview: require("./VRPacks/Crowns/0_6.jpg")
      }]
    }, {
      id: "restavrationsMedia",
      title: _t("Композитные реставрации"),
      preview: require("./Images/Реставрации.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/реставрации_1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/реставрации_превью_1.jpg`,
        title: _t("Композитная реставрация переднего зуба")
      }, {
        video: `${CDN_DOMAIN}/Videos/реставрации_2.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/реставрации_превью_2.jpg`,
        title: _t("Композитные реставрации передних зубов")
      }, {
        video: `${CDN_DOMAIN}/Videos/реставрации_3.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/реставрации_превью_3.jpg`,
        title: _t("Композитная реставрация скола на зубе")
      }],
      photo: [{
        photo: require("./Photos/реставрации_0.jpg"),
        title: _t("Реставрации")
      }, {
        photo: require("./Photos/реставрации_1.jpg"),
        title: _t("Реставрации")
      }, {
        photo: require("./Photos/реставрации_2.jpg"),
        title: _t("Реставрации")
      }, {
        photo: require("./Photos/реставрации_3.jpg"),
        title: _t("Реставрации")
      }, {
        photo: require("./Photos/реставрации_4.jpg"),
        title: _t("Реставрации")
      }, {
        photo: require("./Photos/реставрации_5.jpg"),
        title: _t("Реставрации")
      }, {
        photo: require("./Photos/реставрации_6.jpg"),
        title: _t("Реставрации")
      }, {
        photo: require("./Photos/реставрации_7.jpg"),
        title: _t("Реставрации")
      }],
      vr: [{
        folder: "restavrations",
        xCount: 30,
        yCount: 0,
        title: _t("Композитные реставрации"),
        preview: require("./VRPacks/restavrations/0_15.jpg")
      }]
    }, {
      id: "womanTeethStylesMedia",
      title: _t("Женские фасоны зубов"),
      preview: require("./Images/woman.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/DSD_woman.mp4`,
        videoPoster: require("./Photos/0.0_woman.jpg"),
        title: _t("Женские фасоны зубов")
      }],
      photo: [{
        photo: require("./Photos/Styles2.jpg"),
        title: _t("Pogarskiy Design Library")
      }, {
        photo: require("./Photos/Styles1.jpg"),
        title: _t("Expression Tooth library Neo")
      }, {
        photo: require("./Photos/0.0_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 0.0")
      }, {
        photo: require("./Photos/0.1_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 0.1")
      }, {
        photo: require("./Photos/0.2_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 0.2")
      }, {
        photo: require("./Photos/0.3_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 0.3")
      }, {
        photo: require("./Photos/1.0_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 1.0")
      }, {
        photo: require("./Photos/1.1_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 1.1")
      }, {
        photo: require("./Photos/1.2_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 1.2")
      }, {
        photo: require("./Photos/1.3_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 1.3")
      }, {
        photo: require("./Photos/1.4_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 1.4")
      }, {
        photo: require("./Photos/2.0_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 2.0")
      }, {
        photo: require("./Photos/2.2_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 2.2")
      }, {
        photo: require("./Photos/3.0_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 3.0")
      }, {
        photo: require("./Photos/3.1_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 3.1")
      }, {
        photo: require("./Photos/3.2_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 3.2")
      }, {
        photo: require("./Photos/3.3_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 3.3")
      }, {
        photo: require("./Photos/4.0_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 4.0")
      }, {
        photo: require("./Photos/4.1_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 4.1")
      }, {
        photo: require("./Photos/4.2_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 4.2")
      }, {
        photo: require("./Photos/5.0_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 5.0")
      }, {
        photo: require("./Photos/5.1_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 5.1")
      }, {
        photo: require("./Photos/5.2_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 5.2")
      }, {
        photo: require("./Photos/5.3_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 5.3")
      }, {
        photo: require("./Photos/5.4_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 5.4")
      }, {
        photo: require("./Photos/6.0_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 6.0")
      }, {
        photo: require("./Photos/6.1_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 6.1")
      }, {
        photo: require("./Photos/6.3_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 6.3")
      }, {
        photo: require("./Photos/6.4_woman.jpg"),
        title: _t("Pogarskiy Design Library Style 6.4")
      }, {
        photo: require("./Photos/D1_woman.jpg"),
        title: _t("Expression Tooth library Neo D1")
      }, {
        photo: require("./Photos/G1_woman.jpg"),
        title: _t("Expression Tooth library Neo G1")
      }, {
        photo: require("./Photos/O1_woman.jpg"),
        title: _t("Expression Tooth library Neo O1")
      }, {
        photo: require("./Photos/S1_woman.jpg"),
        title: _t("Expression Tooth library Neo S1")
      }, {
        photo: require("./Photos/U1_woman.jpg"),
        title: _t("Expression Tooth library Neo U1")
      }, {
        photo: require("./Photos/V1_woman.jpg"),
        title: _t("Expression Tooth library Neo V1")
      }]
    }, {
      id: "manTeethStylesMedia",
      title: _t("Мужские фасоны зубов"),
      preview: require("./Images/man.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/DSD_man.mp4`,
        videoPoster: require("./Photos/0.0_man.jpg"),
        title: _t("Мужские фасоны зубов")
      }],
      photo: [{
        photo: require("./Photos/Styles2.jpg"),
        title: _t("Pogarskiy Design Library")
      }, {
        photo: require("./Photos/Styles1.jpg"),
        title: _t("Expression Tooth library Neo")
      }, {
        photo: require("./Photos/0.0_man.jpg"),
        title: _t("Pogarskiy Design Library Style 0.0")
      }, {
        photo: require("./Photos/0.1_man.jpg"),
        title: _t("Pogarskiy Design Library Style 0.1")
      }, {
        photo: require("./Photos/0.2_man.jpg"),
        title: _t("Pogarskiy Design Library Style 0.2")
      }, {
        photo: require("./Photos/0.3_man.jpg"),
        title: _t("Pogarskiy Design Library Style 0.3")
      }, {
        photo: require("./Photos/1.0_man.jpg"),
        title: _t("Pogarskiy Design Library Style 1.0")
      }, {
        photo: require("./Photos/1.1_man.jpg"),
        title: _t("Pogarskiy Design Library Style 1.1")
      }, {
        photo: require("./Photos/1.2_man.jpg"),
        title: _t("Pogarskiy Design Library Style 1.2")
      }, {
        photo: require("./Photos/1.3_man.jpg"),
        title: _t("Pogarskiy Design Library Style 1.3")
      }, {
        photo: require("./Photos/2.0_man.jpg"),
        title: _t("Pogarskiy Design Library Style 2.0")
      }, {
        photo: require("./Photos/2.2_man.jpg"),
        title: _t("Pogarskiy Design Library Style 2.2")
      }, {
        photo: require("./Photos/3.3_man.jpg"),
        title: _t("Pogarskiy Design Library Style 3.3")
      }, {
        photo: require("./Photos/4.0_man.jpg"),
        title: _t("Pogarskiy Design Library Style 4.0")
      }, {
        photo: require("./Photos/4.1_man.jpg"),
        title: _t("Pogarskiy Design Library Style 4.1")
      }, {
        photo: require("./Photos/5.0_man.jpg"),
        title: _t("Pogarskiy Design Library Style 5.0")
      }, {
        photo: require("./Photos/5.1_man.jpg"),
        title: _t("Pogarskiy Design Library Style 5.1")
      }, {
        photo: require("./Photos/5.2_man.jpg"),
        title: _t("Pogarskiy Design Library Style 5.2")
      }, {
        photo: require("./Photos/5.4_man.jpg"),
        title: _t("Pogarskiy Design Library Style 5.4")
      }, {
        photo: require("./Photos/6.0_man.jpg"),
        title: _t("Pogarskiy Design Library Style 6.0")
      }, {
        photo: require("./Photos/6.1_man.jpg"),
        title: _t("Pogarskiy Design Library Style 6.1")
      }, {
        photo: require("./Photos/6.3_man.jpg"),
        title: _t("Pogarskiy Design Library Style 6.3")
      }, {
        photo: require("./Photos/6.4_man.jpg"),
        title: _t("Pogarskiy Design Library Style 6.4")
      }, {
        photo: require("./Photos/D1_man.jpg"),
        title: _t("Expression Tooth library Neo D1")
      }, {
        photo: require("./Photos/G1_man.jpg"),
        title: _t("Expression Tooth library Neo G1")
      }, {
        photo: require("./Photos/P1_man.jpg"),
        title: _t("Expression Tooth library Neo P1")
      }, {
        photo: require("./Photos/T1_man.jpg"),
        title: _t("Expression Tooth library Neo T1")
      }, {
        photo: require("./Photos/U1_man.jpg"),
        title: _t("Expression Tooth library Neo U1")
      }, {
        photo: require("./Photos/V1_man.jpg"),
        title: _t("Expression Tooth library Neo V1")
      }]
    }, {
      id: "gingivaMedia",
      title: _t("Десневая пластика"),
      preview: require("./Images/gingivalPlastic.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/gigivaLaser.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/gingivectomy.png`,
        title: _t("Коррекция формы десен лазером")
      }, {
        video: `${CDN_DOMAIN}/Videos/Гингива Виниры.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Гингива Виниры.jpg`,
        title: _t("Виниринг после гингивальной пластики")
      }, {
        video: `${CDN_DOMAIN}/Videos/гингива длинные шейки.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/гингива длинные шейки.jpg`,
        title: _t("Удлиненные шейки")
      }, {
        video: `${CDN_DOMAIN}/Videos/Гингива черные треугольники.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Гингива черные треугольники.jpg`,
        title: _t("Пластика десны при черных треугольниках")
      }],
      photo: [{
        photo: require("./Photos/Черные треугольники.jpg"),
        title: _t("Черные треугольники")
      }, {
        photo: require("./Photos/Гингива докнормк.5525.jpg"),
        title: _t("Удлиненные шейки")
      }, {
        photo: require("./Photos/Гингива докнорм.5525.jpg"),
        title: _t("Черные треугольники")
      }]
    }]
  }, {
    id: "orthodonticMedia",
    title: _t("Ортодонтия"),
    description: _t("Виды брекет систем, элайнеры, вспомогательные системы и методики."),
    preview: require("./Images/media_ortho.png"),
    sections: [{
      id: "alignersMedia",
      title: _t("Элайнеры"),
      preview: require("./Images/aligners.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Alligners1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Alligners1.jpg`,
        title: _t("Элайнеры")
      }, {
        video: `${CDN_DOMAIN}/Videos/Alligners2.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Alligners2.jpg`,
        title: _t("Элайнер")
      }, {
        video: `${CDN_DOMAIN}/Videos/Aligner_2.mp4`,
        videoPoster: require("./Photos/Капа окклюзионная 2.437.jpg"),
        title: _t("Элайнер, капа")
      }],
      photo: [{
        photo: require("./Photos/aligner1.jpg"),
        title: _t("Элайнер")
      }, {
        photo: require("./Photos/aligner2.jpg"),
        title: _t("Элайнер")
      }, {
        photo: require("./Photos/aligner3.jpg"),
        title: _t("Элайнер")
      }, {
        photo: require("./Photos/aligner4.jpg"),
        title: _t("Элайнеры")
      }, {
        photo: require("./Photos/aligner5.jpg"),
        title: _t("Элайнеры")
      }, {
        photo: require("./Photos/aligner6.jpg"),
        title: _t("Элайнер")
      }, {
        photo: require("./Photos/aligner7.jpg"),
        title: _t("Эластопозиционеры")
      }, {
        photo: require("./Photos/aligner8.jpg"),
        title: _t("Эластопозиционер")
      }],
      vr: [{
        folder: "Aligner",
        xCount: 75,
        yCount: 0,
        title: _t("Элайнер"),
        preview: require("./VRPacks/Aligner/0_40.jpg")
      }]
    }, {
      id: "metalBracesMedia",
      title: _t("Металлические брекеты"),
      preview: require("./Images/metall_br.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/classicBraces.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/classicBraces.jpg`,
        title: _t("Классические металлические брекеты")
      }],
      photo: [{
        photo: require("./VRPacks/ligature/0_13.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }],
      vr: [{
        folder: "metallBraces",
        xCount: 94,
        yCount: 0,
        title: _t("Металлические брекеты"),
        preview: require("./VRPacks/metallBraces/0_50.jpg")
      }]
    }, {
      id: "ceramicBracesMedia",
      title: _t("Керамические брекеты"),
      preview: require("./Images/ceram_br.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/ceramicBraces.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/ceramicBraces.jpg`,
        title: _t("Керамические брекеты")
      }],
      photo: [{
        photo: require("./Photos/ceramicBraces.jpg"),
        title: _t("Керамические брекеты")
      }, {
        photo: require("./Photos/ceramicBraces1.jpg"),
        title: _t("Керамические брекеты")
      }, {
        photo: require("./Photos/ceramicBraces2.jpg"),
        title: _t("Керамические брекеты")
      }, {
        photo: require("./Photos/ceramicBraces3.jpg"),
        title: _t("Керамические брекеты")
      }],
      vr: [{
        folder: "ceramicBraces",
        xCount: 91,
        yCount: 0,
        title: _t("Керамические брекеты"),
        preview: require("./VRPacks/ceramicBraces/0_50.jpg")
      }]
    }, {
      id: "sapfirBracesMedia",
      title: _t("Сапфировые брекеты"),
      preview: require("./Images/sapfirf_br.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/sapphireBraces.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/sapphireBraces.jpg`,
        title: _t("Сапфировые брекеты")
      }],
      photo: [{
        photo: require("./Photos/sapphireBraes.jpg"),
        title: _t("Сапфировые брекеты")
      }, {
        photo: require("./Photos/sapphireBraes1.jpg"),
        title: _t("Сапфировые брекеты")
      }, {
        photo: require("./Photos/sapphireBraes2.jpg"),
        title: _t("Сапфировые брекеты")
      }, {
        photo: require("./Photos/sapphireBraes3.jpg"),
        title: _t("Сапфировые брекеты")
      }],
      vr: [{
        folder: "sapfirBraces",
        xCount: 93,
        yCount: 0,
        title: _t("Сапфировые брекеты"),
        preview: require("./VRPacks/sapfirBraces/0_50.jpg")
      }]
    }, {
      id: "plasticBracesMedia",
      title: _t("Пластиковые брекеты"),
      preview: require("./Images/ceram_br.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/ceramicBraces.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/ceramicBraces.jpg`,
        title: _t("Пластиковые брекеты")
      }],
      photo: [{
        photo: require("./Photos/ceramicBraces.jpg"),
        title: _t("Пластиковые брекеты")
      }],
      vr: [{
        folder: "ceramicBraces",
        xCount: 91,
        yCount: 0,
        title: _t("Пластиковые брекеты"),
        preview: require("./VRPacks/ceramicBraces/0_50.jpg")
      }]
    }, {
      id: "selfLigBracesMedia",
      title: _t("Самолигирующие брекеты"),
      preview: require("./Images/self_lig_br.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/self-ligatingBraces.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/self-ligatingBraces.jpg`,
        title: _t("Самолигирующие брекеты")
      }, {
        video: `${CDN_DOMAIN}/Videos/брекет самолиг керам.mp4`,
        videoPoster: require("./Photos/ceramicBraces2.jpg"),
        title: _t("Брекеты самолигирующие керамические")
      }],
      photo: [{
        photo: require("./Photos/ligature1.jpg"),
        title: _t("Металлические самолигирующие брекеты")
      }, {
        photo: require("./Photos/ceramicBraces2.jpg"),
        title: _t("Керамические самолигирующие брекеты")
      }],
      vr: [{
        folder: "selfLigatyresBraces",
        xCount: 95,
        yCount: 0,
        title: _t("Металлические самолигирующие брекеты"),
        preview: require("./VRPacks/selfLigatyresBraces/0_50.jpg")
      }, {
        folder: "ceramicSelfLigBraces",
        xCount: 95,
        yCount: 0,
        title: _t("Керамические самолигирующие брекеты"),
        preview: require("./VRPacks/ceramicSelfLigBraces/0_50.jpg")
      }]
    }, {
      id: "lingvalMedia",
      title: _t("Лингвальные брекеты"),
      preview: require("./Images/lingvall_br.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/lingualBraces.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/lingualBraces.jpg`,
        title: _t("Лингвальные брекеты")
      }],
      photo: [{
        photo: require("./Photos/lingvalBraces.jpg"),
        title: _t("Лингвальные брекеты")
      }, {
        photo: require("./Photos/lingvalBraces2.jpg"),
        title: _t("Лингвальные брекеты")
      }, {
        photo: require("./Photos/lingvalBraces3.jpg"),
        title: _t("Лингвальные брекеты")
      }, {
        photo: require("./Photos/lingvalBraces4.jpg"),
        title: _t("Лингвальные брекеты")
      }, {
        photo: require("./Photos/lingvalBraces5.jpg"),
        title: _t("Лингвальные брекеты")
      }, {
        photo: require("./Photos/lingvalBraces6.jpg"),
        title: _t("Лингвальные брекеты")
      }, {
        photo: require("./Photos/lingvalBraces7.jpg"),
        title: _t("Лингвальные брекеты")
      }],
      vr: [{
        folder: "lingvalBraces",
        xCount: 85,
        yCount: 0,
        title: _t("Лингвальные брекеты"),
        preview: require("./VRPacks/lingvalBraces/0_50.jpg")
      }]
    }, {
      id: "lfLigBracesMedia",
      title: _t("Лигатуры на брекеты"),
      preview: require("./Images/ligatura.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Multi-Colored Ligature On Braces.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Multi-Colored Ligature On Braces.jpg`,
        title: _t("Цветная лигатура")
      }],
      photo: [{
        photo: require("./VRPacks/ligature/0_0.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_1.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_2.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_3.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_4.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_5.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_6.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_7.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_8.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_9.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_10.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_11.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_12.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_13.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_14.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_15.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_16.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_17.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }, {
        photo: require("./VRPacks/ligature/0_18.jpg"),
        title: _t("Цветная лигатура на брекетах")
      }],
      vr: [{
        folder: "ligature",
        xCount: 19,
        yCount: 0,
        title: _t("Цветная лигатура на брекетах"),
        preview: require("./VRPacks/ligature/0_5.jpg")
      }]
    }, {
      id: "orthoPlateMedia",
      title: _t("Ортодонтические пластины"),
      preview: require("./Images/Ortho_plate.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Ортодонтические_Пластины-18.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Ортодонтические_Пластины-18.jpg`,
        title: _t("Ортодонтические пластины")
      }, {
        video: `${CDN_DOMAIN}/Videos/Пластинка_Ортодонтическая-21.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Пластинка_Ортодонтическая-21.jpg`,
        title: _t("Ортодонтическая пластина")
      }],
      photo: [{
        photo: require("./Photos/Ortho_plate.jpg"),
        title: _t("Ортодонтические пластины")
      }, {
        photo: require("./Photos/Ortho_plate1.jpg"),
        title: _t("Ортодонтические пластины")
      }, {
        photo: require("./Photos/Ortho_plate2.jpg"),
        title: _t("Ортодонтическая пластина")
      }, {
        photo: require("./Photos/Ortho_plate3.jpg"),
        title: _t("Ортодонтическая пластина")
      }, {
        photo: require("./Photos/Ortho_plate4.jpg"),
        title: _t("Ортодонтические пластины")
      }, {
        photo: require("./Photos/Ortho_plate5.jpg"),
        title: _t("Ортодонтическая пластина")
      }, {
        photo: require("./Photos/Ortho_plate6.jpg"),
        title: _t("Ортодонтическая пластина")
      }]
    }, {
      id: "reteinerMedia",
      title: _t("Ортодонтический ретейнер"),
      preview: require("./Images/reteiner.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/reteinerHard.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/reteinerHardPrev.jpg`,
        title: _t("Жесткий несъемный ретейнер")
      }, {
        video: `${CDN_DOMAIN}/Videos/reteinerIndividual.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/reteinerIndividualPrev.jpg`,
        title: _t("Жесткий индивидуальный ретейнер")
      }, {
        video: `${CDN_DOMAIN}/Videos/reteinerRemove.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/reteinerRemovePrev.jpg`,
        title: _t("Съемный ретейнер")
      }],
      photo: [{
        photo: require("./Photos/reteinerHard.jpg"),
        title: _t("Жесткий несъемный ретейнер")
      }, {
        photo: require("./Photos/reteinerHard1.jpg"),
        title: _t("Жесткий несъемный ретейнер")
      }, {
        photo: require("./Photos/reteinerIndividual.jpg"),
        title: _t("Жесткий индивидуальный ретейнер")
      }, {
        photo: require("./Photos/reteinerIndividual1.jpg"),
        title: _t("Жесткий индивидуальный ретейнер")
      }, {
        photo: require("./Photos/reteinerRemove.jpg"),
        title: _t("Съемный ретейнер")
      }, {
        photo: require("./Photos/reteinerRemove1.jpg"),
        title: _t("Съемный ретейнер")
      }]
    }, {
      id: "classMedia",
      title: _t("Классификация ортодонтических аномалий"),
      preview: require("./Images/classification.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Orthodontic Classification.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Orthodontic Classification.jpg`,
        title: _t("Классификация ортодонтических аномалий")
      }],
      photo: [{
        photo: require("./Photos/Class l.jpg"),
        title: _t("Class l")
      }, {
        photo: require("./Photos/Class ll(l).jpg"),
        title: _t("Class ll(l)")
      }, {
        photo: require("./Photos/Class ll(ll).jpg"),
        title: _t("Class ll(ll)")
      }, {
        photo: require("./Photos/Class lll.jpg"),
        title: _t("Class lll")
      }]
    }, {
      id: "anomaliesMedia",
      title: _t("Виды ортодонтических аномалий"),
      preview: require("./Images/anomalies.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/example_treatment.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/example_treatment.png`,
        title: _t("Пример ортодонтического лечения")
      }, {
        video: `${CDN_DOMAIN}/Videos/Angle's Class II Division I.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Angle's-Class-II-Division-I.png`,
        title: _t("Коррекция II класс по Энглю Подкласс I")
      }, {
        video: `${CDN_DOMAIN}/Videos/Angle's Class II Division II.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Angle's-Class-II-Division-II.png`,
        title: _t("Коррекция II класс по Энглю Подкласс II")
      }, {
        video: `${CDN_DOMAIN}/Videos/Crowding Of Teeth On Both Jaws.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Crowding-Of-Teeth-On-Both-Jaws.png`,
        title: _t("Лечение скученности")
      }, {
        video: `${CDN_DOMAIN}/Videos/Spaces.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Spaces.png`,
        title: _t("Лечение трем и диастем")
      }, {
        video: `${CDN_DOMAIN}/Videos/Openbite.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Openbite.png`,
        title: _t("Лечение открытого прикуса")
      }, {
        video: `${CDN_DOMAIN}/Videos/One Side Angle's Class II.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/One-Side-Angle's-Class-II.png`,
        title: _t("Односторонний II класс по Энглю")
      }, {
        video: `${CDN_DOMAIN}/Videos/One side Angle's Class III.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/One-side-Angle's-Class-III.png`,
        title: _t("Односторонний III класс по Энглю")
      }, {
        video: `${CDN_DOMAIN}/Videos/Expansion Of Dental Arches.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Expansion-Of-Dental-Arches.png`,
        title: _t("Расширение зубной дуги")
      }, {
        video: `${CDN_DOMAIN}/Videos/Cross Bite.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Cross-bite.png`,
        title: _t("Перекрестный прикус")
      }],
      photo: [{
        photo: require("./Photos/angleClass_II_DivisionI.jpg"),
        title: _t("II Класс по Энглю I подкласс")
      }, {
        photo: require("./Photos/angleClass_II_DivisionII.jpg"),
        title: _t("II Класс по Энглю II подкласс")
      }, {
        photo: require("./Photos/crossBite.jpg"),
        title: _t("Перекрестный прикус")
      }, {
        photo: require("./Photos/crowding_Of_Teeth_on_both_jaws.jpg"),
        title: _t("Скученность зубов на обоих челюстях")
      }, {
        photo: require("./Photos/crowding_Of_Teeth_on_one_jaw.jpg"),
        title: _t("Скученность зубов на одной челюсти")
      }, {
        photo: require("./Photos/expansion_Of_dental_arches.jpg"),
        title: _t("Сужение зубных дуг")
      }, {
        photo: require("./Photos/extrudedTooth.jpg"),
        title: _t("Экструзированный зуб")
      }, {
        photo: require("./Photos/incorrect_inclination_Of_the_tooth.jpg"),
        title: _t("Нарушение наклона зуба")
      }, {
        photo: require("./Photos/IntrudedTooth.jpg"),
        title: _t("Интрузированный зуб")
      }, {
        photo: require("./Photos/OneSideAngleClassII.jpg"),
        title: _t("II класс по Энглю с одной стороны")
      }, {
        photo: require("./Photos/OneSideAngleClassIII.jpg"),
        title: _t("III класс по Энглю с одной стороны")
      }, {
        photo: require("./Photos/Openbite.jpg"),
        title: _t("Открытый прикус")
      }, {
        photo: require("./Photos/Overbite_deepBite.jpg"),
        title: _t("Глубокий прикус")
      }, {
        photo: require("./Photos/Overjet_sagittal_Fissure.jpg"),
        title: _t("Сагиттальная щель")
      }, {
        photo: require("./Photos/Protrusion_Of_ the_anterior_teeth.jpg"),
        title: _t("Протрузия")
      }, {
        photo: require("./Photos/Retrusion_Of_the_anterior_teeth.jpg"),
        title: _t("Ретрузия")
      }, {
        photo: require("./Photos/RotatedTooth.jpg"),
        title: _t("Ротированный зуб")
      }, {
        photo: require("./Photos/Small_multiple_irregularities.jpg"),
        title: _t("Небольшие множественные нарушения")
      }, {
        photo: require("./Photos/Spacing.jpg"),
        title: _t("Тремы, диастемы")
      }, {
        photo: require("./Photos/ViolationToothAngulation.jpg"),
        title: _t("Нарушенная ангуляция зуба")
      }],
      vr: [{
        folder: "angleClass_II_DivisionI",
        xCount: 37,
        yCount: 0,
        title: _t("II Класс по Энглю I подкласс"),
        preview: require("./VRPacks/angleClass_II_DivisionI/0_0.jpg")
      }, {
        folder: "angleClass_II_DivisionII",
        xCount: 37,
        yCount: 0,
        title: _t("II Класс по Энглю II подкласс"),
        preview: require("./VRPacks/angleClass_II_DivisionII/0_0.jpg")
      }, {
        folder: "crossBite",
        xCount: 25,
        yCount: 0,
        title: _t("Перекрестный прикус"),
        preview: require("./VRPacks/crossBite/0_0.jpg")
      }, {
        folder: "crowding_Of_Teeth_on_both_jaws",
        xCount: 26,
        yCount: 0,
        title: _t("Скученность зубов на обоих челюстях"),
        preview: require("./VRPacks/crowding_Of_Teeth_on_both_jaws/0_0.jpg")
      }, {
        folder: "crowding_Of_Teeth_on_one_jaw",
        xCount: 25,
        yCount: 0,
        title: _t("Скученность зубов на одной челюсти"),
        preview: require("./VRPacks/crowding_Of_Teeth_on_one_jaw/0_0.jpg")
      }, {
        folder: "expansion_Of_dental_arches",
        xCount: 29,
        yCount: 0,
        title: _t("Сужение зубных дуг"),
        preview: require("./VRPacks/expansion_Of_dental_arches/0_0.jpg")
      }, {
        folder: "extrudedTooth",
        xCount: 29,
        yCount: 0,
        title: _t("Экструзированный зуб"),
        preview: require("./VRPacks/extrudedTooth/0_0.jpg")
      }, {
        folder: "incorrect_inclination_Of_the_tooth",
        xCount: 23,
        yCount: 0,
        title: _t("Нарушение наклона зуба"),
        preview: require("./VRPacks/incorrect_inclination_Of_the_tooth/0_0.jpg")
      }, {
        folder: "IntrudedTooth",
        xCount: 23,
        yCount: 0,
        title: _t("Интрузированный зуб"),
        preview: require("./VRPacks/IntrudedTooth/0_0.jpg")
      }, {
        folder: "OneSideAngleClassII",
        xCount: 27,
        yCount: 0,
        title: _t("II класс по Энглю с одной стороны"),
        preview: require("./VRPacks/OneSideAngleClassII/0_0.jpg")
      }, {
        folder: "OneSideAngleClassIII",
        xCount: 29,
        yCount: 0,
        title: _t("III класс по Энглю с одной стороны"),
        preview: require("./VRPacks/OneSideAngleClassIII/0_0.jpg")
      }, {
        folder: "Openbite",
        xCount: 27,
        yCount: 0,
        title: _t("Открытый прикус"),
        preview: require("./VRPacks/Openbite/0_0.jpg")
      }, {
        folder: "Overbite_deepBite",
        xCount: 25,
        yCount: 0,
        title: _t("Глубокий прикус"),
        preview: require("./VRPacks/Overbite_deepBite/0_0.jpg")
      }, {
        folder: "Overjet_sagittal_Fissure",
        xCount: 27,
        yCount: 0,
        title: _t("Сагиттальная щель"),
        preview: require("./VRPacks/Overjet_sagittal_Fissure/0_0.jpg")
      }, {
        folder: "Protrusion_Of_ the_anterior_teeth",
        xCount: 25,
        yCount: 0,
        title: _t("Протрузия"),
        preview: require("./VRPacks/Protrusion_Of_ the_anterior_teeth/0_0.jpg")
      }, {
        folder: "Retrusion_Of_the_anterior_teeth",
        xCount: 27,
        yCount: 0,
        title: _t("Ретрузия"),
        preview: require("./VRPacks/Retrusion_Of_the_anterior_teeth/0_0.jpg")
      }, {
        folder: "RotatedTooth",
        xCount: 27,
        yCount: 0,
        title: _t("Ротированный зуб"),
        preview: require("./VRPacks/RotatedTooth/0_0.jpg")
      }, {
        folder: "Small_multiple_irregularities",
        xCount: 25,
        yCount: 0,
        title: _t("Небольшие множественные нарушения"),
        preview: require("./VRPacks/Small_multiple_irregularities/0_0.jpg")
      }, {
        folder: "Spacing",
        xCount: 25,
        yCount: 0,
        title: _t("Тремы, диастемы"),
        preview: require("./VRPacks/Spacing/0_0.jpg")
      }, {
        folder: "ViolationToothAngulation",
        xCount: 23,
        yCount: 0,
        title: _t("Нарушенная ангуляция зуба"),
        preview: require("./VRPacks/ViolationToothAngulation/0_0.jpg")
      }]
    }, {
      id: "IPRMedia",
      title: _t("Сепарации (IPR)"),
      preview: require("./Images/IPR.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/IPR.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/IPR.jpg`,
        title: _t("Межапроксимальная редукция")
      }],
      photo: [{
        photo: require("./Photos/IPR.jpg"),
        title: _t("Сепарации")
      }, {
        photo: require("./Photos/IPR1.jpg"),
        title: _t("Сепарации")
      }, {
        photo: require("./Photos/IPR2.jpg"),
        title: _t("Сепарации")
      }]
    }, {
      id: "additionComponentsMedia",
      title: _t("Вспомогательные элементы"),
      preview: require("./Images/additionalOrtho.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/атачменты2_опти.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/атачменты2_опти.jpg`,
        title: _t("Фиксация ортодонтических аттачментов")
      }, {
        video: `${CDN_DOMAIN}/Videos/Аттачмент_опти.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Аттачмент_опти.jpg`,
        title: _t("Аттачменты ортодонтические")
      }, {
        video: `${CDN_DOMAIN}/Videos/кнопки_ортодонтические_2_класс_опти.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/кнопки_ортодонтические_2_класс_опти.jpg`,
        title: _t("Эластики II класс по Энглю")
      }, {
        video: `${CDN_DOMAIN}/Videos/Эластики_3_классопти.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Эластики_3_классопти.jpg`,
        title: _t("Эластики III класс по Энглю")
      }, {
        video: `${CDN_DOMAIN}/Videos/Эластики_Для_экструзииопти.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Эластики_Для_экструзииопти.jpg`,
        title: _t("Эластики для экструзии")
      }, {
        video: `${CDN_DOMAIN}/Videos/Эластики_завершающиеопти.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Эластики_завершающиеопти.jpg`,
        title: _t("Завершающие эластики")
      }],
      photo: [{
        photo: require("./Photos/2_класс_.jpg"),
        title: _t("Эластики II класс по Энглю")
      }, {
        photo: require("./Photos/3_класс.6.jpg"),
        title: _t("Эластики III класс по Энглю")
      }, {
        photo: require("./Photos/Завершающие.jpg"),
        title: _t("Завершающие эластики")
      }, {
        photo: require("./Photos/Экструзия.jpg"),
        title: _t("Эластики для экструзии")
      }, {
        photo: require("./Photos/attachmentsOrtho.jpg"),
        title: _t("Аттачменты ортодонтические")
      }, {
        photo: require("./Photos/attachmentsOrtho1.jpg"),
        title: _t("Аттачменты ортодонтические")
      }, {
        photo: require("./Photos/attachmentsOrtho2.jpg"),
        title: _t("Аттачменты ортодонтические")
      }, {
        photo: require("./Photos/attachmentsOrtho3.jpg"),
        title: _t("Аттачменты ортодонтические")
      }, {
        photo: require("./Photos/attachmentsOrtho4.jpg"),
        title: _t("Аттачменты ортодонтические")
      }, {
        photo: require("./Photos/attachmentsOrtho5.jpg"),
        title: _t("Аттачменты ортодонтические")
      }, {
        photo: require("./Photos/attachmentsOrtho6.jpg"),
        title: _t("Аттачменты ортодонтические")
      }]
    }]
  }, {
    id: "teeehTreatmentMedia",
    title: _t("Терапевтическая стоматология, лечение зубов"),
    description: _t("Пломбирование зубов, каналов, заболевания зубов и десен, методики лечения."),
    preview: require("./Images/media_filling.png"),
    sections: [{
      id: "cariesMedia",
      title: _t("Кариес"),
      preview: require("./Images/caries.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/кариес.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/кариес.jpg`,
        title: _t("Кариес")
      }, {
        video: `${CDN_DOMAIN}/Videos/Basal Caries.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Basal Caries.jpg`,
        title: _t("Визуальный кариес")
      }, {
        video: `${CDN_DOMAIN}/Videos/Deep Caries.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Deep Caries.jpg`,
        title: _t("Глубокий кариес")
      }, {
        video: `${CDN_DOMAIN}/Videos/Fissures Caries.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Fissures Caries.jpg`,
        title: _t("Фиссурный кариес")
      }, {
        video: `${CDN_DOMAIN}/Videos/Cervical Caries.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Cervical Caries.jpg`,
        title: _t("Пришеечный кариес")
      }, {
        video: `${CDN_DOMAIN}/Videos/Approximal caries.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/AproximalCaries.png`,
        title: _t("Апроксимальный кариес")
      }],
      photo: [{
        photo: require("./Photos/кариес.112.jpg"),
        title: _t("Кариес")
      }, {
        photo: require("./Photos/кариес.113.jpg"),
        title: _t("Кариес - Пломба")
      }, {
        photo: require("./Photos/кариес.jpg"),
        title: _t("Кариес (46 зуб)")
      }, {
        photo: require("./Photos/Апроксимальный кариес2.jpg"),
        title: _t("Апроксимальный кариес")
      }, {
        photo: require("./Photos/Глубокий Кариес.421.jpg"),
        title: _t("Глубокий кариес")
      }, {
        photo: require("./Photos/прикорневой.423.jpg"),
        title: _t("Прикорневой кариес")
      }, {
        photo: require("./Photos/пришеечный кариес.427.jpg"),
        title: _t("Пришеечный кариес")
      }, {
        photo: require("./Photos/Фиссурный кариес.431.jpg"),
        title: _t("Фиссурный кариес")
      }, {
        photo: require("./Photos/Пультит.429.jpg"),
        title: _t("Глубокий кариес")
      }]
    }, {
      id: "fillingMedia",
      title: _t("Пломбирование зубов"),
      preview: require("./Images/filling.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/постоянная пломба.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/постоянная пломба.jpg`,
        title: _t("Композитная пломба")
      }, {
        video: `${CDN_DOMAIN}/Videos/временная пломба.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/временная пломба.jpg`,
        title: _t("Временная пломба")
      }, {
        video: `${CDN_DOMAIN}/Videos/Ceramic Tabs.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Ceramic Tabs.jpg`,
        title: _t("Керамические вкладки")
      }],
      photo: [{
        photo: require("./Photos/пломба.jpg"),
        title: _t("Пломба")
      }, {
        photo: require("./Photos/пломба1.jpg"),
        title: _t("Композитная пломба")
      }, {
        photo: require("./Photos/пломба2.jpg"),
        title: _t("Временная пломба")
      }, {
        photo: require("./Photos/пломба3.jpg"),
        title: _t("Временная пломба")
      }, {
        photo: require("./Photos/пломба4.jpg"),
        title: _t("Зубная пломба")
      }, {
        photo: require("./Photos/пломба5.jpg"),
        title: _t("Пломба")
      }],
      vr: [{
        folder: "tabs",
        xCount: 89,
        yCount: 0,
        title: _t("Керамические вкладки"),
        preview: require("./VRPacks/tabs/0_1.jpg")
      }]
    }, {
      id: "pulpitusMedia",
      title: _t("Пульпит"),
      preview: require("./Images/pulpitus.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/пульпит.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/пульпит.jpg`,
        title: _t("Пульпит")
      }, {
        video: `${CDN_DOMAIN}/Videos/Pulpitis.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Pulpitis.jpg`,
        title: _t("Пульпит")
      }],
      photo: [{
        photo: require("./Photos/Пульпит.jpg"),
        title: _t("Пульпит")
      }, {
        photo: require("./Photos/пульпит1.jpg"),
        title: _t("Пульпит")
      }, {
        photo: require("./Photos/Пульпит2.jpg"),
        title: _t("Пульпит")
      }]
    }, {
      id: "rootCanalFillingMedia",
      title: _t("Пломбировка каналов"),
      preview: require("./Images/rootCanalFilling.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Пломбировка канала.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Пломбировка канала.jpg`,
        title: _t("Пломбировка канала")
      }],
      photo: [{
        photo: require("./Photos/rootChannels3.jpg"),
        title: _t("Пломбировка канала")
      }, {
        photo: require("./Photos/rootChannels4.jpg"),
        title: _t("Пломбировка канала")
      }, {
        photo: require("./Photos/rootChannels.jpg"),
        title: _t("Каналы верхних зубов")
      }, {
        photo: require("./Photos/rootChannels2.jpg"),
        title: _t("Каналы нижних зубов")
      }]
    }, {
      id: "periodontusMedia",
      title: _t("Периодонтит"),
      preview: require("./Images/periodontit.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Периодотит.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Периодотит.jpg`,
        title: _t("Периодонтит")
      }],
      photo: [{
        photo: require("./Photos/переодонт.jpg"),
        title: _t("Периодонт")
      }, {
        photo: require("./Photos/переодонт1.jpg"),
        title: _t("Периодонт")
      }, {
        photo: require("./Photos/переодонт3.jpg"),
        title: _t("Периодонт")
      }]
    }, {
      id: "cystMedia",
      title: _t("Киста"),
      preview: require("./Images/kist.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Киста_Гранулема.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/киста.jpg`,
        title: _t("Киста, гранулема")
      }],
      photo: [{
        photo: require("./Photos/киста.jpg"),
        title: _t("Киста")
      }, {
        photo: require("./Photos/Киста_гранулема.jpg"),
        title: _t("Киста, гранулема")
      }, {
        photo: require("./Photos/Киста_гранулема_2.jpg"),
        title: _t("Киста, гранулема")
      }]
    }, {
      id: "periodonticDiseaseMedia",
      title: _t("Заболевания периодонта"),
      preview: require("./Images/paradontit.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Парадонтит.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Парадонтит.jpg`,
        title: _t("Пародонтит")
      }, {
        video: `${CDN_DOMAIN}/Videos/Periodontal Disease.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Periodontal Disease.jpg`,
        title: _t("Заболевания периодонта")
      }],
      photo: [{
        photo: require("./Photos/парадонтит.jpg"),
        title: _t("Пародонтит")
      }, {
        photo: require("./Photos/парадонтит1.jpg"),
        title: _t("Пародонтит")
      }, {
        photo: require("./Photos/парадонтит2.jpg"),
        title: _t("Пародонтит")
      }]
    }, {
      id: "TeethStoneMedia",
      title: _t("Зубной камень"),
      preview: require("./Images/toothStone.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Зубной камень.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Зубной камень.jpg`,
        title: _t("Зубной камень")
      }],
      photo: [{
        photo: require("./Photos/зубной камень.jpg"),
        title: _t("Зубной камень")
      }, {
        photo: require("./Photos/камень зубной.jpg"),
        title: _t("Камень зубной")
      }, {
        photo: require("./Photos/камень.jpg"),
        title: _t("Зубной камень")
      }]
    }, {
      id: "crownDestructionMedia",
      title: _t("Разрушенная коронка зуба. Восстановление коронки."),
      preview: require("./Images/crownDestruction.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/реставрация культи зуба.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/реставрация культи зуба.jpg`,
        title: _t("Реставрация культи зуба")
      }, {
        video: `${CDN_DOMAIN}/Videos/композитная культивая вкладка.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/композитная культивая вкладка.jpg`,
        title: _t("Композитная культевая вкладка")
      }, {
        video: `${CDN_DOMAIN}/Videos/композитный штифтовый зуб.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/композитный штифтовый зуб.jpg`,
        title: _t("Композитный штифтовый зуб")
      }],
      photo: [{
        photo: require("./Photos/Разрушенная коронка зуба 2.jpg"),
        title: _t("Разрушенная коронка зуба")
      }, {
        photo: require("./Photos/Разрушенная коронка зуба4.jpg"),
        title: _t("Разрушенная коронка зуба")
      }, {
        photo: require("./Photos/культи зуб.jpg"),
        title: _t("Восстановление коронки зуба")
      }, {
        photo: require("./Photos/культи зуб1.jpg"),
        title: _t("Восстановление коронки зуба")
      }, {
        photo: require("./Photos/культи зуб2.jpg"),
        title: _t("Восстановление коронки зуба")
      }]
    }, {
      id: "teethGrindingMedia",
      title: _t("Стираемость зубов"),
      preview: require("./Images/teethGrinding.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Stiraemost.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Stiraemost.jpg`,
        title: _t("Стираемость зубов")
      }],
      photo: [{
        photo: require("./Photos/teethGrinding.jpg"),
        title: _t("Степени стираемости зубов")
      }, {
        photo: require("./Photos/teethGrinding_I_level.jpg"),
        title: _t("I степень стираемости зубов")
      }, {
        photo: require("./Photos/teethGrinding_II_level.jpg"),
        title: _t("II степень стираемости зубов")
      }, {
        photo: require("./Photos/teethGrinding_III_level.jpg"),
        title: _t("III степень стираемости зубов")
      }, {
        photo: require("./Photos/teethGrinding2.jpg"),
        title: _t("Степени стираемости зубов")
      }]
    }]
  }, {
    id: "tmgMedia",
    title: _t("Лечение сустава, капы"),
    description: _t("Заболевания суставов, методики использования кап, сплит терапия."),
    preview: require("./Images/media_split.png"),
    sections: [{
      id: "TMJnormalMedia",
      title: _t("Нормальная работа ВНЧС"),
      preview: require("./Images/normalTMG.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Нормальная_Работа_Внчс-17.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Нормальная_Работа_Внчс-17.jpg`,
        title: _t("Нормальная работа ВНЧС")
      }],
      photo: [{
        photo: require("./Photos/normalTMG.jpg"),
        title: _t("ВНЧС")
      }, {
        photo: require("./Photos/normalTMG2.jpg"),
        title: _t("Височно-нижнечелюстной сустав")
      }, {
        photo: require("./Photos/ВНЧС_2.jpg"),
        title: _t("Нормальное положение ВНЧС")
      }]
    }, {
      id: "TMJdysfunctionMedia",
      title: _t("Дисфункция ВНЧС"),
      preview: require("./Images/TMJdysfunction.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Дисфункция_Внчс-12.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Дисфункция_Внчс-12.jpg`,
        title: _t("Дисфункция ВНЧС")
      }, {
        video: `${CDN_DOMAIN}/Videos/Joint_dysfunction.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Joint_dysfunctionPrev.png`,
        title: _t("Дисфункция сустава")
      }],
      photo: [{
        photo: require("./Photos/ВНЧС_2.jpg"),
        title: _t("Нормальное положение ВНЧС")
      }, {
        photo: require("./Photos/TMJdysfunction.jpg"),
        title: _t("Аномальное положение ВНЧС")
      }, {
        photo: require("./Photos/Joint dysfunction_1.jpg"),
        title: _t("Смещение нижней челюсти при движении")
      }, {
        photo: require("./Photos/Joint dysfunction_2.jpg"),
        title: _t("Смещение нижней челюсти при движении")
      }, {
        photo: require("./Photos/Joint dysfunction_3.jpg"),
        title: _t("Аномальное положение суставного диска")
      }]
    }, {
      id: "changeJointStructureMedia",
      title: _t("Изменение формы и структуры сустава"),
      preview: require("./Images/changeJointStructure.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/changeJointStructure.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/changeJointStructurePrev.png`,
        title: _t("Изменение формы и структуры сустава")
      }],
      photo: [{
        photo: require("./Photos/changeJointStructure.jpg"),
        title: _t("Изменение формы и структуры сустава")
      }, {
        photo: require("./Photos/changeJointStructure1.jpg"),
        title: _t("Изменение формы и структуры сустава")
      }, {
        photo: require("./Photos/changeJointStructure2.jpg"),
        title: _t("Изменение формы и структуры сустава")
      }]
    }, {
      id: "mouthGuardMedia",
      title: _t("Окклюзионная капа"),
      preview: require("./Images/mouthGuard.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Капа_Окклюзионная-15.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Капа окклюзионная.jpg`,
        title: _t("Капа окклюзионная")
      }, {
        video: `${CDN_DOMAIN}/Videos/Aligner_2.mp4`,
        videoPoster: require("./Photos/Капа окклюзионная 2.437.jpg"),
        title: _t("Капа, сплит")
      }],
      photo: [{
        photo: require("./Photos/Капа_окклюзионная.jpg"),
        title: _t("Капа окклюзионная")
      }, {
        photo: require("./Photos/Капа окклюзионная 2.437.jpg"),
        title: _t("Капа окклюзионная")
      }, {
        photo: require("./Photos/Капа окклюзионная ВНЧС.439.jpg"),
        title: _t("Капа окклюзионная")
      }, {
        photo: require("./Photos/Капа окклюзионная ВНЧС.440.jpg"),
        title: _t("Капа окклюзионная")
      }, {
        photo: require("./Photos/Капа окклюзионная ВНЧС.441.jpg"),
        title: _t("Капа окклюзионная")
      }],
      vr: [{
        folder: "mouth_guard",
        xCount: 90,
        yCount: 0,
        title: _t("Окклюзионная капа"),
        preview: require("./VRPacks/mouth_guard/0_15.jpg")
      }]
    }]
  }, {
    id: "paradonticMedia",
    title: _t("Парадонтология"),
    description: _t("Заболевание десен, лечение десен, лечение мягких тканей"),
    preview: require("./Images/bareRoot.png"),
    sections: [{
      id: "periodonticDiseaseMedia",
      title: _t("Заболевания периодонта"),
      preview: require("./Images/paradontit.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Paradontis1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Парадонтит.jpg`,
        title: _t("Парадонтит")
      }, {
        video: `${CDN_DOMAIN}/Videos/Periodontal Disease.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Periodontal Disease.jpg`,
        title: _t("Заболевания периодонта")
      }],
      photo: [{
        photo: require("./Photos/periodontitis.jpg"),
        title: _t("Парадонтит")
      }, {
        photo: require("./Photos/periodontitis1.jpg"),
        title: _t("Образование кармана")
      }, {
        photo: require("./Photos/парадонтит.jpg"),
        title: _t("Парадонтит")
      }, {
        photo: require("./Photos/парадонтит1.jpg"),
        title: _t("Парадонтит")
      }, {
        photo: require("./Photos/парадонтит2.jpg"),
        title: _t("Парадонтит")
      }]
    }, {
      id: "moovingTeethMedia",
      title: _t("Подвижный зуб"),
      preview: require("./Images/moovTooth.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Подвижный зуб.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Подвижный зуб.jpg`,
        title: _t("Подвижный зуб")
      }],
      photo: [{
        photo: require("./Photos/подвижный зуб1.jpg"),
        title: _t("Подвижный зуб")
      }, {
        photo: require("./Photos/подвижный зуб.jpg"),
        title: _t("Подвижный зуб")
      }]
    }, {
      id: "bareRootMedia",
      title: _t("Оголение корня"),
      preview: require("./Images/bareRoot.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/фукарция.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/фукарция.jpg`,
        title: _t("Фуркация")
      }],
      photo: [{
        photo: require("./Photos/фукарция1.jpg"),
        title: _t("Оголение корней")
      }, {
        photo: require("./Photos/фукарция2.jpg"),
        title: _t("Оголение корней зубов")
      }, {
        photo: require("./Photos/фукарция3.jpg"),
        title: _t("Фуркация корней")
      }]
    }, {
      id: "TeethStoneMedia",
      title: _t("Зубной камень, удаление камня"),
      preview: require("./Images/toothStone.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Зубной камень.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Зубной камень.jpg`,
        title: _t("Зубной камень")
      }],
      photo: [{
        photo: require("./Photos/зубной камень.jpg"),
        title: _t("Зубной камень")
      }, {
        photo: require("./Photos/камень зубной.jpg"),
        title: _t("Удаление зубного камня")
      }, {
        photo: require("./Photos/камень.jpg"),
        title: _t("Удаление зубного камня")
      }]
    }, {
      id: "SkelingMedia",
      title: _t("Скейлинг, полировка корня"),
      preview: require("./Images/skeling.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/skeling.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/skeling.jpg`,
        title: _t("Скейлинг, полировка корня")
      }],
      photo: [{
        photo: require("./Photos/skeling.jpg"),
        title: _t("Скейлинг, полировка корня")
      }, {
        photo: require("./Photos/skeling1.jpg"),
        title: _t("Удаление камня")
      }, {
        photo: require("./Photos/skeling2.jpg"),
        title: _t("Очистка корня")
      }]
    }]
  }, {
    id: "surgeryMedia",
    title: _t("Хирургия"),
    description: _t("Удаление зубов, костная пластика, гингивальная пластика"),
    preview: require("./Images/surgery.png"),
    sections: [{
      id: "extractionMedia",
      title: _t("Удаление зубов"),
      preview: require("./Images/surgery.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/tooth-extraction.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/tooth-extraction-preview.jpg`,
        title: _t("Удаление 46-ого зуба")
      }],
      photo: [{
        photo: require("./Photos/tooth-extraction2.jpg"),
        title: _t("Удаление зуба")
      }, {
        photo: require("./Photos/tooth-extraction1.jpg"),
        title: _t("Удаление 46-ого зуба")
      }, {
        photo: require("./Photos/tooth-extraction3.jpg"),
        title: _t("Закрытие лунки после удаления")
      }]
    }, {
      id: "augmentationMedia",
      title: _t("Костная пластика"),
      preview: require("./Images/addition.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Восстановление_Костной_Ткани.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Восстановление_Костной_Ткани.jpg`,
        title: _t("Восстановление костной ткани после удаления")
      }, {
        video: `${CDN_DOMAIN}/Videos/Horizontalaugmentation.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Horizontalaugmentation.jpg`,
        title: _t("Горизонтальная аугментация")
      }, {
        video: `${CDN_DOMAIN}/Videos/Verticalaugmentation.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Verticalaugmentation.jpg`,
        title: _t("Вертикальная аугментация")
      }, {
        video: `${CDN_DOMAIN}/Videos/Ugmentationimplantation.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Ugmentationimplantation.jpg`,
        title: _t("Аугментация при имплантации")
      }],
      photo: [{
        photo: require("./Photos/Восстановление_костной_ткани.jpg"),
        title: _t("Восстановление кости")
      }, {
        photo: require("./Photos/Восстановление_костной_ткани2.jpg"),
        title: _t("Восстановление кости")
      }]
    }, {
      id: "sinusLiftingMedia",
      title: _t("Синус-лифтинг"),
      preview: require("./Images/media_sinus.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/sinus.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/sinus.png`,
        title: _t("Истончение кости в области гайморовых")
      }, {
        video: `${CDN_DOMAIN}/Videos/Закрытый_синус_лифтинг.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Закрытый_синус_лифтинг.jpg`,
        title: _t("Закрытый синус-лифтинг")
      }, {
        video: `${CDN_DOMAIN}/Videos/Открытый_синус_лифтинг.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Открытый_синус_лифтинг.jpg`,
        title: _t("Открытый синус-лифтинг")
      }, {
        video: `${CDN_DOMAIN}/Videos/Синус_лифтинг_имплантация.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Синус_лифтинг_имплантация.jpg`,
        title: _t("Имплантация после синус-лифтинга")
      }],
      photo: [{
        photo: require("./Photos/синус 5.jpg"),
        title: _t("Синус-лифтинг")
      }, {
        photo: require("./Photos/синус 6.jpg"),
        title: _t("Синус-лифтинг")
      }, {
        photo: require("./Photos/синус 7.jpg"),
        title: _t("Синус-лифтинг")
      }]
    }, {
      id: "boneRemoveMedia",
      title: _t("Перенос костного блока"),
      preview: require("./Images/boneRemoveMediat.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Peremeshenie_Kostnogo_Bloka.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Peremeshenie_Kostnogo_Bloka.jpg`,
        title: _t("Перенос костного блока")
      }],
      photo: [{
        photo: require("./Photos/Peremeshenie kostnogo bloka.jpg"),
        title: _t("Перенос костного блока")
      }, {
        photo: require("./Photos/Peremeshenie kostnogo bloka1.jpg"),
        title: _t("Фиксация костного блока")
      }, {
        photo: require("./Photos/Peremeshenie kostnogo bloka4.jpg"),
        title: _t("Имплантация в области переноса")
      }]
    }, {
      id: "gindinaPlasticMedia",
      title: _t("Гингивальная пластика"),
      preview: require("./Images/gingivalPlastic.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/gigivaLaser.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/gingivectomy.png`,
        title: _t("Коррекция формы десен лазером")
      }, {
        video: `${CDN_DOMAIN}/Videos/Гингива Виниры.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Гингива Виниры.jpg`,
        title: _t("Виниринг после гингивальной пластики")
      }, {
        video: `${CDN_DOMAIN}/Videos/гингива длинные шейки.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/гингива длинные шейки.jpg`,
        title: _t("Удлиненные шейки")
      }, {
        video: `${CDN_DOMAIN}/Videos/Гингива черные треугольники.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Гингива черные треугольники.jpg`,
        title: _t("Пластика десны при черных треугольниках")
      }, {
        video: `${CDN_DOMAIN}/Videos/Гингива Импланты.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Гингива Импланты.jpg`,
        title: _t("Десневая пластика после имплантации")
      }],
      photo: [{
        photo: require("./Photos/Черные треугольники.jpg"),
        title: _t("Черные треугольники")
      }, {
        photo: require("./Photos/Гингива докнормк.5525.jpg"),
        title: _t("Удлиненные шейки")
      }, {
        photo: require("./Photos/Гингива докнорм.5525.jpg"),
        title: _t("Черные треугольники")
      }, {
        photo: require("./Photos/Гингива импланты.5515.jpg"),
        title: _t("Формирование десны в области имплантов")
      }, {
        photo: require("./Photos/Гингива импланты.5516.jpg"),
        title: _t("Создание контура прорезывания")
      }]
    }, {
      id: "rootResection",
      title: _t("Резекция корня"),
      preview: require("./Images/resection.png"),
      video: [{
        video: `${CDN_DOMAIN}/Videos/Resection.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/resection-preview.png`,
        title: _t("Резекция корня")
      }],
      photo: [{
        photo: require("./Photos/resection-photo_1.jpg"),
        title: _t("Резекция корня")
      }, {
        photo: require("./Photos/resection-photo_2.jpg"),
        title: _t("Резекция корня")
      }, {
        photo: require("./Photos/resection-photo_3.jpg"),
        title: _t("Резекция корня")
      }, {
        photo: require("./Photos/resection-photo_4.jpg"),
        title: _t("Резекция корня")
      }]
    }]
  }];
};

export default MediaLibraryContent;
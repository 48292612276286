import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShow: false,
  folder: "",
  xCount: 0,
  yCount: 0,
};

export const vrViewerSlice = createSlice({
  name: "vrViewer",
  initialState,
  reducers: {
    setVRViewer: (state, action) => {
      state.isShow = action.payload.isShow;
      state.folder = action.payload.folder;
      state.xCount = action.payload.xCount;
      state.yCount = action.payload.yCount;
    },
  },
});

export const { setVRViewer } = vrViewerSlice.actions;

export default vrViewerSlice.reducer;

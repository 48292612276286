import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Landing from "./components/Landing";
import { Box, ThemeProvider } from "@mui/material";
import theme from "./theme";
import { SignInForm, SignTemplate, SignUpForm } from "./components/Auth";
import {
  MainTemplate,
  HomeSection,
  ProfileSection,
  CardsSection,
  PackagesSection,
} from "./components/Main";
import { StepsAppLayout } from "./components/StepsApp";
import { PrivateLayout } from "./components/PrivateLayout";
import MetaTags from "./components/MetaTags";
import { SubmitProfile } from "./components/SubmitProfile";
import LazySpinner from "./components/LazySpinner";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags />
      <React.Suspense
        fallback={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <LazySpinner />
          </Box>
        }
      >
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/submit-profile/:token" element={<SubmitProfile />} />
          <Route element={<SignTemplate />}>
            <Route path="/sign-in" element={<SignInForm />} />
            <Route path="/sign-up" element={<SignUpForm />} />
          </Route>
          <Route element={<PrivateLayout />}>
            <Route path="/account" element={<MainTemplate />}>
              <Route index element={<HomeSection />} />
              <Route path="profile" element={<ProfileSection />} />
              <Route path="cards" element={<CardsSection />} />
              <Route path="packages" element={<PackagesSection />} />
            </Route>
            <Route path="/treatment-plan" element={<StepsAppLayout />}>
              <Route index element={<HomeSection />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </React.Suspense>
    </ThemeProvider>
  );
}

export default App;

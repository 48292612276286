import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShow: false,
  name: "",
  folder: "",
  totalSlides: 0,
};

export const vrSliderSlice = createSlice({
  name: "vrSlider",
  initialState,
  reducers: {
    setVRSlider: (state, action) => {
      state.isShow = action.payload.isShow;
      state.name = action.payload.name;
      state.folder = action.payload.folder;
      state.totalSlides = action.payload.totalSlides;
    },
  },
});

export const { setVRSlider } = vrSliderSlice.actions;

export default vrSliderSlice.reducer;

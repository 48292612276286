import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { commonApi } from "./commonApi";
import auth from "./reducers/auth";
import infoBars from "./reducers/infoBars";
import photoViewer from "./reducers/photoViewer";
import videoViewer from "./reducers/videoViewer";
import mediaLibrary from "./reducers/mediaLibrary";
import vrSlider from "./reducers/vrSlider";
import vrViewer from "./reducers/vrViewer";
import screenSaver from "./reducers/screenSaver";
import stepControl from "./reducers/stepControl";
import snackbar from "./reducers/snackbar";

const rootReducer = combineReducers({
  [commonApi.reducerPath]: commonApi.reducer,
  auth,
  infoBars,
  mediaLibrary,
  vrSlider,
  vrViewer,
  screenSaver,
  photoViewer,
  videoViewer,
  stepControl,
  snackbar,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(commonApi.middleware),
});

export default store;

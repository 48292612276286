import { translate } from "../i18n";
export const slidesVRContent = language => {
  const _t = key => translate(key, language);

  return [{
    key: "Small_multiple_violations",
    totalSlides: 14,
    preview: require("./VRSlides/Small_multiple_violations/Small_multiple_violations_prew.png"),
    name: _t("Небольшие множественные нарушения позиции зубов")
  }, {
    key: "Extruded_tooth",
    totalSlides: 13,
    preview: require("./VRSlides/Extruded_tooth/Extruded_tooth_prew.png"),
    name: _t("Экструзированный зуб")
  }, {
    key: "Intruded_tooth",
    totalSlides: 13,
    preview: require("./VRSlides/Intruded_tooth/Intruded_tooth_prew.png"),
    name: _t("Интрузированый зуб")
  }, {
    key: "Incorrect_inclination_of_the_tooth",
    totalSlides: 13,
    preview: require("./VRSlides/Incorrect_inclination_of_the_tooth/Incorrect_inclination_of_the_tooth_prew.png"),
    name: _t("Нарушенный наклон зуба")
  }, {
    key: "Violation_tooth_angulation",
    totalSlides: 13,
    preview: require("./VRSlides/Violation_tooth_angulation/Violation_tooth_angulation_prew.png"),
    name: _t("Нарушенная ангуляция зуба")
  }, {
    key: "Rotated_tooth",
    totalSlides: 14,
    preview: require("./VRSlides/Rotated_tooth/Rotated_tooth_prew.png"),
    name: _t("Ротированный зуб")
  }, {
    key: "Spacing",
    totalSlides: 14,
    preview: require("./VRSlides/Spacing/Spacing_prew.png"),
    name: _t("Тремы, диастемы")
  }, {
    key: "Crowding_of_teeth_on_one_jaw",
    totalSlides: 14,
    preview: require("./VRSlides/Crowding_of_teeth_on_one_jaw/Crowding_of_teeth_on_one_jaw_prew.png"),
    name: _t("Скученность зубов на одной челюсти")
  }, {
    key: "Protrusion_of_the_anterior_teeth",
    totalSlides: 14,
    preview: require("./VRSlides/Protrusion_of_the_anterior_teeth/Protrusion_of_the_anterior_teeth_prew.png"),
    name: _t("Протрузия")
  }, {
    key: "Retrusion_of_the_anterior_teeth",
    totalSlides: 15,
    preview: require("./VRSlides/Retrusion_of_the_anterior_teeth/Retrusion_of_the_anterior_teeth_prew.png"),
    name: _t("Ретрузия")
  }, {
    key: "One_side_Angles_Class_II",
    totalSlides: 15,
    preview: require("./VRSlides/One_side_Angles_Class_II/One_side_Angle's_Class_II_prew.png"),
    name: _t("II класс по Энглю с одной стороны")
  }, {
    key: "One_side_Angles_Class_III",
    totalSlides: 15,
    preview: require("./VRSlides/One_side_Angles_Class_III/One_side_Angle's_Class_III_prew.png"),
    name: _t("III класс по Энглю с одной стороны")
  }, {
    key: "Cross_bite",
    totalSlides: 13,
    preview: require("./VRSlides/Cross_bite/Cross_bite_prew.png"),
    name: _t("Перекрестное положение зубов")
  }, {
    key: "Angles_Class_II_Division_I",
    totalSlides: 21,
    preview: require("./VRSlides/Angles_Class_II_Division_I/Angle's_Class_II_Division_I_prew.png"),
    name: _t("II класс по Энглю. Подкласс II")
  }, {
    key: "Angles_Class_II_Division_II",
    totalSlides: 20,
    preview: require("./VRSlides/Angles_Class_II_Division_II/Angle's_Class_II_Division_II_prew.png"),
    name: _t("II класс по Энглю. Подкласс I")
  }, {
    key: "Expansion_of_dental_arches",
    totalSlides: 15,
    preview: require("./VRSlides/Expansion_of_dental_arches/Expansion_of_dental_arches_prew.png"),
    name: _t("Сужение зубных дуг в области премоляров")
  }, {
    key: "Crowding_of_teeth_on_both_jaws",
    totalSlides: 15,
    preview: require("./VRSlides/Crowding_of_teeth_on_both_jaws/Crowding_of_teeth_on_both_jaws_prew.png"),
    name: _t("Скученность зубов на обе челюсти")
  }, {
    key: "Overjet_sagittal_fissure",
    totalSlides: 15,
    preview: require("./VRSlides/Overjet_sagittal_fissure/Overjet_sagittal_fissure_prew.png"),
    name: _t("Сагиттальная щель")
  }, {
    key: "Overbite_deep_bite",
    totalSlides: 13,
    preview: require("./VRSlides/Overbite_deep_bite/Overbite_deep_bite_prew.png"),
    name: _t("Глубокий прикус")
  }, {
    key: "Openbite",
    totalSlides: 16,
    preview: require("./VRSlides/Openbite/Openbite_prew.png"),
    name: _t("Открытый прикус")
  }];
};
export default slidesVRContent;
import { commonApi } from "./commonApi";

export const cardsApi = commonApi.injectEndpoints({
  tagTypes: ["Card"],
  endpoints: (builder) => ({
    addCard: builder.mutation({
      query: (card) => ({
        url: "/user/cards",
        method: "POST",
        body: card,
      }),
      invalidatesTags: ["Card", "PackageDetails"],
    }),
    getCards: builder.query({
      query: (page = 1, pageSize = 5) => ({
        url: `/user/cards?page=${page}`,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [...result.data.map(({ id }) => ({ type: "Card", id })), "Card"]
          : ["Card"],
    }),
  }),
});

export const { useAddCardMutation, useGetCardsQuery } = cardsApi;

import { lazy } from "react";

import HomeSection from "./HomeSection";
import ProfileSection from "./ProfileSection";
import CardsSection from "./CardsSection";
import PackagesSection from "./PackagesSection";

const MainTemplate = lazy(() => import("./MainTemplate"));

export {
  MainTemplate,
  HomeSection,
  ProfileSection,
  CardsSection,
  PackagesSection,
};

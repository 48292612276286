import { AnimationClip, AnimationMixer, AnimationObjectGroup, BufferGeometry, Cache, Clock, CubeTextureLoader, DirectionalLight, Float32BufferAttribute, Group, LoopOnce, MathUtils, Mesh, MeshStandardMaterial, Object3D, OrthographicCamera, Quaternion, QuaternionKeyframeTrack, Scene, Vector3, VectorKeyframeTrack, sRGBEncoding, WebGLRenderer, DoubleSide, PCFSoftShadowMap, BasicShadowMap, Raycaster, Vector2 } from "three";
import materials from "./materials";
import { mergeVertices } from "three/examples/jsm/utils/BufferGeometryUtils";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls"; // import TrackballControls from "./TrackballControls";

import { isEqual } from "lodash";
import { detect } from "detect-browser";
const browser = detect();
const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("/dracoDecoder/");
dracoLoader.setDecoderConfig({
  type: "js"
});
dracoLoader.preload();
gltfLoader.setDRACOLoader(dracoLoader);

class SceneManager {
  constructor(renderContainer, renderWrapper, isAnimationEnabled = false, cameraZoom = 15) {
    this.renderContainer = renderContainer;
    this.renderWrapper = renderWrapper;
    this.renderWidth = 0;
    this.renderHeight = 0;
    this.renderer = null;
    this.camera = null;
    this.cameraZoom = cameraZoom;
    this.lightType = "basic";
    this.lightNames = ["directionalLightLeft", "directionalLightRight", "directionalLightFront"];
    this.renderRequested = false;
    this.isAnimationEnabled = isAnimationEnabled;
    this.controls = null;
    this.reflectionCube = null;
    this.raycaster = null;
    this.pointer = null;
    this.scenes = [];
    this.sceneNames = ["main"];
    this.scenesCount = 1;
    this.groups = ["center", "top", "bottom"];
    this.stlFiles = {};
    this.stlGroups = {};
    this.headMaskType = null;
    this.animationFrame = null;
    this.onProgress = null;

    this.onLoadEnd = () => {};

    this.worker = null;
    this.workerPromises = {};
    this.currentTaskPromise = null;
    this.mixers = {};
    this.clock = null;
    this.animationSpeed = 0.5;
    this.centerPointInited = true;
    this.centerPoint = {
      x: -29.057803903307235,
      y: -6.75175467775839,
      z: -1.0217800081544925
    };
    this.INTERSECTED = null;
    this.onMeshSelect = null;
    this.meshSelect = false;
  }

  createWorker = () => {
    switch (browser && browser.name) {
      case "chrome":
      case "crios":
      case "chromium-webview":
      case "firefox":
      case "opera":
      case "safari":
      case "yandexbrowser":
      case "ios-webview":
      case "ios":
      case "android":
        this.worker = new Worker("./stlLoad.worker.js");
        break;

      default:
        this.worker = null;
        break;
    }
  };
  destroyWorker = () => {
    Object.values(this.workerPromises).forEach(promise => {
      promise.reject();
    });
    this.workerPromises = {};

    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
    } else {
      console.error("Worker is not defined");
    }
  };
  reloadWorker = () => {
    if (this.worker) {
      this.destroyWorker();
    }

    this.createWorker();
  };
  checkWebGL = () => {
    try {
      const canvas = document.createElement("canvas");
      return !!(window && window.WebGLRenderingContext && (canvas.getContext("webgl") || canvas.getContext("experimental-webgl")));
    } catch (e) {
      return false;
    }
  };
  addScenes = scenes => {
    if (scenes && scenes.length) {
      this.sceneNames = scenes;
      this.scenesCount = scenes.length;
    }
  };
  addOnProgress = func => {
    this.onProgress = func;
  };
  addOnLoadEnd = func => {
    this.onLoadEnd = func;
  };
  addOnMeshSelect = func => {
    this.meshSelect = true;
    this.onMeshSelect = func;
  };
  removeOnMeshSelect = () => {
    this.centerModels(true);
    this.meshSelect = false;
    this.onMeshSelect = null;
  };
  progressLoader = (loaded, total, index) => {
    if (this.onProgress) {
      // Set model progress
      let progress = Math.round(loaded / total * 100);
      this.stlFiles[index] = { ...this.stlFiles[index],
        progress
      }; // Big math function, we can refactor it

      const totalProgress = this.stlFiles.reduce((sum, el) => {
        return sum + el.progress;
      }, 0) / this.stlFiles.length;

      if (this.onProgress) {
        this.onProgress(totalProgress);
      }
    }
  }; // Инициализация сцены

  init = () => {
    if (this.checkWebGL) {
      Cache.enabled = true;
      this.clock = new Clock();
      this.createWorker();
      this.buildRender();
      this.buildPointerControls();
      this.buildCamera();
      this.buildControls();
      this.addMap();
      this.buildScenes();
      this.buildLights("basic");
      this.animate();
      this.created();
      return true;
    }

    console.error("Error with WebGL");
    return "Error with WebGL";
  };
  getRelativeCoordinates = referenceElement => {
    const offset = {
      left: referenceElement.offsetLeft,
      top: referenceElement.offsetTop
    };
    let reference = referenceElement.offsetParent;

    while (reference) {
      offset.left += reference.offsetLeft;
      offset.top += reference.offsetTop;
      reference = reference.offsetParent;
    }

    return offset;
  };
  setPointerCoordinates = (x, y) => {
    this.pointer.x = x / this.renderWidth * 2 - 1;
    this.pointer.y = -(y / this.renderHeight) * 2 + 1;
  };
  onPointerMove = e => {
    const relativeCoords = this.getRelativeCoordinates(e.target);
    let x = e.clientX - relativeCoords.left;
    let y = e.clientY - relativeCoords.top;
    this.setPointerCoordinates(x, y);
  };
  onPointerClick = e => {
    if (!this.meshSelect || typeof this.onMeshSelect !== "function") {
      return;
    }

    const relativeCoords = this.getRelativeCoordinates(e.target);
    let x = e.clientX - relativeCoords.left;
    let y = e.clientY - relativeCoords.top;
    this.setPointerCoordinates(x, y);
    this.generateRaycast(true);

    if (this.INTERSECTED) {
      this.onMeshSelect(this.INTERSECTED.name);
    }
  }; // Создание рендера

  created = () => {
    window.addEventListener("resize", this.resize, false);
    this.renderContainer.addEventListener("pointermove", this.onPointerMove, false);
    this.renderContainer.addEventListener("click", this.onPointerClick, false);
  }; // Удаление рендера

  destroy = () => {
    // Terminate worker
    this.destroyWorker();
    cancelAnimationFrame(this.animationFrame);
    this.scenes = [];
    this.camera = null;
    this.controls = null;
    Cache.clear();
    this.renderer.domElement.addEventListener("dblclick", null, false); //remove listener to render

    this.renderer.clear();
    this.renderer.forceContextLoss();
    this.renderer.dispose();
    this.renderer = null;
    this.renderContainer.removeEventListener("pointermove", this.onPointerMove, false);
    this.renderContainer.removeEventListener("click", this.onPointerClick, false);
    window.removeEventListener("resize", this.resize, false);
  }; // Обновление размера рендера

  updateRenderSize = () => {
    this.renderWidth = this.renderWrapper ? this.renderWrapper.clientWidth : this.renderContainer.clientWidth;
    this.renderHeight = this.renderWrapper ? this.renderWrapper.clientHeight : this.renderContainer.clientHeight;
  }; // Постройка рендер контейнера

  buildRender = () => {
    this.renderer = new WebGLRenderer({
      alpha: true,
      antialias: true,
      canvas: this.renderContainer,
      preserveDrawingBuffer: true
    });
    this.updateRenderSize();
    this.renderer.setSize(this.renderWidth, this.renderHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setClearColor(0x423c63, 0);
    this.renderer.setScissorTest(true);
    this.renderer.outputEncoding = sRGBEncoding;
  };
  buildPointerControls = () => {
    this.raycaster = new Raycaster();
    this.pointer = new Vector2();
  }; // Постройка камеры сцены

  buildCamera = () => {
    const width = this.renderWidth / this.scenesCount;
    const height = this.renderHeight;
    this.camera = new OrthographicCamera(width / -2, width / 2, height / 2, height / -2, -120, 1000); // this.camera = new PerspectiveCamera( 60, width / height, 1, 1000 );

    this.camera.zoom = this.cameraZoom;
    this.camera.position.set(90, 0, 0);
    this.camera.updateProjectionMatrix();
  }; // Установка zoom камеры

  setCameraZoom = zoom => {
    this.cameraZoom = zoom;
    this.camera.zoom = this.cameraZoom;
  };
  resetControls = () => {
    this.controls.reset();
    this.setCameraZoom(this.cameraZoom);
    this.resize();
  }; // Постройка управления сценой

  buildControls = () => {
    this.controls = new TrackballControls(this.camera, this.renderContainer);
    this.controls.rotateSpeed = 4.0;
    this.controls.zoomSpeed = 4.0;
    this.controls.panSpeed = 10.0;
    this.controls.noZoom = false;
    this.controls.noPan = false;
    this.controls.staticMoving = true;
    this.controls.dynamicDampingFactor = 0.3;
    this.controls.addEventListener("change", () => {
      this.renderRequested = true;
    });
    this.controls.addEventListener("start", () => {
      this.renderRequested = true;
    });
  }; // Добавление окружения

  addMap = () => {
    const urls = [require("./textures/4.jpg"), require("./textures/4.jpg"), require("./textures/5.jpg"), require("./textures/3.jpg"), require("./textures/4.jpg"), require("./textures/4.jpg")];
    this.reflectionCube = new CubeTextureLoader().load(urls);
  };
  buildScenes = () => {
    this.sceneNames.forEach(sceneName => {
      const existingScene = this.scenes.find(el => el.name === sceneName);

      if (existingScene) {
        return;
      }

      let scene = new Scene();
      scene.name = sceneName;
      this.groups.forEach(animationName => {
        const group = new Group();
        group.name = `${animationName}Group`; // group.updateMatrixWorld(true);

        scene.add(group);
      }); // const gridHelper = new GridHelper(400, 40, 0x0000ff, 0x808080);
      // gridHelper.position.y = 0;
      // gridHelper.position.x = 0;
      // scene.add(gridHelper);
      // const axesHelper = new AxesHelper(10);
      // scene.add(axesHelper);
      // scene.add(new BoxHelper(centerGroup));

      let maskGroup = new Group();
      maskGroup.name = "maskGroup"; // maskGroup.updateMatrixWorld(true);

      scene.add(maskGroup);
      this.scenes.unshift(scene);
    });
    this.scenes = this.scenes.filter(el => this.sceneNames.includes(el.name));
  };
  buildLights = type => {
    let lights = [];

    if (type === "shadowedLight3Point") {
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = PCFSoftShadowMap;
      const width = this.renderWidth / this.scenesCount;
      const height = this.renderHeight;
      const lightLeft = new DirectionalLight(0xffffff, 0.1);
      lightLeft.position.set(150, 10, 70);
      lightLeft.name = "directionalLightLeft";
      lightLeft.castShadow = true;
      lightLeft.shadow.mapSize.width = 2048; // default

      lightLeft.shadow.mapSize.height = 2048; // default

      lightLeft.shadow.camera.near = 1; // default

      lightLeft.shadow.camera.far = 1000; // default

      lightLeft.shadow.camera.left = width / -2;
      lightLeft.shadow.camera.right = width / 2;
      lightLeft.shadow.camera.top = height / 2;
      lightLeft.shadow.camera.bottom = height / -2;
      lightLeft.shadow.bias = -0.001;
      lightLeft.shadow.radius = 10;
      const lightRight = new DirectionalLight(0xffffff, 0.1);
      lightRight.position.set(150, 10, -70);
      lightRight.name = "directionalLightRight";
      lightRight.castShadow = true;
      lightRight.shadow.mapSize.width = 2048; // default

      lightRight.shadow.mapSize.height = 2048; // default

      lightRight.shadow.camera.near = 1; // default

      lightRight.shadow.camera.far = 1000; // default

      lightRight.shadow.camera.left = width / -2;
      lightRight.shadow.camera.right = width / 2;
      lightRight.shadow.camera.top = height / 2;
      lightRight.shadow.camera.bottom = height / -2;
      lightRight.shadow.bias = -0.001;
      lightRight.shadow.radius = 10;
      const lightFront = new DirectionalLight(0xffffff, 0.8);
      lightFront.position.set(150, 10, 0);
      lightFront.name = "directionalLightFront";
      lightFront.castShadow = true;
      lightFront.shadow.mapSize.width = 2048; // default

      lightFront.shadow.mapSize.height = 2048; // default

      lightFront.shadow.camera.near = 1; // default

      lightFront.shadow.camera.far = 1000; // default

      lightFront.shadow.camera.left = width / -2;
      lightFront.shadow.camera.right = width / 2;
      lightFront.shadow.camera.top = height / 2;
      lightFront.shadow.camera.bottom = height / -2;
      lightFront.shadow.bias = -0.005;
      lightFront.shadow.radius = 20;
      lights = [lightLeft, lightRight, lightFront];
    } else if (type === "basic") {
      this.renderer.shadowMap.enabled = false;
      this.renderer.shadowMap.type = BasicShadowMap;
      const lightFront = new DirectionalLight(0xffffff, 1);
      lightFront.position.set(150, 0, 0);
      lightFront.name = "directionalLightFront";
      lights.push(lightFront);
    }

    this.lightType = type;
    this.scenes.forEach(scene => {
      this.lightNames.forEach(lightName => {
        scene.remove(scene.getObjectByName(lightName));
      });
      lights.forEach(light => {
        const lightTmp = new DirectionalLight();
        lightTmp.copy(light);
        scene.add(lightTmp);
      });
    });
  }; // Ререндеринг сцены

  render = () => {
    let width = this.renderWidth / this.scenesCount;
    let height = this.renderHeight;
    let offset = width;
    let bottom = 0;
    this.scenes.forEach((scene, index) => {
      this.renderer.setViewport(offset * index, bottom, width, height);
      this.renderer.setScissor(offset * index, bottom, width, height);

      if (this.lightType === "basic") {
        const lightFront = scene.getObjectByName("directionalLightFront");
        lightFront.position.copy(this.camera.position);
      } else if (this.lightType === "shadowedLight3Point") {
        const width = this.renderWidth / this.scenesCount;
        const height = this.renderHeight;
        const lightLeft = scene.getObjectByName("directionalLightLeft");
        const lightRight = scene.getObjectByName("directionalLightRight");
        const lightFront = scene.getObjectByName("directionalLightFront");
        lightLeft.shadow.camera.left = width / -2;
        lightLeft.shadow.camera.right = width / 2;
        lightLeft.shadow.camera.top = height / 2;
        lightLeft.shadow.camera.bottom = height / -2;
        lightRight.shadow.camera.left = width / -2;
        lightRight.shadow.camera.right = width / 2;
        lightRight.shadow.camera.top = height / 2;
        lightRight.shadow.camera.bottom = height / -2;
        lightFront.shadow.camera.left = width / -2;
        lightFront.shadow.camera.right = width / 2;
        lightFront.shadow.camera.top = height / 2;
        lightFront.shadow.camera.bottom = height / -2;
        const lightFrontPosition = new Vector3(60, 10, 0).add(this.camera.position);
        lightFront.position.copy(lightFrontPosition);
      }

      this.renderer.render(scene, this.camera);
    });
    this.renderRequested = false;
  };
  generateRaycast = (clickEvent = false) => {
    this.raycaster.setFromCamera(this.pointer, this.camera);
    this.scenes.forEach(scene => {
      let intersects = [];
      this.groups.forEach(groupName => {
        const group = scene.getObjectByName(`${groupName}Group`);
        let groupIntersects = this.raycaster.intersectObject(group);
        intersects = [...intersects, ...groupIntersects];
      });
      let targetObject = null;

      if (intersects.length > 0) {
        let targetIntersects = intersects.filter(target => target.object?.userData?.selectable);

        if (targetIntersects.length > 0) {
          targetObject = targetIntersects[0].object;
        }
      }

      if (targetObject) {
        if (this.INTERSECTED !== targetObject) {
          if (this.INTERSECTED) {
            this.INTERSECTED.material.emissive.setHex(this.INTERSECTED.currentHex);
          }

          this.INTERSECTED = targetObject;

          if (!clickEvent) {
            this.INTERSECTED.currentHex = this.INTERSECTED.material.emissive.getHex();
            this.INTERSECTED.material.emissive.setHex(0xff0000);
          }
        }
      } else {
        if (this.INTERSECTED) {
          this.INTERSECTED.material.emissive.setHex(this.INTERSECTED.currentHex);
        }

        this.INTERSECTED = null;
      }

      this.renderRequested = true;
    });
  }; // Анимация сцены

  animate = () => {
    this.animationFrame = requestAnimationFrame(this.animate);
    this.controls.update();

    if (Object.keys(this.mixers).length) {
      const mixerUpdateDelta = this.clock.getDelta();
      Object.values(this.mixers).forEach(mixer => {
        this.renderRequested = true;
        mixer.update(mixerUpdateDelta);
      });
    }

    if (this.meshSelect) {
      this.generateRaycast();
    }

    if (this.isAnimationEnabled || this.renderRequested) {
      this.render();
    }
  }; // Авто изменение размера сцены

  resize = () => {
    if (this.camera && this.renderer) {
      this.updateRenderSize();
      this.renderer.setSize(this.renderWidth, this.renderHeight);
      const width = this.renderWidth / this.scenesCount;
      const height = this.renderHeight;
      this.camera.left = width / -2;
      this.camera.right = width / 2;
      this.camera.top = height / 2;
      this.camera.bottom = height / -2;
      this.camera.updateProjectionMatrix();
      this.renderRequested = true;
    }
  }; // Центрование всех моделей

  calcCenter = group => {
    let centerPoint = {
      x: 0,
      y: 0,
      z: 0,
      xArray: [],
      yArray: [],
      zArray: []
    };

    for (let i = 0; i < group.children.length; i++) {
      if (group.children[i] instanceof Mesh) {
        if (!group.children[i].geometry.boundingSphere) {
          group.children[i].geometry.computeBoundingSphere();
        }

        centerPoint.xArray.push(group.children[i].geometry.boundingSphere.center.x);
        centerPoint.yArray.push(group.children[i].geometry.boundingSphere.center.y);
        centerPoint.zArray.push(group.children[i].geometry.boundingSphere.center.z);
      }
    }

    if (centerPoint.xArray.length) {
      centerPoint.x = [].reduce.call(centerPoint.xArray, (p, c) => c + p) / centerPoint.xArray.length;
    }

    if (centerPoint.yArray.length) {
      centerPoint.y = [].reduce.call(centerPoint.yArray, (p, c) => c + p) / centerPoint.yArray.length;
    }

    if (centerPoint.zArray.length) {
      centerPoint.z = [].reduce.call(centerPoint.zArray, (p, c) => c + p) / centerPoint.zArray.length;
    }

    this.centerPoint = {
      x: centerPoint.x,
      y: centerPoint.y,
      z: centerPoint.z
    };
    this.centerPointInited = true;
    const vectorCenter = new Vector3(centerPoint.x, centerPoint.y, centerPoint.z);
    group.children.forEach(el => {
      el.position.copy(vectorCenter).negate();
    });
  };
  centerModels = (globalCenter = false) => {
    this.scenes.forEach(scene => {
      this.groups.forEach(groupName => {
        const group = scene.getObjectByName(`${groupName}Group`);

        if (group) {
          const vectorCenter = new Vector3();

          if (globalCenter) {
            vectorCenter.set(this.centerPoint.x, this.centerPoint.y, this.centerPoint.z);
          } else {
            let centerPoint = {
              x: 0,
              y: 0,
              z: 0,
              xArray: [],
              yArray: [],
              zArray: []
            };

            for (let i = 0; i < group.children.length; i++) {
              if (group.children[i] instanceof Mesh) {
                if (!group.children[i].geometry.boundingSphere) {
                  group.children[i].geometry.computeBoundingSphere();
                }

                centerPoint.xArray.push(group.children[i].geometry.boundingSphere.center.x);
                centerPoint.yArray.push(group.children[i].geometry.boundingSphere.center.y);
                centerPoint.zArray.push(group.children[i].geometry.boundingSphere.center.z);
              }
            }

            if (centerPoint.xArray.length) {
              centerPoint.x = [].reduce.call(centerPoint.xArray, (p, c) => c + p) / centerPoint.xArray.length;
            }

            if (centerPoint.yArray.length) {
              centerPoint.y = [].reduce.call(centerPoint.yArray, (p, c) => c + p) / centerPoint.yArray.length;
            }

            if (centerPoint.zArray.length) {
              centerPoint.z = [].reduce.call(centerPoint.zArray, (p, c) => c + p) / centerPoint.zArray.length;
            }

            vectorCenter.set(centerPoint.x, centerPoint.y, centerPoint.z);
          }

          if (group.children.length) {
            group.children.forEach(el => {
              el.position.copy(vectorCenter).negate();
            });
          }
        }
      });
    });
  };
  resetAllScenes = () => {
    this.stlFiles = {};
    this.stlGroups = {};
    this.scenes.forEach(scene => {
      this.groups.forEach(groupName => {
        const group = scene.getObjectByName(`${groupName}Group`);

        if (group) {
          group.clear();
        }
      });
    });
    this.renderRequested = true; // this.render();
  };
  equalsIgnoreOrder = (a, b) => {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);

    for (const v of uniqueValues) {
      const aCount = a.filter(e => e === v).length;
      const bCount = b.filter(e => e === v).length;
      if (aCount !== bCount) return false;
    }

    return true;
  };
  prepareScenes = files => {
    let extraScenes = ["main"];
    files.forEach(el => {
      if (!extraScenes.includes(el.scene)) {
        extraScenes.unshift(el.scene);
      }
    });

    if (!this.equalsIgnoreOrder(extraScenes, this.sceneNames)) {
      this.addScenes(extraScenes);
      this.buildScenes();
      this.buildLights("basic");
      this.resize();
      this.renderRequested = true; // this.render();
    }
  };
  loadRemoteFiles = files => {
    // Recreate worker if it's busy
    if (this.worker) {
      this.reloadWorker();
    }

    if (files.length) {
      this.prepareScenes(files);
      this.checkStlFiles(files).then(e => {
        if (!this.centerPointInited) {
          const scene = this.getSceneByName("main");
          this.calcCenter(scene.getObjectByName("centerGroup"));
        }

        if (this.isAnimationEnabled) {
          this.appendMainAnimation();
        }

        this.onLoadEnd();
      }).catch(error => {
        this.onLoadEnd();
      });
    } else {
      this.resetAllScenes();
      this.onLoadEnd();
    }
  };
  resetGroupsPositions = () => {
    this.scenes.forEach(scene => {
      this.groups.forEach(groupName => {
        const group = scene.getObjectByName(`${groupName}Group`);
        group.position.set(0, 0, 0);
        group.rotation.set(0, 0, 0);
      });
    });
  };
  appendMainAnimation = () => {
    if (this.mixers["mainAnimationMixer"]) {
      return;
    }

    let sceneName = "main";
    const scene = this.getSceneByName(sceneName);
    const centerGroup = scene.getObjectByName("centerGroup");
    const animationGroup = new AnimationObjectGroup();
    animationGroup.add(centerGroup);
    let yAxis = new Vector3(0, 1, 0);
    const qInitial = new Quaternion().setFromAxisAngle(yAxis, 0);
    const lFinal = new Quaternion().setFromAxisAngle(yAxis, Math.PI / 2);
    const rFinal = new Quaternion().setFromAxisAngle(yAxis, -Math.PI / 2);
    const quaternionKF = new QuaternionKeyframeTrack(".quaternion", [0, 1, 2, 3, 4], [qInitial.x, qInitial.y, qInitial.z, qInitial.w, lFinal.x, lFinal.y, lFinal.z, lFinal.w, qInitial.x, qInitial.y, qInitial.z, qInitial.w, rFinal.x, rFinal.y, rFinal.z, rFinal.w, qInitial.x, qInitial.y, qInitial.z, qInitial.w]);
    const clip = new AnimationClip(`mainAnimation`, 5, [quaternionKF]);
    clip.optimize();
    clip.resetDuration();
    const mixer = new AnimationMixer(animationGroup);

    if (clip) {
      const action = mixer.clipAction(clip);
      action.setDuration(15);
      action.play();
    }

    this.mixers["mainAnimationMixer"] = mixer;
  };
  createJawAnimationClip = (groupName, move) => {
    let yAxis = new Vector3(0, 0, 1);
    const qInitial = new Quaternion().setFromAxisAngle(yAxis, 0);
    const lFinal = new Quaternion().setFromAxisAngle(yAxis, MathUtils.degToRad(groupName === "top" ? 20 : -20));
    let positionKF = null;
    let quaternionKF = null;

    if (move === "close") {
      if (groupName === "bottom") {
        positionKF = new VectorKeyframeTrack(".position", [0, 1], [0, -15, 0, 0, 0, 0]);
      } else {
        positionKF = new VectorKeyframeTrack(".position", [0, 1], [0, 15, 0, 0, 0, 0]);
      }

      quaternionKF = new QuaternionKeyframeTrack(".quaternion", [0, 1], [lFinal.x, lFinal.y, lFinal.z, lFinal.w, qInitial.x, qInitial.y, qInitial.z, qInitial.w]);
    } else {
      if (groupName === "bottom") {
        positionKF = new VectorKeyframeTrack(".position", [0, 1], [0, 0, 0, 0, -15, 0]);
      } else {
        positionKF = new VectorKeyframeTrack(".position", [0, 1], [0, 0, 0, 0, 15, 0]);
      }

      quaternionKF = new QuaternionKeyframeTrack(".quaternion", [0, 1], [qInitial.x, qInitial.y, qInitial.z, qInitial.w, lFinal.x, lFinal.y, lFinal.z, lFinal.w]);
    }

    return new AnimationClip(`animation_${groupName}_${move}`, 2, [quaternionKF, positionKF]);
  };
  appendJawAnimation = (groupName = "top", move = "open") => {
    this.scenes.forEach(scene => {
      let sceneName = scene.name;

      if (!this.stlGroups[sceneName] || !this.stlGroups[sceneName][groupName]) {
        return;
      }

      const group = scene.getObjectByName(`${groupName}Group`);
      const animationGroup = new AnimationObjectGroup();
      const currentGroup = this.stlGroups[sceneName][groupName];

      if (currentGroup && currentGroup.models) {
        currentGroup.models.forEach(model => {
          const editModel = scene.getObjectByName(model);

          if (editModel) {
            group.attach(editModel);
          }
        });
      }

      animationGroup.add(group);
      const clip = this.createJawAnimationClip(groupName, move);
      const mixerName = `${sceneName}_${groupName}_mixer`;
      const mixer = new AnimationMixer(animationGroup);

      if (clip) {
        const action = mixer.clipAction(clip);
        action.clampWhenFinished = true;
        action.loop = LoopOnce;
        action.setDuration(3);
        action.play();
      }

      mixer.addEventListener("finished", e => {
        if (this.mixers[mixerName]) {
          delete this.mixers[mixerName];
        }
      });
      this.mixers[mixerName] = mixer;
    });
  };
  clearMaskGroup = () => {
    this.scenes.forEach(scene => {
      // const scene = this.scenes.find((el) => el.name === "main");
      const maskGroup = scene.getObjectByName("maskGroup");

      if (maskGroup) {
        maskGroup.clear();
      }
    });
  };
  toggleMaskFiles = headMaskType => {
    this.scenes.forEach(scene => {
      // const scene = this.scenes.find((el) => el.name === "main");
      const maskGroup = scene.getObjectByName("maskGroup");

      if (!headMaskType) {
        // Set all as default
        this.clearMaskGroup();
        this.headMaskType = null;
        this.buildLights("basic");
        this.renderRequested = true; // this.render();

        return;
      } else if (headMaskType !== this.headMaskType) {
        this.clearMaskGroup();
      }

      if (this.lightType !== "shadowedLight3Point") {
        this.buildLights("shadowedLight3Point");
      }

      this.headMaskType = headMaskType;
      gltfLoader.load(require(`./${headMaskType}.glb`), data => {
        data.scene.children.forEach(child => {
          const instance = child.clone();

          if (instance instanceof Mesh) {
            instance.castShadow = true;
            instance.receiveShadow = true;
            instance.material.shadowSide = DoubleSide;
            instance.material.needsUpdate = true;

            if (this.centerPointInited) {
              const vectorCenter = new Vector3(this.centerPoint.x, this.centerPoint.y, this.centerPoint.z);
              instance.position.copy(vectorCenter).negate();
            }
          }

          maskGroup.add(instance);
          this.renderRequested = true; // this.render();
          // maskGroup.position.set();
          // this.syncGroupCenters();
        });
      }, undefined, error => {
        console.error(error);
      });
    });
  };
  removeObject3D = object3D => {
    if (!(object3D instanceof Object3D)) return false; // for better memory management and performance

    object3D.geometry.dispose();

    if (object3D.material instanceof Array) {
      // for better memory management and performance
      object3D.material.forEach(material => material.dispose());
    } else {
      // for better memory management and performance
      object3D.material.dispose();
    }

    object3D.removeFromParent(); // the parent might be the scene or another Object3D, but it is certain to be removed this way

    return true;
  };
  getFileKey = file => {
    return `${file.scene || "main"}_${file.name}`;
  }; // Проверка загруженных STL файлов

  checkStlFiles = files => {
    return new Promise((onLoadEnd, onLoadError) => {
      this.stlGroups = {}; // Clear first load hidden files

      files = files.filter(el => el?.options?.opacity > 0); // Clear scene models if it's not in use

      let existingFiles = { ...this.stlFiles
      };
      files.forEach(file => {
        let fileScene = file.scene || "main";
        let fileName = file.name;
        delete existingFiles[`${fileScene}_${fileName}`];
      });
      Object.keys(existingFiles).forEach(key => {
        const scene = this.scenes.find(el => el.name === (existingFiles[key].scene || "main"));

        if (scene) {
          const sceneCurrentModel = scene.getObjectByName(existingFiles[key].name);
          this.removeObject3D(sceneCurrentModel);
        }
      });
      let loadPromises = [];
      files.forEach(file => {
        const cachedFile = this.stlFiles[this.getFileKey(file)];

        if (cachedFile) {
          const scene = this.scenes.find(el => el.name === file.scene);
          let sceneCurrentModel = scene.getObjectByName(file.name);

          if (!sceneCurrentModel || !isEqual(file.options, cachedFile.options)) {
            this.addGeometryToRender({ ...file,
              geometry: cachedFile.geometry
            });
          }
        } else {
          loadPromises.push(this.loadGeometry(file));
        } // Group getting from params


        if (file?.options?.groups) {
          if (!this.stlGroups.hasOwnProperty(file.scene || "main")) {
            this.stlGroups[file.scene] = {};
          }

          file.options.groups.forEach(el => {
            if (!this.stlGroups[file.scene].hasOwnProperty(el)) {
              this.stlGroups[file.scene][el] = {
                isVisible: true,
                models: [file.name]
              };
            } else {
              if (!this.stlGroups[file.scene][el].models.includes(file.name)) {
                this.stlGroups[file.scene][el].models.push(file.name);
              } else {
                console.error(`ERROR DUPLICATE in ${file.scene} ${el}, ${file.name}`);
              }
            }
          });
        }
      });
      Promise.all(loadPromises).then(() => {
        this.renderRequested = true; // this.render();

        onLoadEnd();
      }).catch(e => {
        onLoadError();
      });
    });
  };
  loadGeometry = rawStlFile => {
    const promise = new Promise((resolve, reject) => {
      this.workerPromises[this.getFileKey(rawStlFile)] = {
        resolve: resolve,
        reject: reject
      };
    });

    if (this.worker) {
      this.worker.postMessage({
        stlFile: rawStlFile
      });

      this.worker.onmessage = ({
        data
      }) => {
        let {
          stlFile,
          sceneId
        } = data;
        const {
          vertexData
        } = stlFile;
        const vertexBuffer = new Float32BufferAttribute(vertexData, 3);
        let geometry = new BufferGeometry();
        geometry.setAttribute("position", vertexBuffer);
        stlFile.geometry = geometry;
        this.addGeometryToRender(stlFile);
        this.workerPromises[this.getFileKey(stlFile)].resolve("Object already loaded");
        delete this.workerPromises[this.getFileKey(stlFile)];
      };
    } else {
      const loader = new STLLoader();
      loader.load(rawStlFile.url, object => {
        let stlFile = { ...rawStlFile,
          geometry: object
        };
        this.addGeometryToRender(stlFile);
        this.workerPromises[this.getFileKey(stlFile)].resolve("Object already loaded");
        delete this.workerPromises[this.getFileKey(stlFile)];
      });
    }

    return promise;
  }; // Submit promise over the url file name

  addGeometryToRender = stlFile => {
    const sceneName = stlFile.scene || "main";
    const scene = this.scenes.find(el => el.name === sceneName);
    const stlOptions = stlFile.hasOwnProperty("options") ? stlFile.options : null;
    let materialName = stlOptions && stlOptions.hasOwnProperty("material") ? stlOptions.material : "default";
    let material = this.getItemMaterial(materialName);
    let sceneCurrentModel = scene.getObjectByName(stlFile.name);

    if (!Boolean(sceneCurrentModel)) {
      // Load geometry
      let geometry = stlFile.geometry; // Функция сглаживания и перевода буфера в геометрию

      if (stlOptions && (!stlOptions.hasOwnProperty("disableSmoothing") || stlOptions.disableSmoothing === false)) {
        geometry = mergeVertices(geometry);
      }

      geometry.computeVertexNormals();
      geometry.computeBoundingSphere();
      let mesh = new Mesh(geometry, material);
      mesh.name = stlFile.name;
      mesh.castShadow = true;
      mesh.receiveShadow = true;

      if (stlOptions && stlOptions.hasOwnProperty("renderOrder")) {
        mesh.renderOrder = stlOptions.renderOrder;
      }

      if (this.centerPointInited) {
        const vectorCenter = new Vector3(this.centerPoint.x, this.centerPoint.y, this.centerPoint.z);
        mesh.position.copy(vectorCenter).negate();
      }

      if (this.isAnimationEnabled || !this.centerPointInited) {
        scene.getObjectByName("centerGroup").add(mesh);
      } else {
        let side = "top";

        if (stlOptions && stlOptions.hasOwnProperty("groups")) {
          side = stlOptions.groups.includes("top") ? "top" : "bottom";
        }

        const sideGroup = scene.getObjectByName(`${side}Group`);

        if (sideGroup) {
          sideGroup.add(mesh);
        } else {
          console.error("Can't find side group for this mesh!");
          return;
        }
      }

      sceneCurrentModel = scene.getObjectByName(stlFile.name);
    } else {
      sceneCurrentModel.material = material;
      sceneCurrentModel.material.needsUpdate = true;
    }

    let opacityType = stlOptions && stlOptions.hasOwnProperty("opacity") ? parseInt(stlOptions.opacity, 10) / 100 : 0;
    this.setModelOpacity(sceneCurrentModel, opacityType); // Here can rewrite visibility from top

    let visibilityType = true;

    if (opacityType > 0 && stlOptions && stlOptions.hasOwnProperty("visible")) {
      visibilityType = stlOptions.visible;
      sceneCurrentModel.visible = visibilityType;
    }

    sceneCurrentModel.userData = { ...sceneCurrentModel.userData,
      basicOpacity: opacityType,
      basicVisibility: visibilityType,
      selectable: stlOptions?.selectable || false,
      transparentDisabled: stlOptions?.transparentDisabled || false
    };
    this.stlFiles[this.getFileKey(stlFile)] = stlFile;
    this.renderRequested = true;
  };
  getItemMaterial = (materialType = null) => {
    switch (materialType) {
      case "steel":
      case "aluminium":
      case "plasticCrown":
      case "plasticTooth":
      case "zirconiumCrown":
      case "zirconiumTooth":
      case "metalCeramicCrown":
      case "metalCeramicTooth":
      case "ceramicCrown":
      case "ceramicTooth":
      case "metalCrown":
      case "metalTooth":
      case "pinToothCrown":
      case "redGlossy":
      case "gold":
        return new MeshStandardMaterial({ ...materials[materialType],
          envMap: this.reflectionCube
        });

      default:
        return new MeshStandardMaterial({ ...materials[materialType]
        });
    }
  };
  getSceneByName = name => {
    let scene = this.scenes.find(el => el.name === name);
    return scene ? scene : "main";
  }; // Switch visibility of formula groups
  // This is hack =(
  // switchFormulaVisibilityGroup = (groupName, value, sceneName) => {
  //   if (sceneName) {
  //     this.setFormulaVisibilityGroup(groupName, value, sceneName);
  //   } else {
  //     this.sceneNames.forEach((name) => {
  //       this.setFormulaVisibilityGroup(groupName, value, name);
  //     });
  //   }
  //
  //   this.renderRequested = true;
  //   // this.render();
  // };

  setFormulaVisibilityGroup = (groupName, isVisible, isTransparent) => {
    // console.log(groupName, isVisible, isTransparent);
    this.scenes.forEach(scene => {
      // const scene = this.getSceneByName(sceneName);
      const sceneName = scene.name;

      if (!this.stlGroups[sceneName] || !this.stlGroups[sceneName][groupName]) {
        return;
      }

      const currentGroup = this.stlGroups[sceneName][groupName];

      if (currentGroup && currentGroup.models) {
        currentGroup.models.forEach(model => {
          const editModel = scene.getObjectByName(model);

          if (!editModel) {
            // console.log("Model is not present in scene setFormulaVisibilityGroup", model, sceneName);
            return;
          }

          if (editModel.material.opacity !== 0) {
            if (isVisible) {
              let opacity = editModel.userData?.basicOpacity || 1;

              if (!isTransparent && !editModel.userData?.transparentDisabled) {
                opacity = 1;
              }

              editModel.material.transparent = opacity !== 1;
              editModel.material.opacity = opacity;
              editModel.visible = true;
            } else {
              editModel.material.transparent = true;
              editModel.material.opacity = 0.1;
              editModel.visible = false;
            }

            this.renderRequested = true; // this.render();
          }
        });
      }
    });
  }; // END Switch visibility of formula groups
  // For direct model use only

  setModelOpacity = (model, opacity) => {
    if (opacity === 1) {
      model.material.transparent = false;
      model.material.opacity = 1;
      model.visible = true;
    } else if (opacity === 0) {
      model.material.transparent = true;
      model.material.opacity = 0;
      model.visible = false;
    } else {
      model.material.transparent = true;
      model.material.opacity = opacity;
      model.visible = true;
    }
  };
  setModelGroupVisibilityByName = (groupName, isVisible) => {
    this.scenes.forEach(scene => {
      const sceneName = scene.name;

      if (!this.stlGroups[sceneName] || !this.stlGroups[sceneName][groupName]) {
        return;
      } // const scene = this.getSceneByName(sceneName);


      const currentGroup = this.stlGroups[sceneName][groupName];

      if (currentGroup && currentGroup.models) {
        // const availableModels = this.stlFiles.map((el) => el.name);
        // const activeModels = currentGroup.models.filter((el) => availableModels.includes(el));
        currentGroup.models.forEach(model => {
          const editModel = scene.getObjectByName(model);

          if (!editModel) {
            // console.log("Model is not present in scene setModelGroupVisibilityByName", model, sceneName);
            return;
          }

          editModel.visible = isVisible;
        });
        this.stlGroups[sceneName][groupName].isVisible = isVisible;
        this.renderRequested = true; // this.render();
      }
    });
  };
}

export default SceneManager;
import { toothTypes } from "../../../FormulaGenerator/Formula/tooth";
import { gapsTypes } from "../../../FormulaGenerator/Formula/gaps";
const cementToothTop4 = [{
  name: "11",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "12",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "13",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "14",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "15",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "16",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "17",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "18",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "21",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "22",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "23",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "24",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "25",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "26",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "27",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "28",
  toothElementsActive: ["emptyTooth"]
}];
const cementToothTop6 = [{
  name: "11",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "12",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "13",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "14",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "15",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "16",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "17",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "18",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "21",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "22",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "23",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "24",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "25",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "26",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "27",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "28",
  toothElementsActive: ["emptyTooth"]
}];
const cementToothBottom4 = [{
  name: "31",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "32",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "33",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "34",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "35",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "36",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "37",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "38",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "41",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "42",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "43",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "44",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "45",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "46",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "47",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "48",
  toothElementsActive: ["emptyTooth"]
}];
const cementToothBottom6 = [{
  name: "31",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "32",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "33",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "34",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "35",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "36",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "37",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "38",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "41",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "42",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "43",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "44",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "45",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "46",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "individualZirconiumAbutment"]
}, {
  name: "47",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "48",
  toothElementsActive: ["emptyTooth"]
}];
const multiunitToothTop4 = [{
  name: "11",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "12",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "13",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "14",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "15",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "16",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "17",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "18",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "21",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "22",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "23",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "24",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "25",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "26",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "27",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "28",
  toothElementsActive: ["emptyTooth"]
}];
const multiunitToothTop6 = [{
  name: "11",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "12",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "13",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "14",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "15",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "16",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "17",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "18",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "21",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "22",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "23",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "24",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "25",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "26",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "27",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "28",
  toothElementsActive: ["emptyTooth"]
}];
const multiunitToothBottom4 = [{
  name: "31",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "32",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "33",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "34",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "35",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "36",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "37",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "38",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "41",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "42",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "43",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "44",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "45",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "46",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "47",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "48",
  toothElementsActive: ["emptyTooth"]
}];
const multiunitToothBottom6 = [{
  name: "31",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "32",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "33",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "34",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "35",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "36",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "37",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "38",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "41",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "42",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "43",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "44",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "45",
  toothElementsActive: ["plasticTooth", "syntheticGum"]
}, {
  name: "46",
  toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
}, {
  name: "47",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "48",
  toothElementsActive: ["emptyTooth"]
}];
const telescopToothTop4 = [{
  name: "11",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "12",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "13",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "14",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "15",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "16",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "17",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "18",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "21",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "22",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "23",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "24",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "25",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "26",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "27",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "28",
  toothElementsActive: ["emptyTooth"]
}];
const telescopToothTop6 = [{
  name: "11",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "12",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "13",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "14",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "15",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "16",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "17",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "18",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "21",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "22",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "23",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "24",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "25",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "26",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "27",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "28",
  toothElementsActive: ["emptyTooth"]
}];
const telescopToothBottom4 = [{
  name: "31",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "32",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "33",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "34",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "35",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "36",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "37",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "38",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "41",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "42",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "43",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "44",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "45",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "46",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "47",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "48",
  toothElementsActive: ["emptyTooth"]
}];
const telescopToothBottom6 = [{
  name: "31",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "32",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "33",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "34",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "35",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "36",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "37",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "38",
  toothElementsActive: ["emptyTooth"]
}, {
  name: "41",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "42",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "43",
  toothElementsActive: ["dentalBar", "prostheticsTooth"]
}, {
  name: "44",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "45",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "46",
  toothElementsActive: ["implantPosition", "individualTitaniumAbutment", "telescopicCrown", "prostheticsTooth"]
}, {
  name: "47",
  toothElementsActive: ["prostheticsTooth"]
}, {
  name: "48",
  toothElementsActive: ["emptyTooth"]
}];
const connectionsTop4 = [{
  name: "1112",
  connection: true
}, {
  name: "2111",
  connection: true
}, {
  name: "1213",
  connection: true
}, {
  name: "1314",
  connection: true
}, {
  name: "1415",
  connection: true
}, {
  name: "1516",
  connection: false
}, {
  name: "1617",
  connection: false
}, {
  name: "1718",
  connection: false
}, {
  name: "2221",
  connection: true
}, {
  name: "2322",
  connection: true
}, {
  name: "2423",
  connection: true
}, {
  name: "2524",
  connection: true
}, {
  name: "2625",
  connection: false
}, {
  name: "2726",
  connection: false
}, {
  name: "2827",
  connection: false
}];
const connectionsTop6 = [{
  name: "1112",
  connection: true
}, {
  name: "2111",
  connection: true
}, {
  name: "1213",
  connection: true
}, {
  name: "1314",
  connection: true
}, {
  name: "1415",
  connection: true
}, {
  name: "1516",
  connection: true
}, {
  name: "1617",
  connection: false
}, {
  name: "1718",
  connection: false
}, {
  name: "2221",
  connection: true
}, {
  name: "2322",
  connection: true
}, {
  name: "2423",
  connection: true
}, {
  name: "2524",
  connection: true
}, {
  name: "2625",
  connection: true
}, {
  name: "2726",
  connection: false
}, {
  name: "2827",
  connection: false
}];
const connectionsBottom4 = [{
  name: "3132",
  connection: true
}, {
  name: "3233",
  connection: true
}, {
  name: "3334",
  connection: true
}, {
  name: "3435",
  connection: true
}, {
  name: "3536",
  connection: false
}, {
  name: "3637",
  connection: false
}, {
  name: "3738",
  connection: false
}, {
  name: "4131",
  connection: true
}, {
  name: "4241",
  connection: true
}, {
  name: "4342",
  connection: true
}, {
  name: "4443",
  connection: true
}, {
  name: "4544",
  connection: true
}, {
  name: "4645",
  connection: false
}, {
  name: "4746",
  connection: false
}, {
  name: "4847",
  connection: false
}];
const connectionsBottom6 = [{
  name: "3132",
  connection: true
}, {
  name: "3233",
  connection: true
}, {
  name: "3334",
  connection: true
}, {
  name: "3435",
  connection: true
}, {
  name: "3536",
  connection: true
}, {
  name: "3637",
  connection: false
}, {
  name: "3738",
  connection: false
}, {
  name: "4131",
  connection: true
}, {
  name: "4241",
  connection: true
}, {
  name: "4342",
  connection: true
}, {
  name: "4443",
  connection: true
}, {
  name: "4544",
  connection: true
}, {
  name: "4645",
  connection: true
}, {
  name: "4746",
  connection: false
}, {
  name: "4847",
  connection: false
}];
export const cementImplantProsthesisFixationTop4 = {
  tooth: [...cementToothTop4, ...toothTypes.filter(el => el.position === "bottom").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  }))],
  gaps: [...connectionsTop4, gapsTypes.filter(el => el.position === "bottom").map(el => ({
    name: el.name,
    connection: false
  }))]
};
export const cementImplantProsthesisFixationTop6 = {
  tooth: [...cementToothTop6, ...toothTypes.filter(el => el.position === "bottom").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  }))],
  gaps: [...connectionsTop6, gapsTypes.filter(el => el.position === "bottom").map(el => ({
    name: el.name,
    connection: false
  }))]
};
export const cementImplantProsthesisFixationBottom4 = {
  tooth: [...toothTypes.filter(el => el.position === "top").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  })), ...cementToothBottom4],
  gaps: [gapsTypes.filter(el => el.position === "top").map(el => ({
    name: el.name,
    connection: false
  })), ...connectionsBottom4]
};
export const cementImplantProsthesisFixationBottom6 = {
  tooth: [...toothTypes.filter(el => el.position === "top").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  })), ...cementToothBottom6],
  gaps: [gapsTypes.filter(el => el.position === "top").map(el => ({
    name: el.name,
    connection: false
  })), ...connectionsBottom6]
};
export const cementImplantProsthesisFixationBoth4 = {
  tooth: [...cementToothTop4, ...cementToothBottom4],
  gaps: [...connectionsTop4, ...connectionsBottom4]
};
export const cementImplantProsthesisFixationBoth6 = {
  tooth: [...cementToothTop6, ...cementToothBottom6],
  gaps: [...connectionsTop6, ...connectionsBottom6]
};
export const multiunitImplantProsthesisFixationTop4 = {
  tooth: [...multiunitToothTop4, ...toothTypes.filter(el => el.position === "bottom").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  }))],
  gaps: [...connectionsTop4, gapsTypes.filter(el => el.position === "bottom").map(el => ({
    name: el.name,
    connection: false
  }))]
};
export const multiunitImplantProsthesisFixationTop6 = {
  tooth: [...multiunitToothTop6, ...toothTypes.filter(el => el.position === "bottom").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  }))],
  gaps: [...connectionsTop6, gapsTypes.filter(el => el.position === "bottom").map(el => ({
    name: el.name,
    connection: false
  }))]
};
export const multiunitImplantProsthesisFixationBottom4 = {
  tooth: [...toothTypes.filter(el => el.position === "top").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  })), ...multiunitToothBottom4],
  gaps: [gapsTypes.filter(el => el.position === "top").map(el => ({
    name: el.name,
    connection: false
  })), ...connectionsBottom4]
};
export const multiunitImplantProsthesisFixationBottom6 = {
  tooth: [...toothTypes.filter(el => el.position === "top").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  })), ...multiunitToothBottom6],
  gaps: [gapsTypes.filter(el => el.position === "top").map(el => ({
    name: el.name,
    connection: false
  })), ...connectionsBottom6]
};
export const multiunitImplantProsthesisFixationBoth4 = {
  tooth: [...multiunitToothTop4, ...multiunitToothBottom4],
  gaps: [...connectionsTop4, ...connectionsBottom4]
};
export const multiunitImplantProsthesisFixationBoth6 = {
  tooth: [...multiunitToothTop6, ...multiunitToothBottom6],
  gaps: [...connectionsTop6, ...connectionsBottom6]
};
export const telescopImplantProsthesisFixationTop4 = {
  tooth: [...telescopToothTop4, ...toothTypes.filter(el => el.position === "bottom").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  }))],
  prosthesis: ["clutchTop"]
};
export const telescopImplantProsthesisFixationTop6 = {
  tooth: [...telescopToothTop6, ...toothTypes.filter(el => el.position === "bottom").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  }))],
  prosthesis: ["clutchTop"]
};
export const telescopImplantProsthesisFixationBottom4 = {
  tooth: [...toothTypes.filter(el => el.position === "top").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  })), ...telescopToothBottom4],
  prosthesis: ["clutchBottom"]
};
export const telescopImplantProsthesisFixationBottom6 = {
  tooth: [...toothTypes.filter(el => el.position === "top").map(el => ({
    name: el.name,
    toothElementsActive: ["healthyTooth"]
  })), ...telescopToothBottom6],
  prosthesis: ["clutchBottom"]
};
export const telescopImplantProsthesisFixationBoth4 = {
  tooth: [...telescopToothTop4, ...telescopToothBottom4],
  prosthesis: ["clutchTop", "clutchBottom"]
};
export const telescopImplantProsthesisFixationBoth6 = {
  tooth: [...telescopToothTop6, ...telescopToothBottom6],
  prosthesis: ["clutchTop", "clutchBottom"]
};
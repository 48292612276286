export const educationPack1 = {
  tooth: [{
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "15",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["implantPosition", "plasticCrown", "individualZirconiumAbutment"]
  }, {
    name: "17",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "18",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["implantPosition", "plasticCrown", "individualZirconiumAbutment"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "25",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "26",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "27",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "28",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "31",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "32",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "33",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "34",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "35",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "36",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "37",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "41",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "42",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "43",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "45",
    toothElementsActive: ["implantPosition", "plasticCrown", "multiunitAbutment"]
  }, {
    name: "46",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["implantPosition", "plasticCrown", "multiunitAbutment"]
  }, {
    name: "48",
    toothElementsActive: ["healthyTooth"]
  }],
  gaps: [{
    name: "1112",
    connection: false
  }, {
    name: "2111",
    connection: false
  }, {
    name: "1213",
    connection: false
  }, {
    name: "1314",
    connection: false
  }, {
    name: "1415",
    connection: false
  }, {
    name: "1516",
    connection: false
  }, {
    name: "1617",
    connection: false
  }, {
    name: "1718",
    connection: false
  }, {
    name: "2221",
    connection: false
  }, {
    name: "2322",
    connection: false
  }, {
    name: "2423",
    connection: false
  }, {
    name: "2524",
    connection: false
  }, {
    name: "2625",
    connection: true
  }, {
    name: "2726",
    connection: true
  }, {
    name: "2827",
    connection: false
  }, {
    name: "3132",
    connection: true
  }, {
    name: "3233",
    connection: false
  }, {
    name: "3334",
    connection: false
  }, {
    name: "3435",
    connection: false
  }, {
    name: "3536",
    connection: false
  }, {
    name: "3637",
    connection: false
  }, {
    name: "3738",
    connection: false
  }, {
    name: "4131",
    connection: true
  }, {
    name: "4241",
    connection: false
  }, {
    name: "4342",
    connection: false
  }, {
    name: "4443",
    connection: false
  }, {
    name: "4544",
    connection: false
  }, {
    name: "4645",
    connection: true
  }, {
    name: "4746",
    connection: true
  }, {
    name: "4847",
    connection: false
  }]
};
export const educationPack2 = {
  tooth: [{
    name: "11",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "12",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "13",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "14",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "15",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "16",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "17",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "18",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "21",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "22",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "23",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "24",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "25",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "26",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "27",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "28",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "31",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "32",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "33",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "34",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "35",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "36",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "37",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "38",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "41",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "42",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "43",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "44",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "45",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "46",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "48",
    toothElementsActive: ["oldEsteticTooth"]
  }]
};
export const implantationSwitchers = ["top", "bottom", "base", "implants", "fixedProstheses", "abutments", "screws"];
export const implantation = {
  gaps: [{
    name: "1112",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2111",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1213",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1314",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1415",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1516",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1617",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1718",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2221",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2322",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2423",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2524",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "2625",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "2726",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2827",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3132",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3233",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3334",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3435",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "3536",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "3637",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3738",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4131",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4241",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4342",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4443",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4544",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4645",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "4746",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "4847",
    connection: false,
    separation: false,
    miniImplant: false
  }],
  tooth: [{
    name: "18",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "17",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "15",
    toothElementsActive: ["implantPosition", "individualZirconiumAbutment", "plasticCrown"]
  }, {
    name: "14",
    toothElementsActive: ["implantPosition", "individualZirconiumAbutment", "plasticCrown"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["implantPosition", "individualZirconiumAbutment", "plasticCrown"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["implantPosition", "individualZirconiumAbutment", "plasticCrown"]
  }, {
    name: "25",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "26",
    toothElementsActive: ["implantPosition", "individualZirconiumAbutment", "plasticCrown"]
  }, {
    name: "27",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "37",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "36",
    toothElementsActive: ["implantPosition", "individualZirconiumAbutment", "plasticCrown"]
  }, {
    name: "35",
    toothElementsActive: ["implantPosition", "individualZirconiumAbutment", "plasticCrown"]
  }, {
    name: "34",
    toothElementsActive: ["implantPosition", "individualZirconiumAbutment", "plasticCrown"]
  }, {
    name: "33",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "31",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "41",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "42",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "43",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "45",
    toothElementsActive: ["implantPosition", "individualZirconiumAbutment", "plasticCrown"]
  }, {
    name: "46",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["implantPosition", "individualZirconiumAbutment", "plasticCrown"]
  }, {
    name: "48",
    toothElementsActive: ["healthyTooth"]
  }]
};
export const totalImplantationSwitchers = ["top", "bottom", "base", "implants", "fixedProstheses", "screws", "multiunitAbutments"];
export const totalImplantation = {
  gaps: [{
    name: "1112",
    connection: true
  }, {
    name: "2111",
    connection: true
  }, {
    name: "1213",
    connection: true
  }, {
    name: "1314",
    connection: true
  }, {
    name: "1415",
    connection: true
  }, {
    name: "1516",
    connection: true
  }, {
    name: "1617",
    connection: false
  }, {
    name: "1718",
    connection: false
  }, {
    name: "2221",
    connection: true
  }, {
    name: "2322",
    connection: true
  }, {
    name: "2423",
    connection: true
  }, {
    name: "2524",
    connection: true
  }, {
    name: "2625",
    connection: true
  }, {
    name: "2726",
    connection: false
  }, {
    name: "2827",
    connection: false
  }, {
    name: "3132",
    connection: false
  }, {
    name: "3233",
    connection: false
  }, {
    name: "3334",
    connection: false
  }, {
    name: "3435",
    connection: false
  }, {
    name: "3536",
    connection: false
  }, {
    name: "3637",
    connection: false
  }, {
    name: "3738",
    connection: false
  }, {
    name: "4131",
    connection: false
  }, {
    name: "4241",
    connection: false
  }, {
    name: "4342",
    connection: false
  }, {
    name: "4443",
    connection: false
  }, {
    name: "4544",
    connection: false
  }, {
    name: "4645",
    connection: false
  }, {
    name: "4746",
    connection: false
  }, {
    name: "4847",
    connection: false
  }],
  tooth: [{
    name: "11",
    toothElementsActive: ["plasticTooth", "syntheticGum"]
  }, {
    name: "12",
    toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
  }, {
    name: "13",
    toothElementsActive: ["plasticTooth", "syntheticGum"]
  }, {
    name: "14",
    toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
  }, {
    name: "15",
    toothElementsActive: ["plasticTooth", "syntheticGum"]
  }, {
    name: "16",
    toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
  }, {
    name: "17",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "18",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["plasticTooth", "syntheticGum"]
  }, {
    name: "22",
    toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
  }, {
    name: "23",
    toothElementsActive: ["plasticTooth", "syntheticGum"]
  }, {
    name: "24",
    toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
  }, {
    name: "25",
    toothElementsActive: ["plasticTooth", "syntheticGum"]
  }, {
    name: "26",
    toothElementsActive: ["implantPosition", "plasticCrown", "syntheticGum", "multiunitAbutment"]
  }, {
    name: "27",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "31",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "33",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "34",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "35",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "36",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "37",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "41",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "42",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "43",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "45",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "46",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "47",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "48",
    toothElementsActive: ["healthyTooth"]
  }]
};
export const veneersSwitchers = ["top", "bottom", "base", "fixedProstheses"];
export const veneers = {
  tooth: [{
    name: "11",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "12",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "13",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "14",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "15",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "16",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "17",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "18",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "21",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "22",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "23",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "24",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "25",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "26",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "27",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "28",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "31",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "32",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "33",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "34",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "35",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "36",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "37",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "38",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "41",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "42",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "43",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "44",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "45",
    toothElementsActive: ["veneerTab"]
  }, {
    name: "46",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "48",
    toothElementsActive: ["oldEsteticTooth"]
  }]
};
export const crownsSwitchers = ["top", "bottom", "base", "fixedProstheses"];
export const crowns = {
  tooth: [{
    name: "11",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "12",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "13",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "14",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "15",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "16",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "17",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "18",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "21",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "22",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "23",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "24",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "25",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "26",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "27",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "28",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "31",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "32",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "33",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "34",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "35",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "36",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "37",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "38",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "41",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "42",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "43",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "44",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "45",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "46",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "48",
    toothElementsActive: ["oldEsteticTooth"]
  }]
};
export const compositeRestorationsSwitchers = ["top", "bottom", "base", "fixedProstheses"];
export const compositeRestorations = {
  tooth: [{
    name: "11",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "12",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "13",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "14",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "15",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "16",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "17",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "18",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "21",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "22",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "23",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "24",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "25",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "26",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "27",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "28",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "31",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "32",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "33",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "34",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "35",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "36",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "37",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "38",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "41",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "42",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "43",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "44",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "45",
    toothElementsActive: ["oldEsteticTooth", "newEsteticTooth"]
  }, {
    name: "46",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "48",
    toothElementsActive: ["oldEsteticTooth"]
  }]
};
export const bridgeProstheticsSwitchers = ["top", "bottom", "base", "fixedProstheses"];
export const bridgeProsthetics = {
  gaps: [{
    name: "1112",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2111",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1213",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1314",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "1415",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "1516",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "1617",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1718",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2221",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2322",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2423",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2524",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "2625",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "2726",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2827",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3132",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "3233",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3334",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3435",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "3536",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "3637",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3738",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4131",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "4241",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "4342",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4443",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4544",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "4645",
    connection: true,
    separation: false,
    miniImplant: false
  }, {
    name: "4746",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4847",
    connection: false,
    separation: false,
    miniImplant: false
  }],
  tooth: [{
    name: "18",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "17",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["healthyTooth", "plasticCrown"]
  }, {
    name: "15",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "14",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth", "plasticCrown"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["healthyTooth", "plasticCrown"]
  }, {
    name: "25",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "26",
    toothElementsActive: ["healthyTooth", "plasticCrown"]
  }, {
    name: "27",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "37",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "36",
    toothElementsActive: ["healthyTooth", "plasticCrown"]
  }, {
    name: "35",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "34",
    toothElementsActive: ["healthyTooth", "plasticCrown"]
  }, {
    name: "33",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "31",
    toothElementsActive: ["healthyTooth", "plasticCrown"]
  }, {
    name: "41",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "42",
    toothElementsActive: ["healthyTooth", "plasticCrown"]
  }, {
    name: "43",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["plasticCrown"]
  }, {
    name: "45",
    toothElementsActive: ["healthyTooth", "plasticCrown"]
  }, {
    name: "46",
    toothElementsActive: ["plasticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["healthyTooth", "plasticCrown"]
  }, {
    name: "48",
    toothElementsActive: ["healthyTooth"]
  }]
};
export const removableProstheticsSwitchers = ["top", "bottom", "base", "removableProsthetics"];
export const removableProsthetics = {
  gaps: [{
    name: "1112",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2111",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1213",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1314",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1415",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1516",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1617",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "1718",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2221",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2322",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2423",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2524",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2625",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2726",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "2827",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3132",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3233",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3334",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3435",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3536",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3637",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "3738",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4131",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4241",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4342",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4443",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4544",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4645",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4746",
    connection: false,
    separation: false,
    miniImplant: false
  }, {
    name: "4847",
    connection: false,
    separation: false,
    miniImplant: false
  }],
  tooth: [{
    name: "18",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "17",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "15",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "14",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "25",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "26",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "27",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "37",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "36",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "35",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "34",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "33",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "31",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "41",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "42",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "43",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "45",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "46",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "47",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "48",
    toothElementsActive: ["emptyTooth"]
  }],
  prosthesis: ["clutchTop", "clutchBottom"]
};
export const fullRemovableProstheticsSwitchers = ["top", "bottom", "base", "removableProsthetics"];
export const fullRemovableProsthetics = {
  tooth: [{
    name: "18",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "17",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "15",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "25",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "26",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "27",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "37",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "36",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "35",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "34",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "33",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "32",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "31",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "41",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "42",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "43",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "44",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "45",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "46",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "47",
    toothElementsActive: ["prostheticsTooth"]
  }, {
    name: "48",
    toothElementsActive: ["prostheticsTooth"]
  }],
  prosthesis: ["totalBottom"]
};
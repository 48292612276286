import "./MainTemplate.scss";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Typography,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import logo from "./assets/logo.png";
import { useTranslation } from "react-i18next";
import "./HomeSection.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { TreatmentPlanBtn } from "../Auth/ActionBtns";
import { setMediaLibrary } from "../../redux/reducers/mediaLibrary";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { setVideoViewer } from "../../redux/reducers/videoViewer";
import { useGetPackageDetailsQuery } from "../../redux/packagesApi";

function HomeSection() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={8}>
        <HomeLetsStart />
      </Grid>
      <Grid item xs={6} md={4}>
        <HomeHelpSlider />
      </Grid>
      <Grid item xs={12} md={12}>
        <HomeMediaLibrary />
      </Grid>
      <Grid item xs={12} md={12}>
        <HomeGuides />
      </Grid>
    </Grid>
  );
}

const HomeLetsStart = () => {
  const { t: _t } = useTranslation();
  const { data: packageDetails, isLoading: isPackageDetailsLoading } =
    useGetPackageDetailsQuery();

  return (
    <Paper
      sx={{ px: 2, py: 2, backgroundColor: "transparent" }}
      className="card-letsStart"
    >
      <Box sx={{ mr: 2 }}>
        <h3 className="card-letsStart-title">
          {_t("Welcome to Dental Bot! Your virtual dental consultant.")}
        </h3>
        <p className="card-letsStart-subTitle">
          {_t(
            "The world’s first application that can tell you about a dental problem and create preliminary treatment options in 3D."
          )}
        </p>
        {!isPackageDetailsLoading && (
          <Button
            variant="contained"
            color="green"
            size="large"
            sx={{ mt: 2, px: 8 }}
            to="/treatment-plan"
            component={RouterLink}
            disabled={!packageDetails.cardCreationEnabled}
          >
            {_t("Let's start")}
          </Button>
        )}
      </Box>
      <img src={logo} className="card-letsStart-logo" alt="" />
    </Paper>
  );
};

const HomeHelpSlider = () => {
  const { t: _t } = useTranslation();

  const slides = useMemo(() => {
    return [
      {
        id: 1,
        img: require("./assets/slide1.png"),
        text: _t("Visualization in 3D"),
      },
      {
        id: 2,
        img: require("./assets/slide2.png"),
        text: _t("Options for treatment and prosthetics plans"),
      },
      {
        id: 3,
        img: require("./assets/slide3.png"),
        text: _t("Information about a dental problem"),
      },
      {
        id: 4,
        img: require("./assets/slide4.png"),
        text: _t("Documents with illustrations"),
      },
    ];
  }, [_t]);

  const [slideIndex, setSlideIndex] = useState(0);

  function prev() {
    if (slideIndex === 0) {
      setSlideIndex(slides.length - 1);
    } else {
      setSlideIndex((prev) => prev - 1);
    }
  }

  function next() {
    if (slideIndex < slides.length - 1) {
      setSlideIndex((prev) => prev + 1);
    } else {
      setSlideIndex(0);
    }
  }

  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => next(), 2000);
    return () => {
      resetTimeout();
    };
  }, [slideIndex]);

  return (
    <Paper className="card-helpSlider">
      <div className="helpSlider">
        <div className="helpSlider-navigation left">
          <IconButton onClick={prev} sx={{ color: "#fff" }} size="small">
            <ArrowBackIos />
          </IconButton>
          <IconButton onClick={next} sx={{ color: "#fff" }} size="small">
            <ArrowForwardIos />
          </IconButton>
        </div>
        <div className="helpSlider-content">
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className={`helpSlider-content-item ${
                index === slideIndex ? "active" : ""
              }`}
            >
              <div className="helpSlider-content-item-text">{slide.text}</div>
              <img
                src={slide.img}
                className="helpSlider-content-item-img"
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    </Paper>
  );
};

const HomeMediaLibrary = () => {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();

  const { data: packageDetails, isLoading: isPackageDetailsLoading } =
    useGetPackageDetailsQuery();

  const player = useRef(null);

  const CDN_DOMAIN = process.env.REACT_APP_CDN_DOMAIN;

  const homeMediaContent = useMemo(() => {
    return [
      {
        video: `${CDN_DOMAIN}/Videos/decayed_tooth_video.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/decayed_tooth-preview.jpg`,
        title: _t("Progression of caries"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/partial-adentia.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/partial-adentia_preview.jpg`,
        title: _t("Consequences of adentia"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/постоянная пломба.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/постоянная пломба.jpg`,
        title: _t("Composite filling"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/винир_1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/винир_превью_1.jpg`,
        title: _t("Veneer"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/Пломбировка канала.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Пломбировка канала.jpg`,
        title: _t("Canal filling"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/skeling.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/skeling.jpg`,
        title: _t("Scaling, root polishing"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах 1.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Протез на мультиюнитах 1.jpg`,
        title: _t("Prosthesis on multi-units"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/Абатмент титановый 2.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Абатмент титановый 2.png`,
        title: _t("Crown on titanium abutment"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/alternativeBridges.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/alternativeBridges.png`,
        title: _t("Crown on titanium abutment"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/Ceramic_Tabs.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Ceramic_Tabs.jpg`,
        title: _t("Ceramic inlays"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/Атрофия десны Colleagial.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Атрофия десны Colleagial.jpg`,
        title: _t("Gum atrophy"),
      },
      {
        video: `${CDN_DOMAIN}/Videos/Dental Microprosthesis Removable.mp4`,
        videoPoster: `${CDN_DOMAIN}/Videos/Dental Microprosthesis Removable.png`,
        title: _t("Temporary micro prosthesis"),
      },
    ];
  }, [_t]);

  function openMediaLibrary() {
    dispatch(setMediaLibrary(true));
  }

  function previewVideo(video) {
    dispatch(
      setVideoViewer({ isShow: true, video: video.video, title: video.title })
    );
  }

  return (
    <Card className="mediaLibraryExample">
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {_t("Media library")}
        </Typography>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          {!isPackageDetailsLoading && (
            <Button
              variant="contained"
              sx={{ mb: 2, maxWidth: 350 }}
              fullWidth
              color="primary"
              onClick={openMediaLibrary}
              disabled={!packageDetails.cardCreationEnabled}
            >
              {_t("Open media library")}
            </Button>
          )}
        </Box>
        <ImageList sx={{ height: 450 }} cols={3} gap={8}>
          {homeMediaContent.map((item) => (
            <ImageListItem
              key={item.videoPoster}
              onClick={() => {
                previewVideo(item);
              }}
              className="mediaLibraryExample-list-item"
            >
              <img
                src={`${item.videoPoster}?w=248&fit=crop&auto=format`}
                alt=""
                loading="lazy"
              />
              <IconButton className="mediaLibraryExample-list-item-videoIcon">
                <OndemandVideoIcon fontSize="inherit" />
              </IconButton>
              <ImageListItemBar
                title={item.title}
                sx={{ textAlign: "center" }}
                position="below"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </CardContent>
    </Card>
  );
};

const HomeGuides = () => {
  const { t: _t } = useTranslation();

  const guides = [
    {
      id: 1,
      src: "https://www.youtube.com/embed/XvRz0kndzsM?list=PLNqmPKc3x4A7TFm1b6JpnU6LD8xfkILI8",
      img: require("./assets/guideEmptyTooth.png"),
      title: _t("Missing teeth"),
      description: _t(
        "The prosthetic module will demonstrate what types of dental prosthetics exist and which ones may suit you."
      ),
    },
    {
      id: 2,
      src: "https://www.youtube.com/embed/4rXGk8hyMO0?list=PLNqmPKc3x4A7TFm1b6JpnU6LD8xfkILI8",
      img: require("./assets/guideEmptyTooth.png"),
      title: _t("Caries, toothache"),
      description: _t(
        "The therapeutic module will help you define the problem, after which the application  will offer the optimal treatment."
      ),
    },
    {
      id: 3,
      src: "https://www.youtube.com/embed/JGvvy2KW3jI?list=PLNqmPKc3x4A7TFm1b6JpnU6LD8xfkILI8",
      img: require("./assets/guideImperfectShape.png"),
      title: _t("Ugly tooth shape"),
      description: _t(
        "The aesthetic module will help you learn more and choose the types of restoration."
      ),
    },
    {
      id: 4,
      src: "https://www.youtube.com/embed/Sfsd86spTtI?list=PLNqmPKc3x4A7TFm1b6JpnU6LD8xfkILI8",
      img: require("./assets/guideUneven.png"),
      title: _t("Uneven teeth"),
      description: _t(
        "The orthodontic module will help you to find out what types of tooth alignment exist and in which cases they are used."
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {_t("Guides")}
        </Typography>
        <Grid container spacing={2} className="guide-list">
          {guides.map((guide) => (
            <Grid key={guide.id} item xs={6} md={3}>
              <Card className="guide-list-item">
                <CardMedia
                  component="iframe"
                  alt=""
                  height="135"
                  src={guide.src}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    align="center"
                  >
                    {guide.title}
                  </Typography>
                  <img
                    src={guide.img}
                    className="guide-list-item-image"
                    alt=""
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 2 }}
                  >
                    {guide.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
            <TreatmentPlanBtn />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HomeSection;

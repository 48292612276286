import React from "react";
import { Link as RouterLink } from "react-router-dom";
import logo2 from "./assets/logo2.png";
import "./index.scss";
import LogoBlock from "../LogoBlock";
import { useTranslation } from "react-i18next";
import { ProfileBtn, SignInBtn, SignOutBtn, SignUpBtn } from "../Auth";
import { useSelector } from "react-redux";

function Landing() {
  const { t: _t } = useTranslation();
  let isAuth = useSelector(({ auth }) => auth.isLoggedIn);

  return (
    <div className="mainPage">
      <header className="mainPage-header">
        <LogoBlock />
        {!isAuth ? (
          <div>
            <SignInBtn />
            <SignUpBtn />
          </div>
        ) : (
          <div>
            <ProfileBtn />
            <SignOutBtn />
          </div>
        )}
      </header>
      <section className="mainPage-section1">
        <h1 className="mainPage-section1-title">
          <strong>Dental Bot</strong>
          <br />
          CONSULTANT
        </h1>
        <img src={logo2} className="mainPage-section1-logo" alt="" />
        <h3 className="mainPage-section1-subTitle">
          {_t("Your virtual")}
          <br />
          <strong>{_t("Dental consultant")}</strong>
        </h3>
        <RouterLink to="account" className="btnGradient">
          {_t("Create treatment plan")}
        </RouterLink>
      </section>
    </div>
  );
}

export default Landing;

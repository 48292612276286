export const adentyTopImplant4 = {
  tooth: [{
    name: "11",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "13",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "15",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "16",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "17",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "18",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "23",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "25",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "26",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "27",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "31",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "33",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "34",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "35",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "36",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "37",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "41",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "42",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "43",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "45",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "46",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "47",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "48",
    toothElementsActive: ["healthyTooth"]
  }]
};
export const adentyTopImplant6 = {
  tooth: [{
    name: "11",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "13",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "15",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "17",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "18",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "23",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "25",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "26",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "27",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "31",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "33",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "34",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "35",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "36",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "37",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "41",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "42",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "43",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "45",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "46",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "47",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "48",
    toothElementsActive: ["healthyTooth"]
  }]
};
export const adentyBottomImplant4 = {
  tooth: [{
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "15",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "17",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "18",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "25",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "26",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "27",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "31",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "33",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "34",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "35",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "36",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "37",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "41",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "42",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "43",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "45",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "46",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "47",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "48",
    toothElementsActive: ["emptyTooth"]
  }]
};
export const adentyBottomImplant6 = {
  tooth: [{
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "15",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "17",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "18",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "25",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "26",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "27",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "31",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "33",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "34",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "35",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "36",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "37",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "41",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "42",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "43",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "45",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "46",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "47",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "48",
    toothElementsActive: ["emptyTooth"]
  }]
};
export const adentyBothImplant4 = {
  tooth: [{
    name: "11",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "13",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "15",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "16",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "17",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "18",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "23",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "25",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "26",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "27",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "31",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "33",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "34",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "35",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "36",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "37",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "41",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "42",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "43",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "45",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "46",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "47",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "48",
    toothElementsActive: ["emptyTooth"]
  }]
};
export const adentyBothImplant6 = {
  tooth: [{
    name: "11",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "13",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "15",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "17",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "18",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "21",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "23",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "25",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "26",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "27",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "28",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "31",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "33",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "34",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "35",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "36",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "37",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "38",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "41",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "42",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "43",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "45",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "46",
    toothElementsActive: ["implantPosition"]
  }, {
    name: "47",
    toothElementsActive: ["emptyTooth"]
  }, {
    name: "48",
    toothElementsActive: ["emptyTooth"]
  }]
};
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import metaList from "../../metaList";

const MetaTags = ({ children }) => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();

  let base_url = process.env.REACT_APP_BASE_URL;

  const locale = useMemo(() => {
    const localesList = { en: "en_EN", ru: "ru_RU" };
    if (localesList.hasOwnProperty(i18n.language)) {
      return localesList[i18n.language];
    }
    return localesList["en"];
  }, [i18n.language]);

  const tags = useMemo(() => {
    if (
      metaList.hasOwnProperty(pathname) &&
      metaList[pathname].hasOwnProperty(i18n.language)
    ) {
      return metaList[pathname][i18n.language];
    }
    return metaList["/"]["en"];
  }, [pathname, i18n.language]);

  return (
    <Helmet>
      <title>{`Dentalbot Consultant | ${tags.title}`}</title>
      <meta name="description" content={tags.desc} />
      <meta name="keywords" content={tags.keys} />
      <meta property="og:locale" content={locale} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={`Dental Bot 3D | ${tags.title}`} />
      <meta property="og:description" content={tags.desc} />
      <meta property="og:image" content={`${base_url}${tags.img || ""}`} />
      <meta property="og:url" content={`${base_url}${tags.url}`} />
      {/*<meta property="og:url:alternate" content={`https://dentalbot.pro${tags.url}/`} />*/}
      {/*<meta property="og:url:alternate" content={`https://ru.dentalbot.pro${tags.url}/`} />*/}
      <meta property="og:site_name" content={"Dental Bot 3D"} />
      <meta
        name="robots"
        content={tags.hasOwnProperty("robots") ? tags.robots : "index, follow"}
      />
      <link rel="canonical" href={`${base_url}${tags.url}`} />
      {/*<link rel="alternate" hrefLang="ru" href={`https://dentalbot.pro${tags.url}/`} />*/}
      {/*<link rel="alternate" hrefLang="ru" href={`https://ru.dentalbot.pro${tags.url}/`} />;*/}
      {children}
    </Helmet>
  );
};

export default MetaTags;

import { DoubleSide, Color } from "three";
const gammaFactor = 2.0; // Gamma To Linear
// 255 * POWER(gammavalue / 255,2.2)

const materials = {
  enamelSynthetic: {
    color: new Color(0xffffff).convertGammaToLinear(gammaFactor),
    roughness: 0.2,
    metalness: 0.05,
    side: DoubleSide
  },
  bone: {
    color: new Color(0xffefc9).convertGammaToLinear(gammaFactor),
    roughness: 0.8,
    metalness: 0.3,
    side: DoubleSide
  },
  enamel: {
    color: new Color(0xe3dccc).convertGammaToLinear(gammaFactor),
    roughness: 0.4,
    metalness: 0.05,
    side: DoubleSide
  },
  enamelRoot: {
    color: new Color(0xddc492),
    roughness: 0.6,
    metalness: 0.05,
    side: DoubleSide
  },
  enamelCarious: {
    color: new Color(0x8e8b85),
    roughness: 0.4,
    metalness: 0.05,
    side: DoubleSide
  },
  gum: {
    color: new Color(0xac6870).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  steel: {
    color: new Color(0x636363),
    roughness: 0.5,
    metalness: 1,
    side: DoubleSide
  },
  aluminium: {
    color: new Color(0x8f8f8f),
    roughness: 0.2,
    metalness: 1,
    side: DoubleSide
  },
  plasticCrown: {
    color: new Color(0x81d842).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  plasticTooth: {
    color: new Color(0x45ee99).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  zirconiumCrown: {
    color: new Color(0x5036f8).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  zirconiumTooth: {
    color: new Color(0x4ad6ff).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  metalCeramicCrown: {
    color: new Color(0xff9e36).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  metalCeramicTooth: {
    color: new Color(0xffce3a).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  ceramicCrown: {
    color: new Color(0xff0233).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  ceramicTooth: {
    color: new Color(0xff4edc).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  metalCrown: {
    color: new Color(0xbbbbbb).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  metalTooth: {
    color: new Color(0x817f7f).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  pinToothCrown: {
    color: new Color(0xffef00).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  roseQuartz: {
    color: new Color(0xf7d1d6).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  lightBlue: {
    color: new Color(0xcbf7ff).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  orthoGum: {
    // color: new Color(0xcc8a93).convertGammaToLinear(gammaFactor),
    // roughness: 0.6,
    // metalness: 0.26,
    // emissive: 0xae5b5b,
    // emissiveIntensity: 0.43,
    // side: DoubleSide,
    color: new Color(0xac6870).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  orthoEnamel: {
    // color: new Color(0xfff9f0).convertGammaToLinear(gammaFactor),
    // roughness: 0.09,
    // metalness: 0.63,
    // emissive: 0xfff7f0,
    // emissiveIntensity: 0.52,
    // side: DoubleSide,
    color: new Color(0xffffff).convertGammaToLinear(gammaFactor),
    roughness: 0.2,
    metalness: 0.05,
    side: DoubleSide
  },
  polycarbonat: {
    color: new Color(0xffffff).convertGammaToLinear(gammaFactor),
    roughness: 0.12,
    metalness: 0.31,
    emissive: 0xe5f4ff,
    emissiveIntensity: 0.2,
    side: DoubleSide
  },
  glassBlue: {
    color: new Color(0xc2dfff).convertGammaToLinear(gammaFactor),
    roughness: 0.0,
    metalness: 0.85,
    emissive: 0xd1e8ff,
    emissiveIntensity: 0.72,
    side: DoubleSide
  },
  glassYellow: {
    color: new Color(0xfff9f0).convertGammaToLinear(gammaFactor),
    roughness: 0.0,
    metalness: 0.76,
    emissive: 0xfff8f0,
    emissiveIntensity: 0.64,
    side: DoubleSide
  },
  redGlossy: {
    color: new Color(0xf91a1a).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  violet: {
    color: new Color(0xcb9ade).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  darkViolet: {
    color: new Color(0xa6e9fc).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  sandyYellow: {
    color: new Color(0xc3bf6d).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  gold: {
    color: new Color(0xeec627).convertGammaToLinear(gammaFactor),
    roughness: 0.35,
    metalness: 1,
    side: DoubleSide
  },
  green: {
    color: new Color(0x4cbb17).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  blue: {
    color: new Color(0x87cefa).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  pink: {
    color: new Color(0xea899a).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  purple: {
    color: new Color(0xb664c0).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  black: {
    color: new Color(0x000000).convertGammaToLinear(gammaFactor),
    roughness: 0.6,
    metalness: 0.0,
    side: DoubleSide
  },
  orange: {
    color: new Color(0xe2b67b).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  },
  Vpurple: {
    color: new Color(0xeab9f0).convertGammaToLinear(gammaFactor),
    roughness: 0.65,
    metalness: 0.25,
    side: DoubleSide
  },
  Vgreen: {
    color: new Color(0xc4f28d).convertGammaToLinear(gammaFactor),
    roughness: 0.65,
    metalness: 0.25,
    side: DoubleSide
  },
  Vyellow: {
    color: new Color(0xfffacd).convertGammaToLinear(gammaFactor),
    roughness: 0.65,
    metalness: 0.25,
    side: DoubleSide
  },
  Vblue: {
    color: new Color(0x95e9fc).convertGammaToLinear(gammaFactor),
    roughness: 0.65,
    metalness: 0.25,
    side: DoubleSide
  },
  Vpink: {
    color: new Color(0xfca7d3).convertGammaToLinear(gammaFactor),
    roughness: 0.65,
    metalness: 0.25,
    side: DoubleSide
  },
  Vgrey: {
    color: new Color(0xdcdcdc).convertGammaToLinear(gammaFactor),
    roughness: 0.65,
    metalness: 0.25,
    side: DoubleSide
  },
  Vpeach: {
    color: new Color(0xffdab9).convertGammaToLinear(gammaFactor),
    roughness: 0.65,
    metalness: 0.25,
    side: DoubleSide
  },
  skin: {
    color: new Color(0xcbaab5).convertGammaToLinear(gammaFactor),
    roughness: 0.56,
    metalness: 0.07,
    side: DoubleSide
  },
  default: {
    color: new Color(0xe2b67b).convertGammaToLinear(gammaFactor),
    roughness: 0.3,
    metalness: 0.05,
    side: DoubleSide
  }
};
export default materials;
export const baseTypes = {
  gumTop: {
    x: 167,
    y: 122,
    width: 376,
    height: 381,
    position: "top",
    zIndex: 1
  },
  gumBottom: {
    x: 183,
    y: 682,
    width: 333,
    height: 317,
    position: "bottom",
    zIndex: 1
  },
  orthoGumTop: {
    x: 167,
    y: 122,
    width: 376,
    height: 381,
    position: "top",
    zIndex: 1
  },
  orthoGumBottom: {
    x: 183,
    y: 682,
    width: 333,
    height: 317,
    position: "bottom",
    zIndex: 1
  }
};
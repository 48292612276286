import { toothTypes } from "./tooth";
import { gapsTypes } from "./gaps";
export const generateFormulaTooth = baseTooth => {
  return toothTypes.map(el => {
    const itemForMerge = !baseTooth ? false : baseTooth.find(item => item.name === el.name);
    return { ...el,
      toothElementsActive: itemForMerge ? itemForMerge.toothElementsActive : el.toothElementsActive,
      toothSegments: itemForMerge ? itemForMerge.toothSegments : el.toothSegments
    };
  }).sort((a, b) => a.sort - b.sort);
};
export const generateFormulaGaps = baseGaps => {
  return gapsTypes.map(gap => {
    let targetEl = !baseGaps ? false : baseGaps.find(el => el.name === gap.name);

    if (targetEl) {
      return { ...gap,
        connection: { ...gap.connection,
          isActive: targetEl.connection
        },
        separation: { ...gap.separation,
          isActive: targetEl.separation
        },
        miniImplant: { ...gap.miniImplant,
          isActive: targetEl.miniImplant
        }
      };
    }

    return gap;
  });
};
export const generateFormulaProsthesis = baseProsthesis => {
  return {
    activeList: !baseProsthesis ? [] : baseProsthesis
  };
};
export const generateFormulaJointDisease = baseJointDisease => {
  return {
    activeList: !baseJointDisease ? [] : baseJointDisease
  };
};
export const generateFormulaMandibularCanal = baseMandibularCanal => {
  return {
    activeList: !baseMandibularCanal ? [] : baseMandibularCanal
  };
};
export const generateFormulaSinuses = baseSinuses => {
  return {
    activeList: !baseSinuses ? [] : baseSinuses
  };
};
export const generateFormulaMucosalDisease = baseMucosalDisease => {
  return baseMucosalDisease;
};
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stepFlow: [{ id: "PSP", formula: {} }],
  formulaBeforeTreatment: null,
  formulaType: "",
  // PSP
  userRole: "patient",
  patientGender: "man",
  patientFace: "face10",
  lastSavedUser: null,
  cardBasedOnId: null,
};

export const stepControlSlice = createSlice({
  name: "stepControl",
  initialState,
  reducers: {
    setStepFlow: (state, action) => {
      state.stepFlow = action.payload;
    },
    setFormulaBeforeTreatment: (state, action) => {
      state.formulaBeforeTreatment = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setPatientGender: (state, action) => {
      state.patientGender = action.payload;
    },
    setPatientFace: (state, action) => {
      state.patientFace = action.payload;
    },
    setLastSavedUser: (state, action) => {
      state.lastSavedUser = action.payload;
    },
    setFormulaType: (state, action) => {
      state.formulaType = action.payload;
    },
    setCardBasedOnId: (state, action) => {
      state.cardBasedOnId = action.payload;
    },
    resetStepControl: (state, action) => {
      state.stepFlow = initialState.stepFlow;
      state.formulaBeforeTreatment = initialState.formulaBeforeTreatment;
      state.formulaType = initialState.formulaType;
      state.userRole = initialState.userRole;
      state.patientGender = initialState.patientGender;
      state.patientFace = initialState.patientFace;
    },
  },
});

export const {
  setStepFlow,
  setFormulaBeforeTreatment,
  setUserRole,
  setPatientGender,
  setPatientFace,
  setLastSavedUser,
  setFormulaType,
  setCardBasedOnId,
  resetStepControl,
} = stepControlSlice.actions;

export default stepControlSlice.reducer;

export const previewCard = (data) => (dispatch) => {
  dispatch(setStepFlow(data));
};

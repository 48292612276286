import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowRightBar: false,
  rightBarKey: "",
  isShowBottomBar: false,
  bottomBarKey: "",
};

export const infoBarsSlice = createSlice({
  name: "infoBars",
  initialState,
  reducers: {
    toggleRightBarInfo: (state, action) => {
      state.isShowRightBar = action.payload.isShow;
      state.rightBarKey = action.payload.key;
    },
    toggleBottomBarInfo: (state, action) => {
      state.isShowBottomBar = action.payload.isShow;
      state.bottomBarKey = action.payload.key;
    },
  },
});

export const { toggleRightBarInfo, toggleBottomBarInfo } =
  infoBarsSlice.actions;

export default infoBarsSlice.reducer;

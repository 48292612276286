import { commonApi } from "./commonApi";

export const packagesApi = commonApi.injectEndpoints({
  tagTypes: ["PackageDetails"],
  endpoints: (builder) => ({
    getPackageDetails: builder.query({
      query: () => ({
        url: "/user/package",
      }),
      providesTags: ["PackageDetails"],
      transformResponse(response) {
        return response.data;
      },
    }),
    generateCheckout: builder.mutation({
      query: (packId) => ({
        url: "/user/generate_order",
        method: "POST",
        body: { type: packId },
      }),
    }),
  }),
});

export const { useGetPackageDetailsQuery, useGenerateCheckoutMutation } =
  packagesApi;

export function prepareTreatmentFormula(data) {
  return { ...data,
    tooth: data.tooth.map(el => {
      return { ...el,
        toothSegments: renameKeys(deleteKeys(el.toothSegments, ["cyst"]), {
          cariousTooth: "cariousToothPrepare"
        }),
        toothElementsActive: el.toothElementsActive.map(ea => {
          switch (ea) {
            case "cariousTooth":
              return "cariousToothPrepare";

            case "pulpitis":
            case "badSealedCanal":
            case "brokenToolCanal":
              return "openCanal";

            default:
              return ea;
          }
        })
      };
    })
  };
}
export function afterTreatmentFormula(data) {
  return { ...data,
    tooth: data.tooth.map(el => {
      return { ...el,
        toothSegments: renameKeys(deleteKeys(el.toothSegments, ["cyst"]), {
          cariousTooth: "therapyRestoration"
        }),
        toothElementsActive: el.toothElementsActive.reduce((sum, ea) => {
          switch (ea) {
            case "cariousTooth":
              return [...sum, "therapyRestoration"];

            case "pulpitis":
            case "badSealedCanal":
            case "brokenToolCanal":
              return [...sum, "treatedCanal", "therapyRestoration"];

            default:
              return [...sum, ea];
          }
        }, [])
      };
    })
  };
}
export function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return {
      [newKey]: obj[key]
    };
  });
  return Object.assign({}, ...keyValues);
}
export function deleteKeys(obj, keys) {
  let tmpObj = { ...obj
  };
  keys.forEach(key => {
    delete tmpObj[key];
  });
  return tmpObj;
}
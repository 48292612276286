import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function RequireAuth({ children }) {
  let isAuth = useSelector(({ auth }) => auth.isLoggedIn);
  let location = useLocation();

  if (!isAuth) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;

export const prosthesisActiveList = [];
export const prosthesisTypes = {
  clutchTop: {
    x: 167,
    y: 122,
    width: 376,
    height: 381,
    position: "top",
    zIndex: 1
  },
  clutchBottom: {
    x: 183,
    y: 682,
    width: 333,
    height: 317,
    position: "bottom",
    zIndex: 1
  },
  acrylicTop: {
    x: 177,
    y: 124,
    width: 363,
    height: 379,
    position: "top",
    zIndex: 1
  },
  acrylicBottom: {
    x: 187,
    y: 680,
    width: 330,
    height: 312,
    position: "bottom",
    zIndex: 1
  },
  totalTop: {
    x: 177,
    y: 124,
    width: 363,
    height: 379,
    position: "top",
    zIndex: 1
  },
  totalBottom: {
    x: 187,
    y: 680,
    width: 330,
    height: 312,
    position: "bottom",
    zIndex: 1
  },
  orthodonticPlateTop: {
    x: 163,
    y: 143,
    width: 377,
    height: 362,
    position: "top",
    zIndex: 1
  },
  orthodonticPlateBottom: {
    x: 182,
    y: 676,
    width: 340,
    height: 313,
    position: "bottom",
    zIndex: 1
  },
  elastopositioner: {
    x: 94,
    y: 77,
    width: 516,
    height: 963,
    position: "top",
    zIndex: 1
  },
  // Slides block
  slideSapphireBracesTop: {
    x: 82,
    y: 61,
    width: 538,
    height: 420,
    position: "top",
    zIndex: 1
  },
  slideSapphireBracesBottom: {
    x: 98,
    y: 664,
    width: 506,
    height: 394,
    position: "bottom",
    zIndex: 1
  },
  slideSelfAlignBracesTop: {
    x: 82,
    y: 61,
    width: 538,
    height: 420,
    position: "top",
    zIndex: 1
  },
  slideSelfAlignBracesBottom: {
    x: 98,
    y: 664,
    width: 506,
    height: 394,
    position: "bottom",
    zIndex: 1
  },
  slideCeramicBracesTop: {
    x: 82,
    y: 61,
    width: 538,
    height: 420,
    position: "top",
    zIndex: 1
  },
  slideCeramicBracesBottom: {
    x: 98,
    y: 664,
    width: 506,
    height: 394,
    position: "bottom",
    zIndex: 1
  },
  slideCeramicSelfAlignBracesTop: {
    x: 82,
    y: 61,
    width: 538,
    height: 420,
    position: "top",
    zIndex: 1
  },
  slideCeramicSelfAlignBracesBottom: {
    x: 98,
    y: 664,
    width: 506,
    height: 394,
    position: "bottom",
    zIndex: 1
  },
  slideMetallBracesTop: {
    x: 82,
    y: 61,
    width: 538,
    height: 420,
    position: "top",
    zIndex: 1
  },
  slideMetallBracesBottom: {
    x: 98,
    y: 665,
    width: 506,
    height: 394,
    position: "bottom",
    zIndex: 1
  },
  slideLingualBracesTop: {
    x: 185,
    y: 150,
    width: 332,
    height: 337,
    position: "top",
    zIndex: 1
  },
  slideLingualBracesBottom: {
    x: 198,
    y: 660,
    width: 308,
    height: 313,
    position: "bottom",
    zIndex: 1
  },
  slideMouthAlignerTop: {
    x: 94,
    y: 77,
    width: 516,
    height: 484,
    position: "top",
    zIndex: 1
  },
  slideMouthAlignerBottom: {
    x: 104,
    y: 597,
    width: 497,
    height: 442,
    position: "bottom",
    zIndex: 1
  },
  slideNoAlignSystemTop: {
    x: 75,
    y: 65,
    width: 263,
    height: 237,
    position: "top",
    zIndex: 1
  },
  slideNoAlignSystemBottom: {
    x: 68,
    y: 297,
    width: 277,
    height: 215,
    position: "bottom",
    zIndex: 1
  },
  // End slides block
  // UNUSED in 2d! But used in FormulaTotal
  nylonTop: {
    x: 75,
    y: 65,
    width: 263,
    height: 237,
    position: "top",
    zIndex: 1
  },
  nylonBottom: {
    x: 68,
    y: 297,
    width: 277,
    height: 215,
    position: "bottom",
    zIndex: 1
  },
  capaTop: {
    x: 91,
    y: 50,
    width: 235,
    height: 204,
    position: "top",
    zIndex: 1
  },
  capaBottom: {
    x: 91,
    y: 346,
    width: 234,
    height: 181,
    position: "bottom",
    zIndex: 1
  },
  elinerTop: {
    x: 91,
    y: 50,
    width: 235,
    height: 204,
    position: "top",
    zIndex: 1
  },
  elinerBottom: {
    x: 91,
    y: 346,
    width: 234,
    height: 181,
    position: "bottom",
    zIndex: 1
  },
  metalBracesTop: {
    x: 86,
    y: 46,
    width: 242,
    height: 193,
    position: "top",
    zIndex: 1
  },
  metalBracesBottom: {
    x: 89,
    y: 356,
    width: 238,
    height: 178,
    position: "bottom",
    zIndex: 1
  },
  ceramicBracesTop: {
    x: 86,
    y: 46,
    width: 242,
    height: 193,
    position: "top",
    zIndex: 1
  },
  ceramicBracesBottom: {
    x: 89,
    y: 356,
    width: 238,
    height: 178,
    position: "bottom",
    zIndex: 1
  },
  sapphireBracesTop: {
    x: 86,
    y: 46,
    width: 242,
    height: 193,
    position: "top",
    zIndex: 1
  },
  sapphireBracesBottom: {
    x: 89,
    y: 356,
    width: 238,
    height: 178,
    position: "bottom",
    zIndex: 1
  },
  plasticBracesTop: {
    x: 86,
    y: 46,
    width: 242,
    height: 193,
    position: "top",
    zIndex: 1
  },
  plasticBracesBottom: {
    x: 89,
    y: 356,
    width: 238,
    height: 178,
    position: "bottom",
    zIndex: 1
  },
  lingualBracesTop: {
    x: 124,
    y: 79,
    width: 169,
    height: 171,
    position: "top",
    zIndex: 9
  },
  lingualBracesBottom: {
    x: 129,
    y: 348,
    width: 157,
    height: 163,
    position: "bottom",
    zIndex: 9
  }
};
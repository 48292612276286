import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShow: false,
  video: "",
  title: "",
};

export const videoViewerSlice = createSlice({
  name: "videoViewer",
  initialState,
  reducers: {
    setVideoViewer: (state, action) => {
      state.isShow = action.payload.isShow;
      state.video = action.payload.video;
      state.title = action.payload.title;
    },
  },
});

export const { setVideoViewer } = videoViewerSlice.actions;

export default videoViewerSlice.reducer;

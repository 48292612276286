import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShow: false,
  images: [],
  index: "",
};

export const photoViewerSlice = createSlice({
  name: "photoViewer",
  initialState,
  reducers: {
    setPhotoViewer: (state, action) => {
      state.isShow = action.payload.isShow;
      state.images = action.payload.images;
      state.index = action.payload.index;
    },
  },
});

export const { setPhotoViewer } = photoViewerSlice.actions;

export default photoViewerSlice.reducer;

import DBLogo from "../assets/images/dentalbot_mini.png";
import { translate } from "../i18n"; // title: _t(" "",
// subTitle: "",
// subTitleIcon: require(),
// footerText: _t(), "",
// footerIcon: require(),
// rightBarAboutKey: null,
// nextKey: null,

export function getStepInfo(language, id, userRole = "") {
  const _t = key => translate(key, language);

  const isDoctor = userRole === "doctor";

  switch (id) {
    case "PSP":
      return {
        title: isDoctor ? _t("Выберите тип проблемы:") : _t("С какой проблемой Вы хотите обратиться к врачу?"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>"
      };

    case "PSP_ET_e1":
    case "PSP_EM_e1":
    case "PSP_OM_e1":
    case "PSP_TM_e1":
      return {
        title: _t("Сперва давайте обучимся работать с формулой зубов"),
        nextKey: "e2"
      };

    case "PSP_ET_e1_e2":
      return {
        title: _t("Давайте познакомимся с 3D формулой зубов"),
        footerText: _t("<b>3D Формула зубов</b> - это пространственная визуализация обеих челюстей с деснами и всеми зубами. Благодаря 3D формуле Вы сможете увидеть со всех сторон предлагаемые конструкции. Попробуйте изменить положение пространственной модели на экране движением пальца. Используйте меню справа для того, чтобы скрыть отдельную челюсть и отдельные компоненты."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        nextKey: "adenty"
      };

    case "PSP_EM_e1_e2":
      return {
        title: _t("Давайте познакомимся с 3D формулой зубов"),
        footerText: _t("<b>3D Формула зубов</b> - это пространственная визуализация обеих челюстей с деснами и всеми зубами. Благодаря 3D формуле Вы сможете увидеть со всех сторон предлагаемые конструкции. Попробуйте изменить положение пространственной модели на экране движением пальца. Используйте меню справа для того, чтобы скрыть отдельную челюсть и отдельные компоненты."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        nextKey: "estetic"
      };

    case "PSP_OM_e1_e2":
      return {
        title: _t("Давайте познакомимся с 3D формулой зубов"),
        footerText: _t("<b>3D Формула зубов</b> - это пространственная визуализация обеих челюстей с деснами и всеми зубами. Благодаря 3D формуле Вы сможете увидеть со всех сторон предлагаемые конструкции. Попробуйте изменить положение пространственной модели на экране движением пальца. Используйте меню справа для того, чтобы скрыть отдельную челюсть и отдельные компоненты."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        nextKey: "orthodonticSelect"
      };

    case "PSP_TM_e1_e2":
      return {
        title: _t("Давайте познакомимся с 3D формулой зубов"),
        footerText: _t("<b>3D Формула зубов</b> - это пространственная визуализация обеих челюстей с деснами и всеми зубами. Благодаря 3D формуле Вы сможете увидеть со всех сторон предлагаемые конструкции. Попробуйте изменить положение пространственной модели на экране движением пальца. Используйте меню справа для того, чтобы скрыть отдельную челюсть и отдельные компоненты."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        nextKey: "therapySelect"
      };

    case "PSP_ET_e1_e2_adenty":
      return {
        title: _t("Выберите степень адентии"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>",
        footerText: _t("<b>Адентия</b> - частичное или полное отсутствие зубов на челюсти. Может проявляться в любом возрасте. Может быть вызвано преждевременной потерей зубов, или своевременным не прорезыванием зубов."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png")
      };

    case "PSP_ET_e1_e2_adenty_partial":
      return {
        title: _t("Укажите в формуле отсутствующие зубы"),
        icon: isDoctor ? require("../mediaContent/StepIcon/adentiaPart.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Необходимо выбрать хотя бы один зуб в формуле") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Необходимо выбрать хотя бы один зуб в формуле"),
        footerText: _t("<b>Частичная адентия:</b> - частичное отсутствие зубов на челюсти."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "adentyPartial",
        // nextKey: "prosthesisSelect",
        nextKey: "decayedTeeth"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth":
      return {
        title: _t("Укажите в формуле разрушенные зубы"),
        icon: isDoctor ? require("../mediaContent/StepIcon/destroedTooth.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Разрушение коронковой части зуба</b> - это изменение формы и цвета коронки зуба. Данное стоматологическое заболевание  может развиваться вследствие травмы..."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "decayedTeeth",
        nextKey: "prostheticsPrepare"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare":
      return {
        title: _t("Подготовка полости рта к протезированию"),
        icon: isDoctor ? require("../mediaContent/StepIcon/toothPreper.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("Подготовка зубов к протезированию включает в себя терапевтические и ортопедические этапы, связанные с лечением корневых каналов и создание основы для будущих коронок или..."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "prostheticsPrepare",
        nextKey: "prosthesisSelect"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect":
      return {
        title: _t("Выберите тип протезирования"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation":
      return {
        title: _t("Укажите в формуле где будут установлены импланты"),
        icon: isDoctor ? require("../mediaContent/StepIcon/implantInstal.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Необходимо выбрать хотя бы один имплант в формуле") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Необходимо выбрать хотя бы один имплант в формуле"),
        footerText: _t("<b>Правила:</b> Импланты необходимо устанавливать в области отсутствующих зубов. Один имплант может нести на себе нагрузку соседнего зуба. Соответственно между имплантами может быть 1 или максимум 2 подвесных зуба."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "implantPosition",
        nextKey: "implantFixation"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation":
      return {
        title: _t("Выберите тип фиксации импланта к протезу"),
        icon: isDoctor ? require("../mediaContent/StepIcon/typeFixation.png") : DBLogo,
        subTitle: _t("<b>Информация:</b> Очень важным этапом планирования протезной конструкции на имплантах является выбор типа фиксации к импланту. Есть ряд методик, которые отличаются надежностью, эстетичностью и подбираются исходя из условий и в соответствии с клиническим случаем.") + "<br/>" + "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation":
      return {
        title: _t("Укажите в формуле где будут подвесные зубы между имплантами. Если после выборов импланта не осталось отсутствующих зубов, перейдите на следующий этап."),
        icon: isDoctor ? require("../mediaContent/StepIcon/implantPonticl.png") : DBLogo,
        // subTitle:
        //   "<div class='controls-heading'>" + _t("Необходимо указать зубы между коронками на имплантах") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Необходимо указать зубы между коронками на имплантах"),
        footerText: _t("<b>Информация:</b> Напоминаем, что между имплантами может находиться один или максимум 2 подвесных зуба. Если у Вас получилось больше, тогда вернитесь на пару шагов назад и установите дополнительные импланты."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "implantationSelectFalseTeeth",
        // nextKey: "implantationSelectFalseTeethConnection",
        nextKey: "extraCrownsSelect"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation_extraCrownsSelect":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation_extraCrownsSelect":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation_extraCrownsSelect":
      return {
        title: _t("Выберите дополнительные коронки. Если не требуется реставрация коронками других зубов, перейдите на следующий этап."),
        icon: isDoctor ? require("../mediaContent/StepIcon/aditionCrowns.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Коронки</b> - это несъемные зубные протезы, которые фиксируются на обточенные зубы для восстановления их формы и цвета."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esCrownsSelection",
        nextKey: "extraVeneersSelect"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation_extraCrownsSelect_extraVeneersSelect":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation_extraCrownsSelect_extraVeneersSelect":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation_extraCrownsSelect_extraVeneersSelect":
      return {
        title: _t("Выберите виниры. Если не требуется реставрация винирами других зубов, перейдите на следующий этап."),
        icon: isDoctor ? require("../mediaContent/StepIcon/veneers.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Виниры</b> – тонкие пластинки из керамики, которые фиксируются на передней части зуба и по цвету не отличаются от эмали."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esVeneersSelection",
        nextKey: "compositeRestorations" // nextKey: "implantationSelectFalseTeethConnection",

      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations":
      return {
        title: _t("Выбор композитных реставраций"),
        icon: isDoctor ? require("../mediaContent/StepIcon/Restavrations.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Композитные реставрации</b> - это поэтапное наслоение фотополимерного материала на зубы для восстановления их формы и цвета."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esRestorationsSelection",
        nextKey: "implantationSelectFalseTeethConnection"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection":
      return {
        title: _t("Укажите в формуле, где будут соединения подвесных зубов и коронок на имплантах. Если соединения не требуются для создания мостов, перейдите на следующий этап."),
        icon: isDoctor ? require("../mediaContent/StepIcon/implantConection.png") : DBLogo,
        // subTitle:
        //   "<div class='controls-heading'>" +
        //   _t("Необходимо указать везде, где коронка соединяется с подвесным зубом и подвесные зубы между собой") +
        //   "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Необходимо указать везде, где коронка соединяется с подвесным зубом и подвесные зубы между собой"),
        footerText: _t("<b>Информация:</b> Важно, чтобы каждый зуб на импланте был соединен с подвесным зубом и каждый подвесной зуб был соединен с рядом стоящим зубом"),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "implantationSelectFalseTeethConnection",
        nextKey: "finishStep"
      };

    case "PSP_ET_e1_e2_adenty_adentyTop":
    case "PSP_ET_e1_e2_adenty_adentyBottom":
    case "PSP_ET_e1_e2_adenty_adentyBoth":
      return {
        title: _t("Выберите тип протезирования"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant":
      let keys = id.split("_");
      return {
        title: _t("Укажите в формуле где будут установлены импланты"),
        icon: isDoctor ? require("../mediaContent/StepIcon/implantInstal.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Необходимо выбрать одну из двух схем установки имплантов") + "</div>",
        subTitleIcon: require("../assets/images/arrow_icon.png"),
        footerText: _t('<b>Правила:</b> Существуют 2 основных типа расстановки имплантов на беззубые челюсти: "все на шести" и "все на четырех". "Все на шести" подразумевает позиционирование имплантов в области 2-ых, 4-ых и 6-ых зубов в каждом сегменте. "Все на четырех" подразумевает позиционирование имплантов в  области 2-ых, 5-ых зубов в каждом сегменте.'),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: `adentyImplantProsthesisFixation_${keys[keys.length - 1]}`,
        nextKey: "adentyImplantProsthesisFixation"
      };

    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant4":
    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant6":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant4":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant6":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant4":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant6":
      return {
        title: _t("Выберите тип фиксации импланта к протезу"),
        icon: isDoctor ? require("../mediaContent/StepIcon/typeFixation.png") : DBLogo,
        subTitle: _t("<b>Информация:</b> Очень важным этапом планирования протезной конструкции на имплантах является выбор типа фиксации к импланту. Есть ряд методик, которые отличаются надежностью, эстетичностью и подбираются исходя из условий и в соответствии с клиническим случаем.") + "<br/><br/><div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics":
      return {
        title: _t("Выберите тип базиса протеза"),
        subTitle: _t("<b>Информация:</b> Базис съемного протеза соединяет все компоненты  конструкции, делает ее цельной и осуществляет опору на слизистую для равномерного распределения жевательной нагрузки. Существует два основных типов базисов протезов.") + "<br/><br/> <div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase":
      return {
        title: _t("Выберите тип протеза"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите челюсть, на которой будет металлический бюгельный протез.") + " <br/> " + _t("Необходимо выбрать ту челюсть, на которой отсутствуют зубы.") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase":
      return {
        title: _t("Выберите тип протеза"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите челюсть, на которой будет пластиковый пластинчатый протез.") + " <br/> " + _t("Необходимо выбрать ту челюсть, на которой отсутствуют зубы.") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseTop":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBottom":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBoth":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseTop":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBottom":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBoth":
      return {
        title: _t("Укажите где будут находиться зубы съемного протеза"),
        icon: isDoctor ? require("../mediaContent/StepIcon/toothProt.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Необходимо указать все отсутствующие зубы") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Необходимо указать все отсутствующие зубы"),
        footerText: _t("<b>Информация:</b> В конструкции съемного протеза нет ограничений в количестве подряд стоящих зубов в протезе. 18, 28, 38, 48 зубы не протезируются и не включаются в конструкцию."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "removableProstheticsTooth",
        nextKey: "extraCrownsSelect"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseTop_extraCrownsSelect":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBottom_extraCrownsSelect":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBoth_extraCrownsSelect":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseTop_extraCrownsSelect":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBottom_extraCrownsSelect":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBoth_extraCrownsSelect":
      return {
        title: _t("Выберите дополнительные коронки. Если не требуется реставрация коронками других зубов, перейдите на следующий этап."),
        icon: isDoctor ? require("../mediaContent/StepIcon/aditionCrowns.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Коронки</b> - это несъемные зубные протезы, которые фиксируются на обточенные зубы для восстановления их формы и цвета."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esCrownsSelection",
        // nextKey: "finishStep",
        nextKey: "compositeRestorations"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseTop_extraCrownsSelect_compositeRestorations":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBottom_extraCrownsSelect_compositeRestorations":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBoth_extraCrownsSelect_compositeRestorations":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseTop_extraCrownsSelect_compositeRestorations":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBottom_extraCrownsSelect_compositeRestorations":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBoth_extraCrownsSelect_compositeRestorations":
      return {
        title: _t("Выбор композитных реставраций"),
        icon: isDoctor ? require("../mediaContent/StepIcon/Restavrations.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Композитные реставрации</b> - это поэтапное наслоение фотополимерного материала на зубы для восстановления их формы и цвета."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esRestorationsSelection",
        nextKey: "finishStep"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_fixedProsthetics":
      return {
        title: _t("Укажите в формуле опорные зубы мостовидного протеза"),
        icon: isDoctor ? require("../mediaContent/StepIcon/brigueCrowns.png") : DBLogo,
        // subTitle:
        //   "<div class='controls-heading'>" + _t("Необходимо отметить в формуле зубы рядом с отсутствующими") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Необходимо отметить в формуле зубы рядом с отсутствующими"),
        footerText: _t("<b>Правила:</b> Мостовидный несъемный протез опирается на здоровые зубы с обеих сторон от дефекта. Необходимо отметить минимум 2 коронки рядом с отсутствующими зубами. Если за отсутствующими зубами нет зубов, которые можно отметить как опорные, значит данный вид протезирования Вам не подходит. Вернитесь на несколько шагов назад  при и выберите другой вид протезирования. Имплантацию или съемное протезирование."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "selectAbutmentTooth",
        nextKey: "suspendedToothSelect"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_fixedProsthetics_suspendedToothSelect":
      return {
        title: _t("Укажите в формуле, где будут подвесные зубы между коронками. Если после выбора коронок не осталось отсутствующих зубов, перейдите на следующий этап."),
        icon: isDoctor ? require("../mediaContent/StepIcon/briguePontics.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Необходимо указать зубы между коронками") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Необходимо указать зубы между коронками"),
        footerText: _t('<b>Информация:</b> Один естественный зуб может нести на себе нагрузку еще одного зуба. Соответственно между коронками на опорных зубах может быть 1 или максимум 2 подвесных зуба. Если у Вас получилось больше, тогда вернитесь на несколько шагов назад  при помощи кнопки "Назад" и выберите другой вид протезирования. Имплантацию или съемное протезирование.'),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "selectSuspendedTooth",
        // nextKey: "toothConnections",
        nextKey: "extraCrownsSelect"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_fixedProsthetics_suspendedToothSelect_extraCrownsSelect":
      return {
        title: _t("Выберите дополнительные коронки. Если не требуется реставрация коронками других зубов, перейдите на следующий этап."),
        icon: isDoctor ? require("../mediaContent/StepIcon/aditionCrowns.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Коронки</b> - это несъемные зубные протезы, которые фиксируются на обточенные зубы для восстановления их формы и цвета."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esCrownsSelection",
        nextKey: "extraVeneersSelect"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_fixedProsthetics_suspendedToothSelect_extraCrownsSelect_extraVeneersSelect":
      return {
        title: _t("Выберите виниры. Если не требуется реставрация винирами других зубов, перейдите на следующий этап."),
        icon: isDoctor ? require("../mediaContent/StepIcon/veneers.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Виниры</b> – тонкие пластинки из керамики, которые фиксируются на передней части зуба и по цвету не отличаются от эмали."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esVeneersSelection",
        nextKey: "compositeRestorations"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_fixedProsthetics_suspendedToothSelect_extraCrownsSelect_extraVeneersSelect_compositeRestorations":
      return {
        title: _t("Выбор композитных реставраций"),
        icon: isDoctor ? require("../mediaContent/StepIcon/Restavrations.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Композитные реставрации</b> - это поэтапное наслоение фотополимерного материала на зубы для восстановления их формы и цвета."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esRestorationsSelection",
        nextKey: "toothConnections"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_fixedProsthetics_suspendedToothSelect_extraCrownsSelect_extraVeneersSelect_compositeRestorations_toothConnections":
      return {
        title: _t("Укажите в формуле, где будут соединения подвесных зубов и коронок. Если соединения не требуются для создания мостов, перейдите на следующий этап."),
        icon: isDoctor ? require("../mediaContent/StepIcon/brigueConnections.png") : DBLogo,
        // subTitle:
        //   "<div class='controls-heading'>" +
        //   _t("Необходимо указать везде, где коронка соединяется с подвесным зубом и подвесные зубы между собой") +
        //   "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Необходимо указать везде, где коронка соединяется с подвесным зубом и подвесные зубы между собой"),
        footerText: _t("<b>Информация:</b> Важно, чтобы каждая коронка была соединена с подвесным зубом, и каждый подвесной зуб был соединен с рядом стоящим зубом. Только цельная конструкция с двумя или более опорными зубами может считаться зубным мостом."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "selectToothConnections",
        nextKey: "finishStep"
      };
    //  FINISH STEP

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection_finishStep":
      return {
        title: isDoctor ? _t("Протезная конструкция готова. Перейдите к сохранению предварительного плана лечения.") : _t("Протезная конструкция готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Протез на имплантах винтовой фиксации") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection_finishStep":
      return {
        title: isDoctor ? _t("Протезная конструкция готова. Перейдите к сохранению предварительного плана лечения.") : _t("Протезная конструкция готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Протез на имплантах цементной фиксации") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection_finishStep":
      return {
        title: isDoctor ? _t("Протезная конструкция готова. Перейдите к сохранению предварительного плана лечения.") : _t("Протезная конструкция готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Протез на имплантах с фиксацией на мульти-юнитах") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopGum":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomGum":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothGum":
      return {
        title: isDoctor ? _t("Протезная конструкция готова. Перейдите к сохранению предварительного плана лечения.") : _t("Протезная конструкция готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Полный съемный протез") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant4_cementImplantProsthesisFixationTop4":
    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant6_cementImplantProsthesisFixationTop6":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant4_cementImplantProsthesisFixationBottom4":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant6_cementImplantProsthesisFixationBottom6":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant4_cementImplantProsthesisFixationBoth4":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant6_cementImplantProsthesisFixationBoth6":
      return {
        title: isDoctor ? _t("Протезная конструкция готова. Перейдите к сохранению предварительного плана лечения.") : _t("Протезная конструкция готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Протез на имплантах цементной фиксации") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant4_multiunitImplantProsthesisFixationTop4":
    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant6_multiunitImplantProsthesisFixationTop6":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant4_multiunitImplantProsthesisFixationBottom4":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant6_multiunitImplantProsthesisFixationBottom6":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant4_multiunitImplantProsthesisFixationBoth4":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant6_multiunitImplantProsthesisFixationBoth6":
      return {
        title: isDoctor ? _t("Протезная конструкция готова. Перейдите к сохранению предварительного плана лечения.") : _t("Протезная конструкция готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Протез на имплантах с фиксацией на мульти-юнитах") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant4_telescopImplantProsthesisFixationTop4":
    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant6_telescopImplantProsthesisFixationTop6":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant4_telescopImplantProsthesisFixationBottom4":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant6_telescopImplantProsthesisFixationBottom6":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant4_telescopImplantProsthesisFixationBoth4":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant6_telescopImplantProsthesisFixationBoth6":
      return {
        title: isDoctor ? _t("Протезная конструкция готова. Перейдите к сохранению предварительного плана лечения.") : _t("Протезная конструкция готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Съемный протез на имплантах с фиксацией на телескопических коронках") + "</div>"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseTop_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBottom_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBoth_extraCrownsSelect_compositeRestorations_finishStep":
      return {
        title: isDoctor ? _t("Протезная конструкция готова. Перейдите к сохранению предварительного плана лечения.") : _t("Протезная конструкция готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Бюгельный cъемный протез с фиксацией на опорные зубы") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseTop_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBottom_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBoth_extraCrownsSelect_compositeRestorations_finishStep":
      return {
        title: isDoctor ? _t("Протезная конструкция готова. Перейдите к сохранению предварительного плана лечения.") : _t("Протезная конструкция готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Пластинчатый съемный протез с фиксацией на опорные зубы") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_fixedProsthetics_suspendedToothSelect_extraCrownsSelect_extraVeneersSelect_compositeRestorations_toothConnections_finishStep":
      return {
        title: isDoctor ? _t("Протезная конструкция готова. Перейдите к сохранению предварительного плана лечения.") : _t("Протезная конструкция готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Мостовидный протез цементной фиксации") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic":
      return {
        title: _t("Выберите в каком режиме Вы хотите планировать реставрации."),
        subTitle: "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>"
      };

    case "PSP_EM_e1_e2_estetic_esCustomVeneers":
      return {
        title: _t("Выбор виниров"),
        icon: isDoctor ? require("../mediaContent/StepIcon/veneers.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Виниры</b> – тонкие пластинки из керамики, которые фиксируются на передней части зуба и по цвету не отличаются от эмали."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esVeneersSelection",
        nextKey: "esCustomCrowns"
      };

    case "PSP_EM_e1_e2_estetic_esCustomVeneers_esCustomCrowns":
      return {
        title: _t("Выбор коронок"),
        icon: isDoctor ? require("../mediaContent/StepIcon/aditionCrowns.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Коронки</b> - это несъемные зубные протезы, которые фиксируются на обточенные зубы для восстановления их формы и цвета."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esCrownsSelection",
        nextKey: "esCustomRestorations"
      };

    case "PSP_EM_e1_e2_estetic_esCustomVeneers_esCustomCrowns_esCustomRestorations":
      return {
        title: _t("Выбор композитных реставраций"),
        icon: isDoctor ? require("../mediaContent/StepIcon/Restavrations.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Укажите в формуле кликом на зуб") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("<b>Композитные реставрации</b> - это поэтапное наслоение фотополимерного материала на зубы для восстановления их формы и цвета."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "esRestorationsSelection",
        nextKey: "smileSelect"
      };

    case "PSP_EM_e1_e2_estetic_esCustomVeneers_esCustomCrowns_esCustomRestorations_smileSelect":
      return {
        title: _t("Проверка типа улыбки"),
        subTitle: "<div class='controls-heading'>" + _t("Необходимо выбрать одну из четырех схем") + "</div>",
        subTitleIcon: require("../assets/images/arrow_icon.png"),
        nextKey: "finishStep"
      };

    case "PSP_EM_e1_e2_estetic_esCustomVeneers_esCustomCrowns_esCustomRestorations_smileSelect_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Выбранные реставрации") + "</div>",
        subTitleIcon: require("../assets/images/arrow_icon.png"),
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect":
      return {
        title: _t("Выберите какие зубы видны при улыбке"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical6Top":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical8Top":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical10Top":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypicalBottom":
      return {
        title: _t("Выберите тип реставрации"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>"
      };

    case "PSP_OM_e1_e2_orthodonticSelect":
      return {
        title: _t("Выберите раздел"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>",
        footerText: _t(""),
        footerIcon: require("../assets/images/keep_attention_red_icon.png")
      };

    case "PSP_OM_e1_e2_orthodonticSelect_orthodonticAnomalies":
      return {
        title: _t("Визуализации лечения аномалий прикуса"),
        subTitle: "<div class='controls-heading'>" + _t("Кликните на раздел, чтобы просмотреть") + "</div>",
        footerText: _t(""),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        nextKey: "rewrite",
        rewriteKey: "PSP_OM_e1_e2_orthodonticSelect_orthodonticTreatment"
      };

    case "PSP_OM_e1_e2_orthodonticSelect_orthodonticTreatment":
      return {
        title: _t("Выберите раздел"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>",
        footerText: _t(""),
        footerIcon: require("../assets/images/keep_attention_red_icon.png")
      };

    case "PSP_OM_e1_e2_orthodonticSelect_orthodonticTreatment_orthoChildTreatment":
      return {
        title: _t("Выберите раздел"),
        subTitle: "<div class='controls-heading'>" + _t("Выберите из списка ниже") + "</div>",
        footerText: _t("Основным способом лечения детских зубочелюстных аномалий является аппаратное лечение при помощи одночелюстных и двухчелюстных ортодонтических устройств."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "orthoChildTreatment",
        nextKey: "finishStep"
      };

    case "PSP_OM_e1_e2_orthodonticSelect_orthodonticTreatment_orthoBasicTreatment":
      return {
        title: _t("Выберите вид ортодонтического лечения"),
        subTitle: "",
        footerText: "",
        footerIcon: "",
        nextKey: "finishStep"
      };

    case "PSP_lowSinus":
      return {
        title: _t("Укажите зону с малым количеством кости в области синусов"),
        subTitle: "<div class='controls-heading'>" + _t("Необходимо выбрать одну из четырех схем") + "</div>",
        footerText: _t("Установка имплантов на верхнюю челюсть в области гайморовых пазух возможна только в случаях, если толщина кости не менее 8 мм."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "lowSinus",
        nextKey: "sinusLifting"
      };

    case "PSP_lowSinus_sinusLifting":
      return {
        title: _t("Выберите где будет применяться синус-лифтинг"),
        subTitle: "<div class='controls-heading'>" + _t("Необходимо выбрать одну из четырех схем") + "</div>",
        footerText: _t("<b>Синус-лифтинг</b> - это восстановление объема костной ткани, необходимого для успешной и безопасной имплантации."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "sinusLifting",
        nextKey: "boneAugmentation"
      };

    case "PSP_lowSinus_sinusLifting_boneAugmentation":
      return {
        title: _t("Укажите где будет проводиться костная аугментация"),
        // subTitle: "<div class='controls-heading'>" + _t("Необходимо выбрать хотя бы один зуб в формуле") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Укажите в формуле кликом на зуб"),
        footerText: _t("При недостаточном количестве кости, на верхней и на нижней челюсти в области отсутствующих зубов используется процедура <b>внешнего наращивания кости или костная аугментация.</b>"),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "boneAugmentation",
        nextKey: "finishStep"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical6Top_esTypicalVeneers_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Виниры на шести передних зубах верхней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical8Top_esTypicalVeneers_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Виниры на восьми передних зубах верхней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical10Top_esTypicalVeneers_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Виниры на десяти передних зубах верхней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypicalBottom_esTypicalVeneers_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Виниры на десяти передних зубах верхней челюсти десяти передних зубах нижней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical6Top_esTypicalCrowns_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Коронки на шести передних зубах верхней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical8Top_esTypicalCrowns_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Коронки на восьми передних зубах верхней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical10Top_esTypicalCrowns_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Коронки на десяти передних зубах верхней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypicalBottom_esTypicalCrowns_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Коронки на десяти передних зубах верхней челюсти и десяти передних зубах нижней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical6Top_esTypicalRestorations_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Композитные реставрации на шести передних зубах верхней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical8Top_esTypicalRestorations_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Композитные реставрации на восьми передних зубах верхней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical10Top_esTypicalRestorations_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Композитные реставрации на десяти передних зубах верхней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypicalBottom_esTypicalRestorations_finishStep":
      return {
        title: isDoctor ? _t("План реставрации готов. Перейдите к сохранению результата консультации.") : _t("План реставрации готов. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "<div class='controls-heading'>" + _t("Композитные реставрации на десяти передних зубах верхней челюсти и десяти передних зубах нижней челюсти") + "</div>",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_OM_e1_e2_orthodonticSelect_orthodonticTreatment_orthoChildTreatment_singleJawApparate_finishStep":
    case "PSP_OM_e1_e2_orthodonticSelect_orthodonticTreatment_orthoChildTreatment_doubleJawApparate_finishStep":
      return {
        title: isDoctor ? _t("План ортодонтического лечения готов. Перейдите к сохранению результата консультации.") : _t("Визуализация методики ортодонтического лечения готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_OM_e1_e2_orthodonticSelect_orthodonticTreatment_orthoBasicTreatment_finishStep":
      return {
        title: isDoctor ? _t("План ортодонтического лечения готов. Перейдите к сохранению результата консультации.") : _t("Визуализация методики ортодонтического лечения готова. Покажите Вашему доктору."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_lowSinus_sinusLifting_boneAugmentation_finishStep":
      return {
        title: _t("План лечения готов. Перейдите к сохранению результата консультации."),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        subTitle: "",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_TM_e1_e2_therapySelect":
      return {
        title: _t("Результаты осмотра полости рта"),
        icon: isDoctor ? require("../mediaContent/StepIcon/sonation.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Необходимо выбрать хотя бы один зуб в формуле") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Кликните на зуб в формуле"),
        footerText: _t("В результате осмотра полости рта пациента могут быть выявлены такие заболевания зубов как кариес или пульпит…"),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "sanitation",
        nextKey: "toothDetailing"
      };

    case "PSP_TM_e1_e2_therapySelect_toothDetailing":
      return {
        title: _t("Детализация зон поражения зубов"),
        icon: isDoctor ? require("../mediaContent/StepIcon/detalisation.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Необходимо выбрать хотя бы один зуб в формуле") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Кликните на зуб в формуле"),
        footerText: _t("Кариес может поражать все видимые и невидимые части зуба и поэтому классифицируются несколько видов кариеса…"),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "affectedAreas",
        nextKey: "rootDetailing"
      };

    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing":
      return {
        title: _t("Воспаления на корнях"),
        icon: isDoctor ? require("../mediaContent/StepIcon/rootGranyl.png") : DBLogo,
        // subTitle: "<div class='controls-heading'>" + _t("Необходимо выбрать хотя бы один зуб в формуле") + "</div>",
        // subTitleIcon: require("../../assets/images/arrow_icon.png"),
        formulaHelperText: _t("Кликните на зуб в формуле"),
        footerText: _t("Периодонтит – это воспаление тканей, окружающих корень зуба…"),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "rootsInflammation",
        nextKey: "clinicalView"
      };

    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView":
      return {
        title: _t("Клиническая ситуация"),
        icon: isDoctor ? require("../mediaContent/StepIcon/clinicCase.png") : DBLogo,
        // footerText: _t("!!!Описание"),
        // footerIcon: require("../../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "",
        nextTitle: _t("Лечение"),
        nextKey: "autoTreatment"
      };

    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment":
      return {
        title: _t("Предложенное лечение"),
        icon: isDoctor ? require("../mediaContent/StepIcon/treatment.png") : DBLogo,
        footerText: _t("Лечение зубов – это в первую очередь лечение кариеса, а также его осложнений: пульпита и периодонтита…"),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "suggestedTreatment",
        nextKey: "treatmentView"
      };

    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment_treatmentView":
      return {
        title: _t("Предложенное лечение"),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        footerText: _t("Для предотвращения болезней зубов в будущем необходимо придерживаться правил профилактики ротовой полости..."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "saveTherapy",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment_treatmentView_restorationSelect":
      return {
        title: _t("Выбор дополнительных реставраций"),
        icon: isDoctor ? require("../mediaContent/StepIcon/Restavrations.png") : DBLogo,
        formulaHelperText: _t("Выберите зуб в формуле"),
        footerText: _t("Для улучшения эстетики зубов в зоне улыбки могут применяться эстетические композитные реставрации на зубах, которые находятся рядом с пролеченными…"),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "additionalRestorations",
        nextKey: "restorationDetailing"
      };

    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment_treatmentView_restorationSelect_restorationDetailing":
      return {
        title: _t("Уточнение зон реставраций"),
        icon: isDoctor ? require("../mediaContent/StepIcon/detalisationRestavrations.png") : DBLogo,
        formulaHelperText: _t("Выберите зуб в формуле"),
        footerText: _t("Композитные реставрации - это поэтапное наслоение фотополимерного материала на зубы для восстановления их формы и цвета."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "refinementRestorationAreas",
        nextKey: "canalFilling"
      };

    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment_treatmentView_restorationSelect_restorationDetailing_canalFilling":
      return {
        title: _t("Пломбировка каналов"),
        icon: isDoctor ? require("../mediaContent/StepIcon/toothPreper.png") : DBLogo,
        formulaHelperText: _t("Выберите зуб в формуле"),
        footerText: _t("Пломбирование корневых каналов может применяться на здоровых зубах в случаях, когда на эти зубы планируется установка коронок…"),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "canalFillingTerapy",
        nextKey: "finishStep"
      };

    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment_treatmentView_restorationSelect_restorationDetailing_canalFilling_finishStep":
      return {
        title: _t("Предложенное лечение"),
        icon: isDoctor ? require("../mediaContent/StepIcon/Save.png") : DBLogo,
        footerText: _t("Для предотвращения болезней зубов в будущем необходимо придерживаться правил профилактики ротовой полости..."),
        footerIcon: require("../assets/images/keep_attention_red_icon.png"),
        rightBarAboutKey: "saveTherapy",
        nextTitle: _t("Сохранение"),
        nextKey: "save"
      };

    default:
      return "";
  }
}
import React, { useState } from "react";
import "./MainTemplate.scss";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import IconCopy from "@mui/icons-material/FileCopy";
import {
  Basic2D,
  generateFormulaTooth,
  generateFormulaGaps,
} from "@dentalbot/core";
import { useTranslation } from "react-i18next";
import "./CardsSection.scss";
import arrowRightIcon from "./assets/arrowRight.png";
import { TreatmentPlanBtn } from "../Auth/ActionBtns";
import { useGetCardsQuery } from "../../redux/cardsApi";
import LazySpinner from "../LazySpinner";
import { format, parseISO } from "date-fns";
import { Link as RouterLink } from "react-router-dom";

function CardsListItem({ card }) {
  const { t: _t } = useTranslation();

  function copyPreviewLink(url) {
    navigator.clipboard.writeText(url);
  }

  const previewLink = card?.preview_link
    ? `${process.env.REACT_APP_CARD_PREVIEW_URL}/${card.preview_link}`
    : "";

  return (
    <Card>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            sx={{ fontSize: 14, fontWeight: "bold" }}
            color="text.secondary"
          >
            {_t("Plan No.")} {card.id}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {format(parseISO(card.created_at), "HH:mm dd-MM-yyyy")}
          </Typography>
        </Stack>
        <Typography variant="h6" component="div">
          {`${card.user_lastname} ${card.user_firstname}`}
        </Typography>
        <Stack direction="row" alignItems="center">
          {card.formula_before_treatment && (
            <Basic2D
              targetWidth={139}
              formulaInformation={{
                tooth: generateFormulaTooth(
                  card.formula_before_treatment.tooth
                ),
                gaps: generateFormulaGaps(card.formula_before_treatment.gaps),
                prosthesis: card.formula_before_treatment.prosthesis,
              }}
              type={card.formula_type}
            />
          )}
          {card.formula_before_treatment && (
            <img
              src={arrowRightIcon}
              style={{ display: "block", width: 46, height: "auto" }}
              alt=""
            />
          )}
          <Basic2D
            targetWidth={card.formula_before_treatment ? 139 : 230}
            formulaInformation={{
              tooth: generateFormulaTooth(card.formula.tooth),
              gaps: generateFormulaGaps(card.formula.gaps),
              prosthesis: card.formula.prosthesis,
            }}
            type={card.formula_type}
          />
        </Stack>
        {card.preview_link && (
          <Box sx={{ width: "100%", mb: 2 }}>
            <TextField
              label={_t("Preview link")}
              id="filled-start-adornment"
              margin="dense"
              fullWidth
              readOnly
              focused
              defaultValue={previewLink}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => copyPreviewLink(previewLink)}
                      edge="end"
                      size="large"
                    >
                      <IconCopy />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size="small"
              variant="outlined"
            />
            <Button
              color="primary"
              variant="contained"
              fullWidth
              href={previewLink}
              target="_blank"
            >
              {_t("Preview")}
            </Button>
          </Box>
        )}
        {/*<Typography>{_t("Documents:")}</Typography>*/}
      </CardContent>
    </Card>
  );
}

function CardsList() {
  const { t: _t } = useTranslation();

  const [page, setPage] = useState(1);
  const { data: cards, isLoading, isFetching } = useGetCardsQuery(page);

  if (isLoading) {
    return (
      <Grid container sx={{ mt: 3 }}>
        <Box display="flex" justifyContent="center" sx={{ mx: "auto" }}>
          <LazySpinner />
        </Box>
      </Grid>
    );
  }

  if (!cards?.data) {
    return (
      <Grid container sx={{ mt: 3 }}>
        <Box display="flex" justifyContent="center" sx={{ mx: "auto" }}>
          <Alert severity="info">
            <AlertTitle>{_t("You don't have any saved card now!")}</AlertTitle>
            {_t(
              "Please use `Create treatment plan` button to create new card!"
            )}
          </Alert>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid container sx={{ mt: 3 }} spacing={2}>
      {cards.data.map((card) => (
        <Grid item key={card.id} xs={6} md={4}>
          <CardsListItem card={card} />
        </Grid>
      ))}
    </Grid>
  );
}

function CardsSection() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
        <TreatmentPlanBtn />
      </Grid>
      <CardsList />
    </Grid>
  );
}

export default CardsSection;

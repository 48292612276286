const slideToothTop = [{
  counter: 10,
  item: "tooth",
  name: "_11",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_12",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_13",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_14",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_15",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_16",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_17",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_21",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_22",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_23",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_24",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_25",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_26",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_27",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["top", "base"]
}];
const slideToothBottom = [{
  counter: 10,
  item: "tooth",
  name: "_31",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_32",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_33",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_34",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_35",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_36",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_37",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_41",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_42",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_43",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_44",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_45",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_46",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}, {
  counter: 10,
  item: "tooth",
  name: "_47",
  material: "orthoEnamel",
  opacity: 100,
  groups: ["bottom", "base"]
}]; // Present record equals empty file

export const emptyToothStlFiles = {
  sectionTooth_02: ["11", "12", "13", "15", "21", "22", "23", "25", "31", "32", "33", "34", "35", "41", "42", "43", "44", "45"],
  sectionTooth_03: ["11", "12", "13", "14", "15", "21", "22", "23", "24", "25", "31", "32", "33", "34", "35", "36", "37", "38", "41", "42", "43", "44", "45", "46", "47", "48"],
  cyst_2: ["11", "12", "13", "15", "21", "22", "23", "25", "31", "32", "33", "34", "35", "41", "42", "43", "44", "45"],
  cyst_3: ["11", "12", "13", "14", "15", "21", "22", "23", "24", "25", "31", "32", "33", "34", "35", "36", "37", "38", "41", "42", "43", "44", "45", "46", "47", "48"]
};
export const formulaToothStl = {
  healthyTooth: {
    files: [// { item: "healthyTooth", material: "enamel", renderOrder: 1, opacity: 100, groups: ["base", "skull"] },
    {
      item: "healthyToothCrown",
      material: "enamel",
      renderOrder: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "healthyToothRoot",
      material: "enamelRoot",
      renderOrder: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_01",
      material: "enamelRoot",
      opacity: 0,
      renderOrder: 1,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_02",
      material: "enamelRoot",
      opacity: 0,
      renderOrder: 1,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_03",
      material: "enamelRoot",
      opacity: 0,
      renderOrder: 1,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_1",
      material: "enamel",
      opacity: 0,
      renderOrder: 1,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_2",
      material: "enamel",
      opacity: 0,
      renderOrder: 1,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_3",
      material: "enamel",
      opacity: 0,
      renderOrder: 1,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_4",
      material: "enamel",
      opacity: 0,
      renderOrder: 1,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_5",
      material: "enamel",
      opacity: 0,
      renderOrder: 1,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_6",
      material: "enamel",
      opacity: 0,
      renderOrder: 1,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_7",
      material: "enamel",
      opacity: 0,
      renderOrder: 1,
      groups: ["base", "skull"]
    }],
    rules: []
  },
  emptyTooth: {
    files: [{
      item: "emptyTooth",
      material: "gum",
      opacity: 50,
      renderOrder: 2,
      groups: ["base", "softTissue"]
    }, {
      item: "corticalBoneImplants",
      material: "bone",
      opacity: 20,
      renderOrder: 2,
      groups: ["base", "skull"]
    }],
    rules: [{
      item: "individualAbutmentCrowns",
      material: "aluminium",
      opacity: 0
    }]
  },
  decayedTooth: {
    files: [{
      item: "decayedToothRoot",
      material: "enamelRoot",
      opacity: 100,
      renderOrder: 2,
      groups: ["base", "skull"]
    }, {
      item: "decayedToothTop",
      material: "black",
      opacity: 100,
      renderOrder: 2,
      groups: ["base", "skull"]
    }],
    rules: []
  },
  oldEsteticTooth: {
    files: [{
      item: "oldEsteticTooth",
      material: "enamelRoot",
      opacity: 100,
      groups: ["base", "skull"]
    }],
    rules: []
  },
  newEsteticTooth: {
    files: [// {
    //   item: "newEsteticTooth",
    //   material: "enamelSynthetic",
    //   opacity: 100,
    //   groups: ["base", "fixedProstheses", "skull"],
    // },
    {
      item: "healthyToothCrown",
      material: "enamelSynthetic",
      opacity: 100,
      groups: ["base", "fixedProstheses", "skull"]
    }],
    rules: []
  },
  plasticCrown: {
    files: [{
      item: "crown",
      material: "enamelSynthetic",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "preparedTooth",
      material: "enamelRoot",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "screwFixationCrowns",
      material: "enamelSynthetic",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "individualAbutmentCrowns",
      material: "enamelSynthetic",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: [{
      item: "standardAbutment",
      opacity: 100
    }, // { item: "healthyTooth", material: "enamel", opacity: 0, importance: 3 },
    {
      item: "healthyToothCrown",
      material: "enamel",
      opacity: 0,
      importance: 3
    }, {
      item: "healthyToothRoot",
      material: "enamel",
      opacity: 0,
      importance: 3
    }, {
      item: "multiunitScrew",
      opacity: 100
    }, {
      item: "multiunitPlatform",
      opacity: 100
    }]
  },
  plasticTooth: {
    files: [{
      item: "syntheticTooth",
      material: "enamelSynthetic",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "emptyTooth",
      material: "gum",
      opacity: 50,
      groups: ["base", "softTissue"]
    }, {
      item: "corticalBoneImplants",
      material: "bone",
      opacity: 20,
      renderOrder: 2,
      groups: ["base", "skull"]
    }, {
      item: "screwFixationCrowns",
      material: "enamelSynthetic",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "individualAbutmentCrowns",
      material: "enamelSynthetic",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: []
  },
  zirconiumCrown: {
    files: [{
      item: "crown",
      material: "zirconiumCrown",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "preparedTooth",
      material: "enamel",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "screwFixationCrowns",
      material: "zirconiumCrown",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "individualAbutmentCrowns",
      material: "zirconiumCrown",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: [{
      item: "standardAbutment",
      opacity: 100
    }, // { item: "healthyTooth", material: "enamel", opacity: 0 },
    {
      item: "healthyToothCrown",
      material: "enamel",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      material: "enamel",
      opacity: 0
    }, {
      item: "multiunitScrew",
      opacity: 100
    }, {
      item: "multiunitPlatform",
      opacity: 100
    }]
  },
  zirconiumTooth: {
    files: [{
      item: "syntheticTooth",
      material: "zirconiumTooth",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "emptyTooth",
      material: "gum",
      opacity: 50,
      groups: ["base", "softTissue"]
    }, {
      item: "corticalBoneImplants",
      material: "bone",
      opacity: 20,
      renderOrder: 2,
      groups: ["base", "skull"]
    }, {
      item: "screwFixationCrowns",
      material: "zirconiumTooth",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "individualAbutmentCrowns",
      material: "zirconiumTooth",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: []
  },
  metalCeramicCrown: {
    files: [{
      item: "crown",
      material: "metalCeramicCrown",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "preparedTooth",
      material: "enamel",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "screwFixationCrowns",
      material: "metalCeramicCrown",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "individualAbutmentCrowns",
      material: "metalCeramicCrown",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: [{
      item: "standardAbutment",
      opacity: 100
    }, // { item: "healthyTooth", material: "enamel", opacity: 0 },
    {
      item: "healthyToothCrown",
      material: "enamel",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      material: "enamel",
      opacity: 0
    }, {
      item: "multiunitScrew",
      opacity: 100
    }, {
      item: "multiunitPlatform",
      opacity: 100
    }]
  },
  metalCeramicTooth: {
    files: [{
      item: "syntheticTooth",
      material: "metalCeramicTooth",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "emptyTooth",
      material: "gum",
      opacity: 50,
      groups: ["base", "softTissue"]
    }, {
      item: "corticalBoneImplants",
      material: "bone",
      opacity: 20,
      renderOrder: 2,
      groups: ["base", "skull"]
    }, {
      item: "screwFixationCrowns",
      material: "metalCeramicTooth",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "individualAbutmentCrowns",
      material: "metalCeramicTooth",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: []
  },
  ceramicCrown: {
    files: [{
      item: "crown",
      material: "ceramicCrown",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "preparedTooth",
      material: "enamel",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "screwFixationCrowns",
      material: "ceramicCrown",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "individualAbutmentCrowns",
      material: "ceramicCrown",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: [{
      item: "standardAbutment",
      opacity: 100
    }, // { item: "healthyTooth", material: "enamel", opacity: 0 },
    {
      item: "healthyToothCrown",
      material: "enamel",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      material: "enamel",
      opacity: 0
    }, {
      item: "multiunitScrew",
      opacity: 100
    }, {
      item: "multiunitPlatform",
      opacity: 100
    }]
  },
  ceramicTooth: {
    files: [{
      item: "syntheticTooth",
      material: "ceramicTooth",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "emptyTooth",
      material: "gum",
      opacity: 50,
      groups: ["base", "softTissue"]
    }, {
      item: "corticalBoneImplants",
      material: "bone",
      opacity: 20,
      renderOrder: 2,
      groups: ["base", "skull"]
    }, {
      item: "screwFixationCrowns",
      material: "ceramicTooth",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "individualAbutmentCrowns",
      material: "ceramicTooth",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: []
  },
  metalCrown: {
    files: [{
      item: "crown",
      material: "metalCrown",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "preparedTooth",
      material: "enamel",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "screwFixationCrowns",
      material: "metalCrown",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "individualAbutmentCrowns",
      material: "metalCrown",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: [{
      item: "standardAbutment",
      opacity: 100
    }, // { item: "healthyTooth", material: "enamel", opacity: 0 },
    {
      item: "healthyToothCrown",
      material: "enamel",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      material: "enamel",
      opacity: 0
    }, {
      item: "multiunitScrew",
      opacity: 100
    }, {
      item: "multiunitPlatform",
      opacity: 100
    }]
  },
  metalTooth: {
    files: [{
      item: "syntheticTooth",
      material: "metalTooth",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "emptyTooth",
      material: "gum",
      opacity: 50,
      groups: ["base", "softTissue"]
    }, {
      item: "corticalBoneImplants",
      material: "bone",
      opacity: 20,
      renderOrder: 2,
      groups: ["base", "skull"]
    }, {
      item: "screwFixationCrowns",
      material: "metalTooth",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "individualAbutmentCrowns",
      material: "metalTooth",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: []
  },
  pinTooth: {
    files: [{
      item: "crown",
      material: "pinToothCrown",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "stumpTabTooth",
      material: "enamelRoot",
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "stumpTab",
      material: "aluminium",
      opacity: 100,
      groups: ["fixedProstheses"]
    }],
    rules: []
  },
  overPress: {
    files: [{
      item: "emptyTooth",
      material: "gum",
      opacity: 50,
      groups: ["base", "softTissue"]
    }, {
      item: "overPress",
      material: "bone",
      opacity: 100,
      groups: ["abutments"]
    }, {
      item: "crown",
      material: "ceramicCrown",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: []
  },
  veneerTab: {
    files: [{
      item: "reducedTooth",
      material: "enamelRoot",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "veneerTab",
      material: "enamelSynthetic",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: [// { item: "healthyTooth", material: "enamel", opacity: 0, importance: 3 },
    {
      item: "healthyToothCrown",
      material: "enamel",
      opacity: 0,
      importance: 3
    }, {
      item: "healthyToothRoot",
      material: "enamel",
      opacity: 0,
      importance: 3
    }]
  },
  stumpTabs: {
    files: [{
      item: "stumpTabTooth",
      material: "enamelRoot",
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "stumpTab",
      material: "steel",
      opacity: 100,
      groups: ["base", "skull", "stumpTabs"]
    }],
    rules: [// { item: "healthyTooth", material: "enamel", opacity: 0 },
    {
      item: "healthyToothCrown",
      material: "enamel",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      material: "enamel",
      opacity: 0
    }, {
      item: "preparedTooth",
      material: "enamel",
      opacity: 0
    }]
  },
  implantPosition: {
    files: [{
      item: "implantPosition",
      material: "steel",
      opacity: 100,
      disableSmoothing: false,
      groups: ["implants", "skull"]
    }, {
      item: "implantsGum",
      material: "gum",
      opacity: 50,
      renderOrder: 2,
      groups: ["base", "softTissue"]
    }],
    rules: []
  },
  multiunitAbutment: {
    files: [{
      item: "multiunitAbutment",
      material: "aluminium",
      opacity: 100,
      groups: ["multiunitAbutments", "prosthesisAll"]
    }, {
      item: "multiunitScrew",
      material: "aluminium",
      opacity: 0,
      disableSmoothing: true,
      groups: ["screws", "prosthesisAll"]
    }, {
      item: "multiunitPlatform",
      material: "aluminium",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: [{
      item: "crown",
      material: "plasticCrown",
      opacity: 0
    }, {
      item: "screwFixationCrowns",
      opacity: 100
    }, {
      item: "preparedTooth",
      material: "plasticCrown",
      opacity: 0
    }]
  },
  multiunitZirconiumAbutment: {
    files: [{
      item: "multiunitAbutment",
      material: "aluminium",
      opacity: 100,
      groups: ["multiunitAbutments", "prosthesisAll"]
    }, {
      item: "individualAbutment",
      material: "bone",
      opacity: 100,
      groups: ["abutments", "prosthesisAll"]
    }, {
      item: "multiunitScrew",
      material: "aluminium",
      opacity: 100,
      disableSmoothing: true,
      groups: ["screws", "prosthesisAll"]
    }, {
      item: "multiunitPlatform",
      material: "aluminium",
      opacity: 100,
      groups: ["abutments", "prosthesisAll"]
    }],
    rules: [{
      item: "crown",
      material: "plasticCrown",
      opacity: 0
    }, {
      item: "preparedTooth",
      material: "plasticCrown",
      opacity: 0
    }, {
      item: "individualAbutmentCrowns",
      opacity: 100
    }]
  },
  singleStageImplant: {
    files: [{
      item: "standardAbutment",
      material: "aluminium",
      opacity: 100,
      groups: ["implants", "prosthesisAll"]
    }, {
      item: "singleStageImplantStubs",
      material: "aluminium",
      opacity: 100,
      groups: ["implants"]
    }],
    rules: [{
      item: "crown",
      opacity: 0
    }, {
      item: "preparedTooth",
      opacity: 0
    }]
  },
  standardAbutment: {
    files: [{
      item: "standardAbutment",
      material: "aluminium",
      opacity: 100,
      groups: ["abutments", "prosthesisAll"]
    }, {
      item: "screws",
      material: "aluminium",
      opacity: 100,
      disableSmoothing: true,
      groups: ["screws", "prosthesisAll"]
    }],
    rules: [{
      item: "crown",
      opacity: 0
    }, {
      item: "preparedTooth",
      opacity: 0
    }]
  },
  individualZirconiumAbutment: {
    files: [{
      item: "standardAbutment",
      material: "aluminium",
      opacity: 100,
      groups: ["abutments", "prosthesisAll"]
    }, {
      item: "individualAbutment",
      material: "bone",
      opacity: 100,
      groups: ["abutments", "prosthesisAll"]
    }, {
      item: "screws",
      material: "aluminium",
      opacity: 100,
      disableSmoothing: true,
      groups: ["screws", "prosthesisAll"]
    }],
    rules: [{
      item: "crown",
      opacity: 0
    }, {
      item: "preparedTooth",
      opacity: 0
    }, {
      item: "individualAbutmentCrowns",
      opacity: 100
    }]
  },
  individualTitaniumAbutment: {
    files: [{
      item: "standardAbutment",
      material: "aluminium",
      opacity: 100,
      groups: ["abutments", "prosthesisAll"]
    }, {
      item: "individualAbutment",
      material: "aluminium",
      opacity: 100,
      groups: ["abutments", "prosthesisAll"]
    }, {
      item: "screws",
      material: "aluminium",
      opacity: 100,
      disableSmoothing: true,
      groups: ["screws", "prosthesisAll"]
    }],
    rules: [{
      item: "crown",
      opacity: 0
    }, {
      item: "preparedTooth",
      opacity: 0
    }, {
      item: "individualAbutmentCrowns",
      opacity: 100
    }]
  },
  screwFixationImplants: {
    files: [{
      item: "screws",
      material: "aluminium",
      opacity: 100,
      disableSmoothing: true,
      groups: ["screws", "prosthesisAll"]
    }, {
      item: "standardAbutment",
      material: "aluminium",
      opacity: 0,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: [{
      item: "crown",
      opacity: 0
    }, {
      item: "preparedTooth",
      opacity: 0
    }, {
      item: "screwFixationCrowns",
      opacity: 100
    }]
  },
  prostheticsTooth: {
    files: [{
      item: "syntheticTooth",
      material: "enamelSynthetic",
      opacity: 100,
      groups: ["removableProsthetics", "prosthesisAll"]
    }, {
      item: "syntheticToothGum",
      material: "gum",
      opacity: 100,
      groups: ["removableProsthetics", "prosthesisAll"]
    }, {
      item: "emptyTooth",
      material: "gum",
      opacity: 50,
      groups: ["base", "softTissue"]
    }, {
      item: "corticalBoneImplants",
      material: "bone",
      opacity: 20,
      renderOrder: 2,
      groups: ["base", "skull"]
    }],
    rules: []
  },
  toothRemoval: {
    files: [// { item: "healthyTooth", material: "black", opacity: 30, groups: ["base", "skull"] },
    {
      item: "healthyToothCrown",
      material: "black",
      opacity: 30,
      groups: ["base", "skull"]
    }, {
      item: "healthyToothRoot",
      material: "enamelRoot",
      opacity: 30,
      groups: ["base", "skull"]
    }],
    rules: []
  },
  telescopicCrown: {
    files: [{
      item: "telescopicCrowns",
      material: "aluminium",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }, {
      item: "preparedTooth",
      material: "enamel",
      opacity: 100,
      groups: ["base", "skull"]
    }],
    rules: [{
      item: "syntheticTooth",
      material: "enamelSynthetic",
      opacity: 50
    }, {
      item: "individualAbutmentCrowns",
      material: "enamel",
      opacity: 0
    }, {
      item: "screwFixationCrowns",
      material: "enamel",
      opacity: 0
    }, // { item: "healthyTooth", material: "enamel", opacity: 0 },
    {
      item: "healthyToothCrown",
      material: "enamel",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      material: "enamel",
      opacity: 0
    }]
  },
  dentalBar: {
    files: [{
      item: "dentalBar",
      material: "aluminium",
      opacity: 100,
      groups: ["fixedProstheses", "prosthesisAll"]
    }],
    rules: [{
      item: "syntheticTooth",
      material: "enamelSynthetic",
      opacity: 50
    }, {
      item: "individualAbutmentCrowns",
      material: "enamel",
      opacity: 0
    }, {
      item: "screwFixationCrowns",
      material: "enamel",
      opacity: 0
    }]
  },
  attachments: {
    files: [{
      item: "attachments",
      material: "aluminium",
      opacity: 100,
      groups: ["fixedProstheses"]
    }],
    rules: []
  },
  buttonImplant: {
    files: [{
      item: "buttonImplants",
      material: "aluminium",
      opacity: 100,
      groups: ["fixedProstheses"]
    }],
    rules: []
  },
  compositeFilling: {
    files: [{
      item: "compositeFilling",
      material: "enamel",
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "compositeFillingTab",
      material: "enamel",
      opacity: 100,
      groups: ["fixedProstheses"]
    }],
    rules: []
  },
  temporaryFilling: {
    files: [{
      item: "compositeFilling",
      material: "enamel",
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "compositeFillingTab",
      material: "bone",
      opacity: 100,
      groups: ["fixedProstheses"]
    }],
    rules: []
  },
  sealedCanal: {
    files: [{
      item: "pulp",
      material: "orange",
      opacity: 100,
      groups: ["base", "skull"]
    }],
    rules: [// { item: "newEsteticTooth", material: "enamelSynthetic", opacity: 50 },
    {
      item: "healthyToothCrown",
      material: "enamelSynthetic",
      opacity: 50
    }, {
      item: "oldEsteticTooth",
      material: "enamelRoot",
      opacity: 50
    }, {
      item: "preparedTooth",
      material: "enamel",
      opacity: 50
    }, // { item: "healthyTooth", material: "enamel", opacity: 50 },
    {
      item: "healthyToothCrown",
      material: "enamel",
      opacity: 50
    }, {
      item: "healthyToothRoot",
      material: "enamelRoot",
      opacity: 50
    }, {
      item: "reducedTooth",
      material: "enamel",
      opacity: 50
    }]
  },
  parodontit: {
    files: [// { item: "healthyTooth", material: "roseQuartz", opacity: 100, groups: ["base", "skull"] },
    {
      item: "healthyToothCrown",
      material: "roseQuartz",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "healthyToothRoot",
      material: "enamelRoot",
      opacity: 100,
      groups: ["base", "skull"]
    }],
    rules: []
  },
  periodontit: {
    files: [],
    rules: []
  },
  movableTooth: {
    files: [// { item: "healthyTooth", material: "darkViolet", opacity: 100, groups: ["base", "skull"] },
    {
      item: "healthyToothCrown",
      material: "darkViolet",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "healthyToothRoot",
      material: "enamelRoot",
      opacity: 100,
      groups: ["base", "skull"]
    }],
    rules: []
  },
  cariousTooth: {
    files: [],
    rules: [// { item: "healthyTooth", opacity: 0 },
    {
      item: "healthyToothCrown",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      opacity: 0
    }, {
      item: "sectionTooth_01",
      material: "enamelRoot",
      importance: 1,
      opacity: 100
    }, {
      item: "sectionTooth_02",
      material: "enamelRoot",
      importance: 1,
      opacity: 100
    }, {
      item: "sectionTooth_03",
      material: "enamelRoot",
      importance: 1,
      opacity: 100
    }, {
      item: "sectionTooth_1",
      material: "enamelCarious",
      importance: 1,
      opacity: 100
    }, {
      item: "sectionTooth_2",
      material: "enamelCarious",
      importance: 1,
      opacity: 100
    }, {
      item: "sectionTooth_3",
      material: "enamelCarious",
      importance: 1,
      opacity: 100
    }, {
      item: "sectionTooth_4",
      material: "enamelCarious",
      importance: 1,
      opacity: 100
    }, {
      item: "sectionTooth_5",
      material: "enamelCarious",
      importance: 1,
      opacity: 100
    }, {
      item: "sectionTooth_6",
      material: "enamelCarious",
      importance: 1,
      opacity: 100
    }, {
      item: "sectionTooth_7",
      material: "enamelCarious",
      importance: 1,
      opacity: 100
    }]
  },
  cariousToothPrepare: {
    files: [],
    rules: [// { item: "healthyTooth", opacity: 0 },
    {
      item: "healthyToothCrown",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      opacity: 0
    }, {
      item: "sectionTooth_01",
      material: "enamelRoot",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_02",
      material: "enamelRoot",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_03",
      material: "enamelRoot",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_1",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_2",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_3",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_4",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_5",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_6",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_7",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }]
  },
  therapyRestoration: {
    files: [],
    rules: [// { item: "healthyTooth", opacity: 0 },
    {
      item: "healthyToothCrown",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      opacity: 0
    }, {
      item: "sectionTooth_01",
      material: "enamelRoot",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_02",
      material: "enamelRoot",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_03",
      material: "enamelRoot",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_1",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_2",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_3",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_4",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_5",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_6",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_7",
      material: "enamel",
      importance: 1,
      opacity: 100,
      groups: ["base", "skull"]
    }]
  },
  pulpitis: {
    files: [{
      item: "pulp",
      material: "redGlossy",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "cyst_1",
      material: "redGlossy",
      opacity: 0,
      groups: ["base", "skull"]
    }, {
      item: "cyst_2",
      material: "redGlossy",
      opacity: 0,
      groups: ["base", "skull"]
    }, {
      item: "cyst_3",
      material: "redGlossy",
      opacity: 0,
      groups: ["base", "skull"]
    }],
    rules: [// { item: "healthyTooth", opacity: 0 },
    {
      item: "healthyToothCrown",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      opacity: 0
    }, {
      item: "sectionTooth_01",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_02",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_03",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_1",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_2",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_3",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_4",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_5",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_6",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_7",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }]
  },
  badSealedCanal: {
    files: [{
      item: "badSealedCanal",
      material: "enamelSynthetic",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "badSealedCanal2",
      material: "black",
      opacity: 45,
      groups: ["base", "skull"]
    }, {
      item: "cyst_1",
      material: "redGlossy",
      opacity: 0,
      groups: ["base", "skull"]
    }, {
      item: "cyst_2",
      material: "redGlossy",
      opacity: 0,
      groups: ["base", "skull"]
    }, {
      item: "cyst_3",
      material: "redGlossy",
      opacity: 0,
      groups: ["base", "skull"]
    }],
    rules: [// { item: "healthyTooth", opacity: 0 },
    {
      item: "healthyToothCrown",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      opacity: 0
    }, {
      item: "sectionTooth_01",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_02",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_03",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_1",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_2",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_3",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_4",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_5",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_6",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_7",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }]
  },
  brokenToolCanal: {
    files: [{
      item: "canal",
      material: "enamelSynthetic",
      opacity: 40,
      groups: ["base", "skull"]
    }, {
      item: "brokenTool",
      material: "steel",
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "cyst_1",
      material: "redGlossy",
      opacity: 0,
      groups: ["base", "skull"]
    }, {
      item: "cyst_2",
      material: "redGlossy",
      opacity: 0,
      groups: ["base", "skull"]
    }, {
      item: "cyst_3",
      material: "redGlossy",
      opacity: 0,
      groups: ["base", "skull"]
    }],
    rules: [// { item: "healthyTooth", opacity: 0 },
    {
      item: "healthyToothCrown",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      opacity: 0
    }, {
      item: "sectionTooth_01",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_02",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_03",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_1",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_2",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_3",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_4",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_5",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_6",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_7",
      material: "enamelCarious",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }]
  },
  openCanal: {
    files: [{
      item: "canal",
      material: "enamelRoot",
      opacity: 100,
      groups: ["base", "skull"]
    }],
    rules: [// { item: "healthyTooth", opacity: 0 },
    {
      item: "healthyToothCrown",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      opacity: 0
    }, {
      item: "sectionTooth_01",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_02",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_03",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_1",
      material: "enamel",
      importance: 2,
      opacity: 0,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_2",
      material: "enamel",
      importance: 2,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_3",
      material: "enamel",
      importance: 2,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_4",
      material: "enamel",
      importance: 2,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_5",
      material: "enamel",
      importance: 2,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_6",
      material: "enamel",
      importance: 2,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_7",
      material: "enamel",
      importance: 2,
      opacity: 100,
      groups: ["base", "skull"]
    }]
  },
  treatedCanal: {
    files: [{
      item: "canal",
      material: "enamelSynthetic",
      opacity: 100,
      groups: ["base", "skull"]
    }],
    rules: [// { item: "healthyTooth", opacity: 0 },
    {
      item: "healthyToothCrown",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      opacity: 0
    }, {
      item: "sectionTooth_01",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_02",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_03",
      material: "enamelRoot",
      importance: 2,
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_1",
      material: "enamel",
      importance: 2,
      opacity: 100,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_2",
      material: "enamel",
      importance: 2,
      opacity: 70,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_3",
      material: "enamel",
      importance: 2,
      opacity: 70,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_4",
      material: "enamel",
      importance: 2,
      opacity: 70,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_5",
      material: "enamel",
      importance: 2,
      opacity: 70,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_6",
      material: "enamel",
      importance: 2,
      opacity: 70,
      groups: ["base", "skull"]
    }, {
      item: "sectionTooth_7",
      material: "enamel",
      importance: 2,
      opacity: 70,
      groups: ["base", "skull"]
    }]
  },
  temporaryTooth: {
    files: [],
    rules: [{
      item: "compositeFilling",
      material: "darkViolet",
      opacity: 50
    }, // { item: "healthyTooth", material: "darkViolet", opacity: 100 },
    {
      item: "healthyToothCrown",
      material: "darkViolet",
      opacity: 100
    }, {
      item: "healthyToothRoot",
      material: "enamelRoot",
      opacity: 100
    }, {
      item: "preparedTooth",
      material: "darkViolet",
      opacity: 100
    }]
  },
  syntheticGum: {
    files: [{
      item: "syntheticGum",
      material: "gum",
      opacity: 100,
      groups: ["syntheticGum", "fixedProstheses", "prosthesisAll"]
    }],
    rules: []
  },
  toothStumpRestoration: {
    files: [{
      item: "stumpTabTooth",
      material: "enamelRoot",
      opacity: 50,
      groups: ["base", "skull"]
    }, {
      item: "stumpTab",
      material: "bone",
      opacity: 100,
      groups: ["base", "skull", "stumpTabs"]
    }],
    rules: [// { item: "healthyTooth", material: "enamel", opacity: 0 },
    {
      item: "healthyToothCrown",
      material: "enamel",
      opacity: 0
    }, {
      item: "healthyToothRoot",
      material: "enamelRoot",
      opacity: 0
    }, {
      item: "preparedTooth",
      material: "enamel",
      opacity: 0
    }]
  },
  boneAugmentation: {
    files: [{
      item: "boneAugmentation",
      material: "enamelSynthetic",
      opacity: 100,
      groups: ["base", "skull"]
    }],
    rules: []
  }
};
export function getFormulaToothStl(type, activity, toothName) {
  const activityInfo = formulaToothStl[activity];
  return activityInfo[type].reduce((tmpContent, el) => {
    return [...tmpContent, { ...el,
      path: `${el.item}/${toothName}`
    }];
  }, []);
}
export const formulaToothPartRules = {
  cariousTooth: {
    sectionTooth_01: {
      item: "sectionTooth_01",
      material: "black",
      importance: 3
    },
    sectionTooth_02: {
      item: "sectionTooth_02",
      material: "black",
      importance: 3
    },
    sectionTooth_03: {
      item: "sectionTooth_03",
      material: "black",
      importance: 3
    },
    sectionTooth_1: {
      item: "sectionTooth_1",
      material: "black",
      importance: 3
    },
    sectionTooth_2: {
      item: "sectionTooth_2",
      material: "black",
      importance: 3
    },
    sectionTooth_3: {
      item: "sectionTooth_3",
      material: "black",
      importance: 3
    },
    sectionTooth_4: {
      item: "sectionTooth_4",
      material: "black",
      importance: 3
    },
    sectionTooth_5: {
      item: "sectionTooth_5",
      material: "black",
      importance: 3
    },
    sectionTooth_6: {
      item: "sectionTooth_6",
      material: "black",
      importance: 3
    },
    sectionTooth_7: {
      item: "sectionTooth_7",
      material: "black",
      importance: 3
    }
  },
  cariousToothPrepare: {
    sectionTooth_01: {
      item: "sectionTooth_01",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_02: {
      item: "sectionTooth_02",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_03: {
      item: "sectionTooth_03",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_1: {
      item: "sectionTooth_1",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_2: {
      item: "sectionTooth_2",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_3: {
      item: "sectionTooth_3",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_4: {
      item: "sectionTooth_4",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_5: {
      item: "sectionTooth_5",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_6: {
      item: "sectionTooth_6",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_7: {
      item: "sectionTooth_7",
      material: "enamelRoot",
      importance: 3
    }
  },
  therapyRestoration: {
    sectionTooth_01: {
      item: "sectionTooth_01",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_02: {
      item: "sectionTooth_02",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_03: {
      item: "sectionTooth_03",
      material: "enamelRoot",
      importance: 3
    },
    sectionTooth_1: {
      item: "sectionTooth_1",
      material: "enamelSynthetic",
      importance: 3
    },
    sectionTooth_2: {
      item: "sectionTooth_2",
      material: "enamelSynthetic",
      importance: 3
    },
    sectionTooth_3: {
      item: "sectionTooth_3",
      material: "enamelSynthetic",
      importance: 3
    },
    sectionTooth_4: {
      item: "sectionTooth_4",
      material: "enamelSynthetic",
      importance: 3
    },
    sectionTooth_5: {
      item: "sectionTooth_5",
      material: "enamelSynthetic",
      importance: 3
    },
    sectionTooth_6: {
      item: "sectionTooth_6",
      material: "enamelSynthetic",
      importance: 3
    },
    sectionTooth_7: {
      item: "sectionTooth_7",
      material: "enamelSynthetic",
      importance: 3
    }
  },
  cyst: {
    sectionTooth_01: {
      item: "cyst_1",
      opacity: 100,
      importance: 3
    },
    sectionTooth_02: {
      item: "cyst_2",
      opacity: 100,
      importance: 3
    },
    sectionTooth_03: {
      item: "cyst_3",
      opacity: 100,
      importance: 3
    }
  }
};
export const formulaProsthesis = {
  clutchTop: {
    files: [{
      item: "clutchProsthesis",
      name: "clutchTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "removableProsthetics", "prosthesisAll"]
    }]
  },
  clutchBottom: {
    files: [{
      item: "clutchProsthesis",
      name: "clutchBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "removableProsthetics", "prosthesisAll"]
    }]
  },
  acrylicTop: {
    files: [{
      item: "acrylicProsthesis",
      name: "acrylicTop",
      material: "gum",
      opacity: 100,
      groups: ["top", "removableProsthetics", "prosthesisAll"]
    }]
  },
  acrylicBottom: {
    files: [{
      item: "acrylicProsthesis",
      name: "acrylicBottom",
      material: "gum",
      opacity: 100,
      groups: ["bottom", "removableProsthetics", "prosthesisAll"]
    }]
  },
  nylonTop: {
    files: [{
      item: "nylonProsthesis",
      name: "nylonTop",
      material: "gum",
      opacity: 100,
      groups: ["top", "removableProsthetics"]
    }]
  },
  nylonBottom: {
    files: [{
      item: "nylonProsthesis",
      name: "nylonBottom",
      material: "gum",
      opacity: 100,
      groups: ["bottom", "removableProsthetics"]
    }]
  },
  totalTop: {
    files: [{
      item: "totalProsthesis",
      name: "totalTop",
      material: "gum",
      opacity: 100,
      groups: ["top", "removableProsthetics", "prosthesisAll"]
    }]
  },
  totalBottom: {
    files: [{
      item: "totalProsthesis",
      name: "totalBottom",
      material: "gum",
      opacity: 100,
      groups: ["bottom", "removableProsthetics", "prosthesisAll"]
    }]
  },
  capaTop: {
    files: [{
      item: "capaProsthesis",
      name: "capaTop",
      material: "blue",
      opacity: 30,
      groups: ["top", "removableProsthetics"]
    }]
  },
  capaBottom: {
    files: [{
      item: "capaProsthesis",
      name: "capaBottom",
      material: "blue",
      opacity: 30,
      groups: ["bottom", "removableProsthetics"]
    }]
  },
  elinerTop: {
    files: [{
      item: "elinersProsthesis",
      name: "elinerTop",
      material: "blue",
      opacity: 30,
      groups: ["top", "removableProsthetics"]
    }]
  },
  elinerBottom: {
    files: [{
      item: "elinersProsthesis",
      name: "elinerBottom",
      material: "blue",
      opacity: 30,
      groups: ["bottom", "removableProsthetics"]
    }]
  },
  orthodonticPlateTop: {
    files: [{
      item: "orthodonticPlateProsthesis",
      name: "orthodonticPlateTop",
      material: "blue",
      opacity: 70,
      groups: ["top", "orthoSystems"]
    }, {
      item: "orthodonticPlateProsthesis",
      name: "orthodonticPlateNutTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }, {
      item: "orthodonticPlateProsthesis",
      name: "orthodonticPlateKlammerTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }, {
      item: "orthodonticPlateProsthesis",
      name: "orthodonticPlateLockTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }]
  },
  orthodonticPlateBottom: {
    files: [{
      item: "orthodonticPlateProsthesis",
      name: "orthodonticPlateBottom",
      material: "blue",
      opacity: 70,
      groups: ["bottom", "orthoSystems"]
    }, {
      item: "orthodonticPlateProsthesis",
      name: "orthodonticPlateNutBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }, {
      item: "orthodonticPlateProsthesis",
      name: "orthodonticPlateKlammerBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }, {
      item: "orthodonticPlateProsthesis",
      name: "orthodonticPlateLockBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }]
  },
  metalBracesTop: {
    files: [{
      item: "metalBraces",
      name: "metalBracesTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "base", "skull", "fixedProstheses"]
    }, {
      item: "arcBraces",
      name: "arcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "base", "skull", "fixedProstheses"]
    }]
  },
  metalBracesBottom: {
    files: [{
      item: "metalBraces",
      name: "metalBracesBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "base", "skull", "fixedProstheses"]
    }, {
      item: "arcBraces",
      name: "arcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "base", "skull", "fixedProstheses"]
    }]
  },
  ceramicBracesTop: {
    files: [{
      item: "ceramicBraces",
      name: "ceramicBracesTop",
      material: "enamel",
      opacity: 100,
      groups: ["top", "base", "skull", "fixedProstheses"]
    }, {
      item: "arcBraces",
      name: "arcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "base", "skull", "fixedProstheses"]
    }]
  },
  ceramicBracesBottom: {
    files: [{
      item: "ceramicBraces",
      name: "ceramicBracesBottom",
      material: "enamel",
      opacity: 100,
      groups: ["bottom", "base", "skull", "fixedProstheses"]
    }, {
      item: "arcBraces",
      name: "arcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "base", "skull", "fixedProstheses"]
    }]
  },
  sapphireBracesTop: {
    files: [{
      item: "sapphireBraces",
      name: "sapphireBracesTop",
      material: "blue",
      opacity: 30,
      groups: ["top", "base", "skull", "fixedProstheses"]
    }, {
      item: "arcBraces",
      name: "arcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "base", "skull", "fixedProstheses"]
    }]
  },
  sapphireBracesBottom: {
    files: [{
      item: "sapphireBraces",
      name: "sapphireBracesBottom",
      material: "blue",
      opacity: 30,
      groups: ["bottom", "base", "skull", "fixedProstheses"]
    }, {
      item: "arcBraces",
      name: "arcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "base", "skull", "fixedProstheses"]
    }]
  },
  plasticBracesTop: {
    files: [{
      item: "plasticBraces",
      name: "plasticBracesTop",
      material: "bone",
      opacity: 100,
      groups: ["top", "base", "skull", "fixedProstheses"]
    }, {
      item: "arcBraces",
      name: "arcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "base", "skull", "fixedProstheses"]
    }]
  },
  plasticBracesBottom: {
    files: [{
      item: "plasticBraces",
      name: "plasticBracesBottom",
      material: "bone",
      opacity: 100,
      groups: ["bottom", "base", "skull", "fixedProstheses"]
    }, {
      item: "arcBraces",
      name: "arcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "base", "fixedProstheses"]
    }]
  },
  lingualBracesTop: {
    files: [{
      item: "lingualBraces",
      name: "lingualBracesTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "base", "skull", "fixedProstheses"]
    }, {
      item: "lingualBraces",
      name: "arcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "base", "skull", "fixedProstheses"]
    }]
  },
  lingualBracesBottom: {
    files: [{
      item: "lingualBraces",
      name: "lingualBracesBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "base", "skull", "fixedProstheses"]
    }, {
      item: "lingualBraces",
      name: "arcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "base", "skull", "fixedProstheses"]
    }]
  },
  elastopositioner: {
    files: [{
      item: "elastopositioner",
      name: "elastopositioner",
      material: "blue",
      opacity: 70,
      groups: ["bottom", "orthoSystems"]
    }]
  },
  slideSapphireBracesTop: {
    files: [{
      item: "gum",
      name: "gumTop",
      material: "orthoGum",
      opacity: 70,
      groups: ["top", "base"]
    }, ...slideToothTop, {
      counter: 10,
      item: "ceramicBraces",
      name: "ceramicBracesTop",
      material: "glassBlue",
      opacity: 50,
      transparentDisabled: true,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "ceramicRubber",
      name: "ceramicRubberTop",
      material: "glassYellow",
      opacity: 70,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "frontArc",
      name: "frontArcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }]
  },
  slideSapphireBracesBottom: {
    files: [{
      item: "gum",
      name: "gumBottom",
      material: "orthoGum",
      opacity: 70,
      groups: ["bottom", "base"]
    }, ...slideToothBottom, {
      counter: 10,
      item: "ceramicBraces",
      name: "ceramicBracesBottom",
      material: "glassBlue",
      opacity: 50,
      transparentDisabled: true,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "ceramicRubber",
      name: "ceramicRubberBottom",
      material: "glassYellow",
      opacity: 70,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "frontArc",
      name: "frontArcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }]
  },
  slideSelfAlignBracesTop: {
    files: [{
      item: "gum",
      name: "gumTop",
      material: "orthoGum",
      opacity: 70,
      groups: ["top", "base"]
    }, ...slideToothTop, {
      counter: 10,
      item: "metallBraces",
      name: "metallBracesTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "selfAlignBraces",
      name: "selfAlignBracesTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "frontArc",
      name: "frontArcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }]
  },
  slideSelfAlignBracesBottom: {
    files: [{
      item: "gum",
      name: "gumBottom",
      material: "orthoGum",
      opacity: 70,
      groups: ["bottom", "base"]
    }, ...slideToothBottom, {
      counter: 10,
      item: "metallBraces",
      name: "metallBracesBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "selfAlignBraces",
      name: "selfAlignBracesBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "frontArc",
      name: "frontArcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }]
  },
  slideCeramicBracesTop: {
    files: [{
      item: "gum",
      name: "gumTop",
      material: "orthoGum",
      opacity: 70,
      groups: ["top", "base"]
    }, ...slideToothTop, {
      counter: 10,
      item: "ceramicBraces",
      name: "ceramicBracesTop",
      material: "orthoEnamel",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "ceramicRubber",
      name: "ceramicRubberTop",
      material: "glassYellow",
      opacity: 70,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "frontArc",
      name: "frontArcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }]
  },
  slideCeramicBracesBottom: {
    files: [{
      item: "gum",
      name: "gumBottom",
      material: "orthoGum",
      opacity: 70,
      groups: ["bottom", "base"]
    }, ...slideToothBottom, {
      counter: 10,
      item: "ceramicBraces",
      name: "ceramicBracesBottom",
      material: "orthoEnamel",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "ceramicRubber",
      name: "ceramicRubberBottom",
      material: "glassYellow",
      opacity: 70,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "frontArc",
      name: "frontArcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }]
  },
  slideCeramicSelfAlignBracesTop: {
    files: [{
      item: "gum",
      name: "gumTop",
      material: "orthoGum",
      opacity: 70,
      groups: ["top", "base"]
    }, ...slideToothTop, {
      counter: 10,
      item: "metallBraces",
      name: "metallBracesTop",
      material: "orthoEnamel",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "selfAlignBraces",
      name: "selfAlignBracesTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "frontArc",
      name: "frontArcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }]
  },
  slideCeramicSelfAlignBracesBottom: {
    files: [{
      item: "gum",
      name: "gumBottom",
      material: "orthoGum",
      opacity: 70,
      groups: ["bottom", "base"]
    }, ...slideToothBottom, {
      counter: 10,
      item: "metallBraces",
      name: "metallBracesBottom",
      material: "orthoEnamel",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "selfAlignBraces",
      name: "selfAlignBracesBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "frontArc",
      name: "frontArcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }]
  },
  slideMetallBracesTop: {
    files: [{
      item: "gum",
      name: "gumTop",
      material: "orthoGum",
      opacity: 70,
      groups: ["top", "base"]
    }, ...slideToothTop, {
      counter: 10,
      item: "metallBraces",
      name: "metallBracesTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "metallRubber",
      name: "metallRubberTop",
      material: "lightBlue",
      opacity: 70,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "frontArc",
      name: "frontArcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }]
  },
  slideMetallBracesBottom: {
    files: [{
      item: "gum",
      name: "gumBottom",
      material: "orthoGum",
      opacity: 70,
      groups: ["bottom", "base"]
    }, ...slideToothBottom, {
      counter: 10,
      item: "metallBraces",
      name: "metallBracesBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "metallRubber",
      name: "metallRubberBottom",
      material: "lightBlue",
      opacity: 70,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "frontArc",
      name: "frontArcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }]
  },
  slideLingualBracesTop: {
    files: [{
      item: "gum",
      name: "gumTop",
      material: "orthoGum",
      opacity: 70,
      groups: ["top", "base"]
    }, ...slideToothTop, {
      counter: 10,
      item: "lingualBraces",
      name: "lingualBracesTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "lingualRubber",
      name: "lingualRubberTop",
      material: "pink",
      opacity: 70,
      groups: ["top", "orthoSystems"]
    }, {
      counter: 10,
      item: "lingualArc",
      name: "lingualArcTop",
      material: "aluminium",
      opacity: 100,
      groups: ["top", "orthoSystems"]
    }]
  },
  slideLingualBracesBottom: {
    files: [{
      item: "gum",
      name: "gumBottom",
      material: "orthoGum",
      opacity: 70,
      groups: ["bottom", "base"]
    }, ...slideToothBottom, {
      counter: 10,
      item: "lingualBraces",
      name: "lingualBracesBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "lingualRubber",
      name: "lingualRubberBottom",
      material: "pink",
      opacity: 70,
      groups: ["bottom", "orthoSystems"]
    }, {
      counter: 10,
      item: "lingualArc",
      name: "lingualArcBottom",
      material: "aluminium",
      opacity: 100,
      groups: ["bottom", "orthoSystems"]
    }]
  },
  slideMouthAlignerTop: {
    files: [{
      item: "gum",
      name: "gumTop",
      material: "orthoGum",
      opacity: 70,
      groups: ["top", "base"]
    }, ...slideToothTop, {
      counter: 10,
      item: "aligners",
      name: "alignersTop",
      material: "polycarbonat",
      opacity: 50,
      transparentDisabled: true,
      groups: ["top", "orthoSystems"]
    }]
  },
  slideMouthAlignerBottom: {
    files: [{
      item: "gum",
      name: "gumBottom",
      material: "orthoGum",
      opacity: 70,
      groups: ["bottom", "base"]
    }, ...slideToothBottom, {
      counter: 10,
      item: "aligners",
      name: "alignersBottom",
      material: "polycarbonat",
      opacity: 50,
      transparentDisabled: true,
      groups: ["bottom", "orthoSystems"]
    }]
  },
  slideNoAlignSystemTop: {
    files: [{
      item: "gum",
      name: "gumTop",
      material: "orthoGum",
      opacity: 70,
      groups: ["top", "base"]
    }, ...slideToothTop]
  },
  slideNoAlignSystemBottom: {
    files: [{
      item: "gum",
      name: "gumBottom",
      material: "orthoGum",
      opacity: 70,
      groups: ["bottom", "base"]
    }, ...slideToothBottom]
  }
};
export const formulaGaps = {
  connection: [{
    item: "connection",
    material: "enamel",
    opacity: 100,
    groups: ["fixedProstheses", "prosthesisAll"]
  }],
  separation: [{
    item: "separation",
    material: "aluminium",
    opacity: 100,
    groups: ["separations"]
  }],
  miniImplant: [{
    item: "miniImplant",
    material: "aluminium",
    opacity: 100,
    groups: ["implants"]
  }]
};
export const formulaJointDisease = {};
export const formulaMandibularCanal = {};
export const formulaBones = {
  skull: {
    files: [{
      item: "Skull",
      material: "bone",
      opacity: 20,
      renderOrder: 1,
      groups: ["skull"]
    }, {
      item: "Mandibula",
      material: "bone",
      opacity: 20,
      renderOrder: 1,
      groups: ["skull"]
    }, {
      item: "Canal",
      material: "redGlossy",
      opacity: 60,
      groups: ["skull"]
    }, {
      item: "JointDiskLeft",
      material: "blue",
      opacity: 20,
      groups: ["skull"]
    }, {
      item: "JointDiskRight",
      material: "blue",
      opacity: 20,
      groups: ["skull"]
    }, {
      item: "softTissue",
      material: "skin",
      opacity: 20,
      renderOrder: 7,
      groups: ["softTissue"]
    }]
  }
};
export const formulaSinuses = {
  normalSinusesLeft: {
    files: [{
      item: "normalSinusesLeft",
      material: "redGlossy",
      opacity: 30,
      groups: ["sinuses", "skull"]
    }],
    rules: []
  },
  normalSinusesRight: {
    files: [{
      item: "normalSinusesRight",
      material: "redGlossy",
      opacity: 30,
      groups: ["sinuses", "skull"]
    }],
    rules: []
  },
  lowSinusesLeft: {
    files: [{
      item: "lowSinusesLeft",
      material: "redGlossy",
      opacity: 30,
      groups: ["sinuses", "skull"]
    }],
    rules: []
  },
  lowSinusesRight: {
    files: [{
      item: "lowSinusesRight",
      material: "redGlossy",
      opacity: 30,
      groups: ["sinuses", "skull"]
    }],
    rules: []
  },
  sinusLiftingLeft: {
    files: [{
      item: "liftingSinusesLeft",
      material: "enamelSynthetic",
      opacity: 100,
      groups: ["sinuses", "skull"]
    }],
    rules: []
  },
  sinusLiftingRight: {
    files: [{
      item: "liftingSinusesRight",
      material: "enamelSynthetic",
      opacity: 100,
      groups: ["sinuses", "skull"]
    }],
    rules: []
  }
};
export const formulaBase = {
  gumTop: {
    files: [{
      item: "gum",
      name: "gum_top",
      material: "gum",
      opacity: 50,
      renderOrder: 5,
      groups: ["top", "base", "softTissue"]
    }]
  },
  gumBottom: {
    files: [{
      item: "gum",
      name: "gum_bottom",
      material: "gum",
      opacity: 50,
      renderOrder: 5,
      groups: ["bottom", "base", "softTissue"]
    }]
  },
  orthoGumTop: {
    files: [{
      item: "gum",
      name: "gum_top",
      material: "gum",
      opacity: 50,
      renderOrder: 1,
      groups: ["bottom", "base"]
    }]
  },
  orthoGumBottom: {
    files: [{
      item: "gum",
      name: "gum_bottom",
      material: "gum",
      opacity: 50,
      renderOrder: 1,
      groups: ["bottom", "base"]
    }]
  }
};
import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSignOut } from "../../redux/reducers/auth";
import createCardIcon from "../Main/assets/treatmentPlanIcon.png";
import LazySpinner from "../LazySpinner";
import { useGetPackageDetailsQuery } from "../../redux/packagesApi";

export const SignInBtn = () => {
  const { t: _t } = useTranslation();

  return (
    <Button
      variant="outlined"
      color="white"
      to="/sign-in"
      component={RouterLink}
      sx={{ mr: 1 }}
    >
      {_t("Sign in")}
    </Button>
  );
};

export const SignUpBtn = () => {
  const { t: _t } = useTranslation();

  return (
    <Button
      variant="outlined"
      color="white"
      to="/sign-up"
      component={RouterLink}
    >
      {_t("Sign up")}
    </Button>
  );
};

export const SignOutBtn = () => {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      color="white"
      onClick={() => {
        dispatch(setSignOut());
        navigate("/", { replace: true });
      }}
    >
      {_t("Sign out")}
    </Button>
  );
};

export const ProfileBtn = () => {
  const { t: _t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="primary"
      to="/account"
      component={RouterLink}
      sx={{ mr: 1 }}
    >
      {_t("Profile")}
    </Button>
  );
};

export const TreatmentPlanBtn = () => {
  const { t: _t } = useTranslation();
  const { data: packageDetails, isLoading: isPackageDetailsLoading } =
    useGetPackageDetailsQuery();

  if (isPackageDetailsLoading) {
    return <LazySpinner />;
  }

  return (
    <Button
      to="/treatment-plan"
      component={RouterLink}
      variant="contained"
      color="primary"
      startIcon={
        <Avatar
          sx={{ width: 66, height: 44 }}
          variant="square"
          src={createCardIcon}
        />
      }
      disabled={!packageDetails.cardCreationEnabled}
    >
      {_t("Create a treatment plan")}
    </Button>
  );
};

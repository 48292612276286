import { green, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#42a5f5",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    green: {
      main: green.A400,
      contrastText: "#fff",
    },
    white: {
      light: "#fff",
      main: "#fff",
      dark: "#dcdcdc",
      contrastText: "#969696",
    },
    navigationBar: {
      main: "#969696",
      contrastText: "#969696",
      dark: "#969696",
    },
    navigationBarActive: {
      main: "#ffffff",
      contrastText: "#969696",
      dark: "#ffffff",
    },
  },
  shape: {
    borderRadius: 24,
  },
});

export default theme;

import {
  Avatar,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "./ProfileSection.scss";
import logo from "./assets/logo.png";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../../redux/profileApi";
import { TreatmentPlanBtn } from "../Auth/ActionBtns";
import { useState } from "react";
import LazySpinner from "../LazySpinner";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { toggleSnackbar } from "../../redux/reducers/snackbar";
import { useDispatch } from "react-redux";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { differenceInYears, parseISO } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import { useGetPackageDetailsQuery } from "../../redux/packagesApi";

function SubscriptionDetails() {
  const { t: _t } = useTranslation();

  const { data: packageDetails, isLoading: isPackageDetailsLoading } =
    useGetPackageDetailsQuery();

  function getPackageTitle(id) {
    switch (id) {
      case 0:
        return _t("Basic package");
      case 1:
        return _t("Single package");
      case 2:
        return _t("Family package");
      case 3:
        return _t("Golden package");
      default:
        return "";
    }
  }

  if (isPackageDetailsLoading) {
    return <LazySpinner />;
  }

  return (
    <Paper
      sx={{ px: 2, py: 2, backgroundColor: "transparent" }}
      className={`subscriptionBlock package-${packageDetails.packageInfo.id}`}
    >
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{ color: "#fff" }}
      >
        <strong>{getPackageTitle(packageDetails.packageInfo.id)}</strong>
      </Typography>
      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs>
          <Stack direction="column">
            <Typography sx={{ color: "#fff" }}>
              <strong>{_t("Total Treatment Plans:")}</strong>{" "}
              {packageDetails.availableCards}
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              <strong>{_t("Created:")}</strong> {packageDetails.createdCards}
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              <strong>{_t("Remaining:")}</strong>{" "}
              {packageDetails.remainingCards}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs="auto">
          <Button
            variant="contained"
            color="white"
            to="/account/packages"
            component={RouterLink}
          >
            {_t("Upgrade package")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

function ProfileEditor({ onClose }) {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  const [updateProfile, { isLoading: isUpdateProfileLoading }] =
    useUpdateProfileMutation();

  const profileSchema = yup.object().shape({
    first_name: yup.string().required(_t("First name is required field")),
    last_name: yup.string().required(_t("Last name is required field")),
    gender: yup
      .string()
      .required(_t("Gender is required field"))
      .oneOf(["man", "woman"]),
    birthday: yup
      .date()
      .min(new Date(1900, 0, 1))
      .required("Birthday is required field"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileSchema),
    defaultValues: {
      first_name: profile?.first_name,
      last_name: profile?.last_name,
      gender: profile?.gender,
      birthday: profile?.birthday,
    },
  });

  function onSubmit(data) {
    updateProfile(data)
      .unwrap()
      .then(() => {
        dispatch(
          toggleSnackbar({
            isShow: true,
            type: "success",
            message: _t("Profile was successfuly updated"),
          })
        );
        onClose();
      })
      .catch((error) => {
        dispatch(
          toggleSnackbar({
            isShow: true,
            type: "error",
            message: _t("Error with profile update"),
          })
        );
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={_t("First name")}
        variant="outlined"
        margin="dense"
        size="small"
        fullWidth
        error={!!errors.first_name?.message}
        helperText={errors.first_name?.message}
        {...register("first_name")}
      />
      <TextField
        label={_t("Last name")}
        variant="outlined"
        margin="dense"
        size="small"
        fullWidth
        error={!!errors.last_name?.message}
        helperText={errors.last_name?.message}
        {...register("last_name")}
      />
      <FormControl
        fullWidth
        margin="dense"
        size="small"
        error={!!errors.gender?.message}
      >
        <InputLabel id="gender-select-label">{_t("Gender")}</InputLabel>
        <Select
          labelId="gender-select-label"
          id="gender-select"
          label={_t("Gender")}
          defaultValue="man"
          {...register("gender")}
        >
          <MenuItem value="man">{_t("Man")}</MenuItem>
          <MenuItem value="woman">{_t("Woman")}</MenuItem>
        </Select>
        <FormHelperText>{errors.gender?.message}</FormHelperText>
      </FormControl>
      <Controller
        name="birthday"
        control={control}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label={_t("Birthday")}
              inputFormat="dd/MM/yyyy"
              value={value}
              onChange={onChange}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  error={!!errors.birthday?.message}
                  helperText={errors.birthday?.message}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />

      <Stack
        direction="row"
        sx={{ mt: 1 }}
        spacing={2}
        justifyContent="space-between"
      >
        <Button
          variant="contained"
          color="white"
          size="small"
          onClick={onClose}
        >
          {_t("Close")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          disabled={isProfileLoading || isUpdateProfileLoading}
        >
          {_t("Save profile")}
        </Button>
      </Stack>
    </form>
  );
}

function ProfileDetails() {
  const { t: _t } = useTranslation();
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();

  const [isProfileEditing, setIsProfileEditing] = useState(false);

  if (isProfileLoading) {
    return <LazySpinner />;
  }

  return (
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <Avatar
        src={logo}
        variant="rounded"
        sx={{
          bgcolor: grey[700],
          width: 130,
          height: 130,
          p: 1,
        }}
        alt=""
      />
      {isProfileEditing ? (
        <ProfileEditor
          onClose={() => {
            setIsProfileEditing(false);
          }}
        />
      ) : (
        <Stack
          direction="column"
          spacing={0.25}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Typography variant="h4">{`${profile.first_name} ${profile.last_name}`}</Typography>
          <Typography>
            <strong>{_t("Gender:")}</strong> {profile.gender}
          </Typography>
          <Typography>
            <strong>{_t("Age:")}</strong>{" "}
            {differenceInYears(new Date(), parseISO(profile.birthday))}
          </Typography>
          <Button
            variant="contained"
            color="white"
            size="small"
            onClick={() => {
              setIsProfileEditing(true);
            }}
          >
            {_t("Edit profile")}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

function ProfileSection() {
  const { t: _t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Paper
          sx={{ px: 2, py: 2, backgroundColor: "transparent" }}
          className="card-profile"
        >
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs>
              <ProfileDetails />
            </Grid>
            <Grid item xs="auto">
              <SubscriptionDetails />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <TreatmentPlanBtn />
      </Grid>
      <Grid item xs>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {_t("Family members")}
            </Typography>
            <Button variant="contained" color="primary" sx={{ mb: 3 }} disabled>
              {_t("Invite to family subscription")}
            </Button>
            {/*<Paper sx={{ px: 2, py: 2, backgroundColor: "transparent" }}>*/}
            {/*  <Typography gutterBottom variant="h6" component="div">*/}
            {/*    {_t("Jane doe")}*/}
            {/*  </Typography>*/}
            {/*  <Grid container alignItems="flex-end">*/}
            {/*    <Grid item xs>*/}
            {/*      <Stack direction="column">*/}
            {/*        <Typography>Total Treatment Plans: 15</Typography>*/}
            {/*        <Typography>Created: 3</Typography>*/}
            {/*        <Typography>Remaining: 12</Typography>*/}
            {/*      </Stack>*/}
            {/*    </Grid>*/}
            {/*  </Grid>*/}
            {/*</Paper>*/}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ProfileSection;

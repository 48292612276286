export const esTypical6Top = {
  tooth: [{
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "15",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "16",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "17",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "18",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "21",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "25",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "26",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "27",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "28",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "31",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "32",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "33",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "34",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "35",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "36",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "37",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "38",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "41",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "42",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "43",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "44",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "45",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "46",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "48",
    toothElementsActive: ["oldEsteticTooth"]
  }]
};
export const esTypical8Top = {
  tooth: [{
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "15",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "16",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "17",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "18",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "21",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "25",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "26",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "27",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "28",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "31",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "32",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "33",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "34",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "35",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "36",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "37",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "38",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "41",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "42",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "43",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "44",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "45",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "46",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "48",
    toothElementsActive: ["oldEsteticTooth"]
  }]
};
export const esTypical10Top = {
  tooth: [{
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "15",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "17",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "18",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "21",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "25",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "26",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "27",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "28",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "31",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "32",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "33",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "34",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "35",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "36",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "37",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "38",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "41",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "42",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "43",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "44",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "45",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "46",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "48",
    toothElementsActive: ["oldEsteticTooth"]
  }]
};
export const esTypicalBottom = {
  tooth: [{
    name: "11",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "12",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "13",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "14",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "15",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "16",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "17",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "18",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "21",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "22",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "23",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "24",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "25",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "26",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "27",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "28",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "31",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "32",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "33",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "34",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "35",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "36",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "37",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "38",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "41",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "42",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "43",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "44",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "45",
    toothElementsActive: ["healthyTooth"]
  }, {
    name: "46",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "47",
    toothElementsActive: ["oldEsteticTooth"]
  }, {
    name: "48",
    toothElementsActive: ["oldEsteticTooth"]
  }]
};
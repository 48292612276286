export function getTargetAlternateKey(stepId) {
  switch (stepId) {
    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopGum":
      return "PSP_ET_e1_e2_adenty_adentyTop";

    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomGum":
      return "PSP_ET_e1_e2_adenty_adentyBottom";

    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothGum":
      return "PSP_ET_e1_e2_adenty_adentyBoth";

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_fixedProsthetics_suspendedToothSelect_extraCrownsSelect_extraVeneersSelect_compositeRestorations_toothConnections_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseTop_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBottom_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBoth_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseTop_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBottom_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBoth_extraCrownsSelect_compositeRestorations_finishStep":
      return "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect";

    default:
      return "PSP_ET_e1_e2_adenty";
  }
}
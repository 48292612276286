import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShow: false,
};

export const mediaLibrarySlice = createSlice({
  name: "mediaLibrary",
  initialState,
  reducers: {
    setMediaLibrary: (state, action) => {
      state.isShow = action.payload;
    },
  },
});

export const { setMediaLibrary } = mediaLibrarySlice.actions;

export default mediaLibrarySlice.reducer;

import { translate } from "../../i18n";

const translates = (lang, type) => {
  const _t = key => translate(key, lang);

  const elements = {
    default: {
      string: _t("Выберите тип обозначения"),
      icon: require("../Formula3DPreview/Formula3DPreviewControls/controlIcons/controlsBase.png")
    },
    ProsthesisTypeSelect: {
      string: _t("Выбор типа протеза"),
      icon: require("../newProject/formula/icons/prosthesisTypeSelect.png")
    },
    healthyTooth: {
      string: _t("Естественный зуб"),
      icon: require("../newProject/formula/icons/healthyTooth.png")
    },
    decayedTooth: {
      string: _t("Разрушенный зуб"),
      icon: require("../newProject/formula/icons/decayedTooth.png")
    },
    oldEsteticTooth: {
      string: _t("Естественный зуб"),
      icon: require("../newProject/formula/icons/healthyTooth.png")
    },
    newEsteticTooth: {
      string: _t("Реставрация"),
      icon: require("../newProject/formula/icons/healthyTooth.png")
    },
    emptyTooth: {
      string: _t("Отсутствующий зуб"),
      icon: require("../newProject/formula/icons/emptyTooth.png")
    },
    plasticCrown: {
      string: _t("Коронка"),
      icon: require("../newProject/formula/icons/plasticCrown.png")
    },
    plasticTooth: {
      string: _t("Подвесной зуб"),
      icon: require("../newProject/formula/icons/plasticTooth.png")
    },
    zirconiumCrown: {
      string: _t("Циркониевая коронка"),
      icon: require("../newProject/formula/icons/zirconiumCrown.png")
    },
    zirconiumTooth: {
      string: _t("Циркониевый зуб"),
      icon: require("../newProject/formula/icons/zirconiumTooth.png")
    },
    metalCeramicCrown: {
      string: _t("Металлокерамическая коронка"),
      icon: require("../newProject/formula/icons/metalCeramicCrown.png")
    },
    metalCeramicTooth: {
      string: _t("Металлокерамический зуб"),
      icon: require("../newProject/formula/icons/metalCeramicTooth.png")
    },
    ceramicCrown: {
      string: _t("Цельнокерамическая коронка"),
      icon: require("../newProject/formula/icons/ceramicCrown.png")
    },
    ceramicTooth: {
      string: _t("Цельнокерамический зуб"),
      icon: require("../newProject/formula/icons/ceramicTooth.png")
    },
    metalCrown: {
      string: _t("Металлическая коронка"),
      icon: require("../newProject/formula/icons/metalCrown.png")
    },
    metalTooth: {
      string: _t("Металлический зуб"),
      icon: require("../newProject/formula/icons/metalTooth.png")
    },
    pinTooth: {
      string: _t("Штифтовый зуб"),
      icon: require("../newProject/formula/icons/pinTooth.png")
    },
    veneerTab: {
      string: _t("Винир"),
      icon: require("../newProject/formula/icons/veneerTab.png")
    },
    overPress: {
      string: _t("Over press"),
      icon: require("../newProject/formula/icons/overPress.png")
    },
    ImplantsTypeSelect: {
      string: _t("Тип фиксации к импланту"),
      icon: require("../newProject/formula/icons/implantsTypeSelect.png")
    },
    multiunitAbutment: {
      string: _t("Мульти-юнит абатмент"),
      icon: require("../newProject/formula/icons/multiunitAbutment.png")
    },
    multiunitZirconiumAbutment: {
      string: _t("Мульти-юнит и супраструктура"),
      icon: require("../newProject/formula/icons/multiunitZirconiumAbutment.png")
    },
    singleStageImplant: {
      string: _t("Одноэтапный имплант"),
      icon: require("../newProject/formula/icons/singleStageImplant.png")
    },
    standardAbutment: {
      string: _t("Стандартный абатмент"),
      icon: require("../newProject/formula/icons/standardAbutment.png")
    },
    individualZirconiumAbutment: {
      string: _t("Абатмент (цементная фиксация)"),
      icon: require("../newProject/formula/icons/individualZirconiumAbutment.png")
    },
    individualTitaniumAbutment: {
      string: _t("Индивидуальный титановый абатмент"),
      icon: require("../newProject/formula/icons/individualTitaniumAbutment.png")
    },
    screwFixationImplants: {
      string: _t("Винтовая фиксация"),
      icon: require("../newProject/formula/icons/screwFixationImplants.png")
    },
    ConnectionsSelect: {
      string: _t("Соединение зубов и коронок"),
      icon: require("../newProject/formula/icons/connections.png")
    },
    connection: {
      string: _t("Соединение"),
      icon: require("../newProject/formula/icons/connections.png")
    },
    SeparationsSelect: {
      string: _t("Сепарации"),
      icon: require("../newProject/formula/icons/separations.png")
    },
    separation: {
      string: _t("Сепарация"),
      icon: require("../newProject/formula/icons/separations.png")
    },
    StumpTabsSelect: {
      string: _t("Культевые вкладки"),
      icon: require("../newProject/formula/icons/stumpTabs.png")
    },
    stumpTabs: {
      string: _t("Культевая вкладка"),
      icon: require("../newProject/formula/icons/stumpTabs.png")
    },
    ToothStumpRestorationSelect: {
      string: _t("Реставрация культи зуба"),
      icon: require("../newProject/formula/icons/toothStumpRestoration.png")
    },
    toothStumpRestoration: {
      string: _t("Реставрация культи зуба"),
      icon: require("../newProject/formula/icons/toothStumpRestoration.png")
    },
    SyntheticGumSelect: {
      string: _t("Искусственная десна"),
      icon: require("../newProject/formula/icons/syntheticGum.png")
    },
    syntheticGum: {
      string: _t("Искусственная десна"),
      icon: require("../newProject/formula/icons/syntheticGum.png")
    },
    BoneRepairSelect: {
      string: _t("Восстановление костной ткани"),
      icon: require("../newProject/formula/icons/boneRepair.png")
    },
    boneRepair: {
      string: _t("Восстановление костной ткани"),
      icon: require("../newProject/formula/icons/boneRepair.png")
    },
    toothRemoval: {
      string: _t("Зуб под удаление"),
      icon: require("../newProject/formula/icons/toothRemoval.png")
    },
    SinusesSelect: {
      string: _t("Выбор синусов"),
      icon: require("../newProject/formula/icons/hightSinuses.png")
    },
    hightSinusesRight: {
      string: _t("Высокие пазухи справа"),
      icon: require("../newProject/formula/icons/hightSinuses.png")
    },
    hightSinusesLeft: {
      string: _t("Высокие пазухи слева"),
      icon: require("../newProject/formula/icons/hightSinuses.png")
    },
    hightSinusesBoth: {
      string: _t("Высокие пазухи обе стороны"),
      icon: require("../newProject/formula/icons/hightSinuses.png")
    },
    hightSinusesClear: {
      string: _t("Высокие пазухи отмена выделения"),
      icon: require("../newProject/formula/icons/hightSinuses.png")
    },
    lowSinusesRight: {
      string: _t("Тонкая кость справа"),
      icon: require("../newProject/formula/icons/lowSinuses.png")
    },
    lowSinusesLeft: {
      string: _t("Тонкая кость слева"),
      icon: require("../newProject/formula/icons/lowSinuses.png")
    },
    lowSinusesBoth: {
      string: _t("Тонкая кость обе стороны"),
      icon: require("../newProject/formula/icons/lowSinuses.png")
    },
    lowSinusesClear: {
      string: _t("Тонкая кость отмена выделения"),
      icon: require("../newProject/formula/icons/lowSinuses.png")
    },
    sinusLiftingRight: {
      string: _t("Синус-лифтинг, справа"),
      icon: require("../newProject/formula/icons/liftingSinuses.png")
    },
    sinusLiftingLeft: {
      string: _t("Синус-лифтинг, слева"),
      icon: require("../newProject/formula/icons/liftingSinuses.png")
    },
    liftingSinusesRight: {
      string: _t("Синус-лифтинг, справа"),
      icon: require("../newProject/formula/icons/liftingSinuses.png")
    },
    liftingSinusesLeft: {
      string: _t("Синус-лифтинг, слева"),
      icon: require("../newProject/formula/icons/liftingSinuses.png")
    },
    liftingSinusesBoth: {
      string: _t("Синус-лифтинг, обе стороны"),
      icon: require("../newProject/formula/icons/liftingSinuses.png")
    },
    liftingSinusesClear: {
      string: _t("Синус-лифтинг, отмена выделения"),
      icon: require("../newProject/formula/icons/liftingSinuses.png")
    },
    smallRight: {
      string: _t("Малое количество кости до нижнечелюстного канала справа"),
      icon: require("../newProject/formula/icons/boneToCanal.png")
    },
    smallLeft: {
      string: _t("Малое количество кости до нижнечелюстного канала слева"),
      icon: require("../newProject/formula/icons/boneToCanal.png")
    },
    smallBoth: {
      string: _t("Малое количество кости до нижнечелюстного канала с обеих сторон"),
      icon: require("../newProject/formula/icons/boneToCanal.png")
    },
    smallClear: {
      string: _t("Малое количество кости до нижнечелюстного канала отмена"),
      icon: require("../newProject/formula/icons/boneToCanal.png")
    },
    largeLeft: {
      string: _t("Большое количество кости до нижнечелюстного канала слева"),
      icon: require("../newProject/formula/icons/boneToCanal.png")
    },
    largeRight: {
      string: _t("Большое количество кости до нижнечелюстного канала справа"),
      icon: require("../newProject/formula/icons/boneToCanal.png")
    },
    largeBoth: {
      string: _t("Большое количество кости до нижнечелюстного канала с обеих сторон"),
      icon: require("../newProject/formula/icons/boneToCanal.png")
    },
    largeClear: {
      string: _t("Большое количество кости до нижнечелюстного канала отмена"),
      icon: require("../newProject/formula/icons/boneToCanal.png")
    },
    jawLeft: {
      string: _t("Заболевание сустава слева"),
      icon: require("../newProject/formula/icons/jaw.png")
    },
    jawRight: {
      string: _t("Заболевание сустава справа"),
      icon: require("../newProject/formula/icons/jaw.png")
    },
    jawBoth: {
      string: _t("Заболевание сустава с обеих сторон"),
      icon: require("../newProject/formula/icons/jaw.png")
    },
    jawClear: {
      string: _t("Заболевание сустава отмена"),
      icon: require("../newProject/formula/icons/jaw.png")
    },
    ImplantPositionSelect: {
      string: _t("Имплант"),
      icon: require("../newProject/formula/icons/implantPosition.png")
    },
    implantPosition: {
      string: _t("Имплант"),
      icon: require("../newProject/formula/icons/implantPosition.png")
    },
    clutchTop: {
      string: _t("Бюгельный протез на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/clutch.png")
    },
    clutchBottom: {
      string: _t("Бюгельный протез на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/clutch.png")
    },
    clutchBoth: {
      string: _t("Бюгельный протез на обе челюсти"),
      icon: require("../newProject/formula/icons/clutch.png")
    },
    acrylicTop: {
      string: _t("Акриловый протез на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/acrylic.png")
    },
    acrylicBottom: {
      string: _t("Акриловый протез на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/acrylic.png")
    },
    acrylicBoth: {
      string: _t("Акриловый протез на обе челюсти"),
      icon: require("../newProject/formula/icons/acrylic.png")
    },
    nylonTop: {
      string: _t("Нейлоновый протез на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/nylon.png")
    },
    nylonBottom: {
      string: _t("Нейлоновый протез на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/nylon.png")
    },
    nylonBoth: {
      string: _t("Нейлоновый протез на обе челюсти"),
      icon: require("../newProject/formula/icons/nylon.png")
    },
    totalTop: {
      string: _t("Полный съемный протез на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/total.png")
    },
    totalBottom: {
      string: _t("Полный съемный протез на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/total.png")
    },
    totalBoth: {
      string: _t("Полный съемный протез на обе челюсти"),
      icon: require("../newProject/formula/icons/total.png")
    },
    capaTop: {
      string: _t("Капа на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/capa.png")
    },
    capaBottom: {
      string: _t("Капа на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/capa.png")
    },
    capaBoth: {
      string: _t("Капа на обе челюсти"),
      icon: require("../newProject/formula/icons/capa.png")
    },
    elinerTop: {
      string: _t("Элайнеры на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/eliner.png")
    },
    elinerBottom: {
      string: _t("Элайнеры на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/eliner.png")
    },
    elinerBoth: {
      string: _t("Элайнер на обе челюсти"),
      icon: require("../newProject/formula/icons/eliner.png")
    },
    orthodonticPlateTop: {
      string: _t("Ортодонтическая пластина на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/orthodonticPlate.png")
    },
    orthodonticPlateBottom: {
      string: _t("Ортодонтическая пластина на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/orthodonticPlate.png")
    },
    orthodonticPlateBoth: {
      string: _t("Ортодонтическая пластина на обе челюсти"),
      icon: require("../newProject/formula/icons/orthodonticPlate.png")
    },
    elastopositioner: {
      string: _t("Эластопозиционер"),
      icon: require("../newProject/formula/icons/elastopositioner.png")
    },
    slideSapphireBracesTop: {
      string: _t("Сапфировые брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/slideSapphireBraces.png")
    },
    slideSapphireBracesBottom: {
      string: _t("Сапфировые брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/slideSapphireBraces.png")
    },
    slideSelfAlignBracesTop: {
      string: _t("Самолигирующие брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/slideSelfAlignBraces.png")
    },
    slideSelfAlignBracesBottom: {
      string: _t("Самолигирующие брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/slideSelfAlignBraces.png")
    },
    slideCeramicBracesTop: {
      string: _t("Керамические брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/slideCeramicBraces.png")
    },
    slideCeramicBracesBottom: {
      string: _t("Керамические брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/slideCeramicBraces.png")
    },
    slideCeramicSelfAlignBracesTop: {
      string: _t("Керамические самолигирующие брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/slideCeramicSelfAlignBraces.png")
    },
    slideCeramicSelfAlignBracesBottom: {
      string: _t("Керамические самолигирующие брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/slideCeramicSelfAlignBraces.png")
    },
    slideMetallBracesTop: {
      string: _t("Металлические брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/slideMetallBraces.png")
    },
    slideMetallBracesBottom: {
      string: _t("Металлические брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/slideMetallBraces.png")
    },
    slideLingualBracesTop: {
      string: _t("Лингвальные брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/slideLingualBraces.png")
    },
    slideLingualBracesBottom: {
      string: _t("Лингвальные брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/slideLingualBraces.png")
    },
    slideMouthAlignerTop: {
      string: _t("Элайнер на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/slideMouthAligner.png")
    },
    slideMouthAlignerBottom: {
      string: _t("Элайнер на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/slideMouthAligner.png")
    },
    metalBracesTop: {
      string: _t("Металлические брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/metalBraces.png")
    },
    metalBracesBottom: {
      string: _t("Металлические брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/metalBraces.png")
    },
    metalBracesBoth: {
      string: _t("Металлические брекеты на обе челюсти"),
      icon: require("../newProject/formula/icons/metalBraces.png")
    },
    ceramicBracesTop: {
      string: _t("Керамические брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/ceramicBraces.png")
    },
    ceramicBracesBottom: {
      string: _t("Керамические брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/ceramicBraces.png")
    },
    ceramicBracesBoth: {
      string: _t("Керамические брекеты на обе челюсти"),
      icon: require("../newProject/formula/icons/ceramicBraces.png")
    },
    sapphireBracesTop: {
      string: _t("Сапфировые брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/sapphireBraces.png")
    },
    sapphireBracesBottom: {
      string: _t("Сапфировые брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/sapphireBraces.png")
    },
    sapphireBracesBoth: {
      string: _t("Сапфировые брекеты на обе челюсти"),
      icon: require("../newProject/formula/icons/sapphireBraces.png")
    },
    plasticBracesTop: {
      string: _t("Пластиковые брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/plasticBraces.png")
    },
    plasticBracesBottom: {
      string: _t("Пластиковые брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/plasticBraces.png")
    },
    plasticBracesBoth: {
      string: _t("Пластиковые брекеты на обе челюсти"),
      icon: require("../newProject/formula/icons/plasticBraces.png")
    },
    lingualBracesTop: {
      string: _t("Лингвальные брекеты на верхнюю челюсть"),
      icon: require("../newProject/formula/icons/lingualBraces.png")
    },
    lingualBracesBottom: {
      string: _t("Лингвальные брекеты на нижнюю челюсть"),
      icon: require("../newProject/formula/icons/lingualBraces.png")
    },
    lingualBracesBoth: {
      string: _t("Лингвальные брекеты на обе челюсти"),
      icon: require("../newProject/formula/icons/lingualBraces.png")
    },
    ProsthesisToothSelect: {
      string: _t("Выбор зубов протеза"),
      icon: require("../newProject/formula/icons/prostheticsTooth.png")
    },
    prostheticsTooth: {
      string: _t("Зуб съемного протеза"),
      icon: require("../newProject/formula/icons/prostheticsTooth.png")
    },
    AttachmentsSelect: {
      string: _t("Выбор аттачментов"),
      icon: require("../newProject/formula/icons/attachments.png")
    },
    attachments: {
      string: _t("Аттачмент"),
      icon: require("../newProject/formula/icons/attachments.png")
    },
    OrthodonticAttachmentsSelect: {
      string: _t("Выбор ортодонтических аттачментов"),
      icon: require("../newProject/formula/icons/orthodonticAttachments.png")
    },
    orthodonticAttachments: {
      string: _t("Ортодонтический аттачмент"),
      icon: require("../newProject/formula/icons/orthodonticAttachments.png")
    },
    TelescopicCrownSelect: {
      string: _t("Выбор телескопических коронок"),
      icon: require("../newProject/formula/icons/telescopicCrown.png")
    },
    telescopicCrown: {
      string: _t("Телескопическая коронка"),
      icon: require("../newProject/formula/icons/telescopicCrown.png")
    },
    DentalBarSelect: {
      string: _t("Выбор балки"),
      icon: require("../newProject/formula/icons/dentalBar.png")
    },
    dentalBar: {
      string: _t("Балка"),
      icon: require("../newProject/formula/icons/dentalBar.png")
    },
    EmptyToothSelect: {
      string: _t("Выбор отсутствующих зубов"),
      icon: require("../newProject/formula/icons/emptyTooth.png")
    },
    FillingsSelect: {
      string: _t("Выбор реставраций и пломб"),
      icon: require("../newProject/formula/icons/compositeFilling.png")
    },
    compositeFilling: {
      string: _t("Композитная реставрация"),
      icon: require("../newProject/formula/icons/compositeFilling.png")
    },
    temporaryFilling: {
      string: _t("Временная пломба"),
      icon: require("../newProject/formula/icons/temporaryFilling.png")
    },
    sealedCanal: {
      string: _t("Пломбированный канал"),
      icon: require("../newProject/formula/icons/sealedCanal.png")
    },
    PathologiesSelect: {
      string: _t("Выбор патологий зубов"),
      icon: require("../newProject/formula/icons/pathologiesSelect.png")
    },
    cariousTooth: {
      string: _t("Кариозный зуб"),
      icon: require("../newProject/formula/icons/cariousTooth.png")
    },
    toothRoot: {
      string: _t("Корень зуба"),
      icon: require("../newProject/formula/icons/toothRoot.png")
    },
    parodontit: {
      string: _t("Пародонтит"),
      icon: require("../newProject/formula/icons/parodontit.png")
    },
    pulpitis: {
      string: _t("Пульпит"),
      icon: require("../newProject/formula/icons/pulpitis.png")
    },
    periodontit: {
      string: _t("Периодонтит"),
      icon: require("../newProject/formula/icons/periodontit.png")
    },
    movableTooth: {
      string: _t("Подвижный зуб"),
      icon: require("../newProject/formula/icons/movableTooth.png")
    },
    resorbedTooth: {
      string: _t("Резекция корня"),
      icon: require("../newProject/formula/icons/resorbedTooth.png")
    },
    topAdentia: {
      string: _t("Адентия верхней челюсти"),
      icon: require("../newProject/formula/icons/adentia.png")
    },
    bottomAdentia: {
      string: _t("Адентия нижней челюсти"),
      icon: require("../newProject/formula/icons/adentia.png")
    },
    bothAdentia: {
      string: _t("Адентия обеих челюстей"),
      icon: require("../newProject/formula/icons/adentia.png")
    },
    cancelAdentia: {
      string: _t("Отмена выделения адентии"),
      icon: require("../newProject/formula/icons/adentia.png")
    },
    MiniImplantsSelect: {
      string: _t("Выбор мини-имплантов"),
      icon: require("../newProject/formula/icons/miniImplant.png")
    },
    miniImplant: {
      string: _t("Мини-имплант"),
      icon: require("../newProject/formula/icons/miniImplant.png")
    },
    ButtonsImplantsSelect: {
      string: _t("Выбор кнопки"),
      icon: require("../newProject/formula/icons/buttonImplant.png")
    },
    buttonImplant: {
      string: _t("Кнопка"),
      icon: require("../newProject/formula/icons/buttonImplant.png")
    },
    cancel: {
      string: _t("Отмена"),
      icon: ""
    },
    TemporaryToothSelect: {
      string: _t("Выбор временного зуба"),
      icon: require("../newProject/formula/icons/temporaryTooth.png")
    },
    temporaryTooth: {
      string: _t("Временный зуб"),
      icon: require("../newProject/formula/icons/temporaryTooth.png")
    },
    boneAugmentation: {
      string: _t("Аугментация"),
      icon: require("../newProject/formula/icons/augmentation.png")
    },
    therapyRestoration: {
      string: _t("Терапевтическая реставрация"),
      icon: require("../newProject/formula/icons/compositeFilling.png")
    },
    treatedCanal: {
      string: _t("Пломбированный канал"),
      icon: require("../newProject/formula/icons/sealedCanal.png")
    },
    badSealedCanal: {
      string: _t("Плохо запломбированный канал"),
      icon: require("../newProject/formula/icons/badSealedCanal.png")
    },
    brokenToolCanal: {
      string: _t("Сломанный инструмент в канале"),
      icon: require("../newProject/formula/icons/brokenToolCanal.png")
    }
  }; // If we can't find anything

  if (!elements[type]) {
    console.error(`Type is not exist: ${type}`);
    return {
      string: "not exist",
      icon: ""
    };
  }

  return elements[type];
};

export default translates;
import logo from "./assets/logo.png";
import "./index.scss";
import { Link } from "react-router-dom";

function LogoBlock() {
  return (
    <Link className="logoBlock" to="/">
      <img src={logo} className="logoBlock-img" alt="" />
      <div className="logoBlock-text">
        <div className="logoBlock-text-title">
          <strong>Dental Bot</strong> CONSULTANT
        </div>
        <div className="logoBlock-text-subTitle">
          by <strong>colleagial</strong>
        </div>
      </div>
    </Link>
  );
}

export default LogoBlock;

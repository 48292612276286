import { commonApi } from "./commonApi";

export const profileApi = commonApi.injectEndpoints({
  tagTypes: ["Profile"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/user/auth",
        method: "POST",
        body: credentials,
      }),
    }),
    createProfile: builder.mutation({
      query: (data) => ({
        url: "/user/create",
        method: "POST",
        body: data,
      }),
    }),
    submitProfile: builder.mutation({
      query: (data) => ({
        url: "/user/submit",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Profile", "PackageDetails"],
    }),
    submitResendProfile: builder.mutation({
      query: () => ({
        url: "/user/submit_resend",
        method: "POST",
      }),
    }),
    getProfile: builder.query({
      query: () => ({
        url: "/user/info",
      }),
      providesTags: ["Profile"],
      transformResponse(response) {
        return response.data;
      },
    }),
    updateProfile: builder.mutation({
      query: (data) => ({
        url: "/user/info",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Profile"],
    }),
  }),
});

export const {
  useLoginMutation,
  useCreateProfileMutation,
  useSubmitProfileMutation,
  useSubmitResendProfileMutation,
  useGetProfileQuery,
  useUpdateProfileMutation,
} = profileApi;

export const gapsTypes = [{
  name: "1112",
  position: "top",
  section: 1,
  actionBtn: {
    position: {
      x: 221,
      y: 41,
      width: 58,
      height: 81,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 269,
      y: 127,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 221,
      y: 41,
      width: 58,
      height: 81,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 153,
      y: 36,
      width: 27,
      height: 46,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "2111",
  position: "top",
  section: 1,
  actionBtn: {
    position: {
      x: 323,
      y: 13,
      width: 57,
      height: 87,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 335,
      y: 109,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 323,
      y: 13,
      width: 57,
      height: 87,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 197,
      y: 28,
      width: 18,
      height: 48,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "1213",
  position: "top",
  section: 1,
  actionBtn: {
    position: {
      x: 161,
      y: 83,
      width: 68,
      height: 73,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 223,
      y: 157,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 161,
      y: 83,
      width: 68,
      height: 73,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 121,
      y: 56,
      width: 37,
      height: 39,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "1314",
  position: "top",
  section: 1,
  actionBtn: {
    position: {
      x: 109,
      y: 150,
      width: 79,
      height: 62,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 193,
      y: 208,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 109,
      y: 150,
      width: 79,
      height: 62,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 95,
      y: 97,
      width: 47,
      height: 24,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "1415",
  position: "top",
  section: 1,
  actionBtn: {
    position: {
      x: 81,
      y: 215,
      width: 84,
      height: 57,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 174,
      y: 261,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 81,
      y: 215,
      width: 84,
      height: 57,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 85,
      y: 123,
      width: 47,
      height: 24,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "1516",
  position: "top",
  section: 1,
  actionBtn: {
    position: {
      x: 59,
      y: 273,
      width: 86,
      height: 58,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 160,
      y: 313,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 59,
      y: 273,
      width: 86,
      height: 58,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 77,
      y: 153,
      width: 47,
      height: 20,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "1617",
  position: "top",
  section: 1,
  actionBtn: {
    position: {
      x: 42,
      y: 355,
      width: 86,
      height: 57,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 140,
      y: 392,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 42,
      y: 355,
      width: 86,
      height: 57,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 69,
      y: 194,
      width: 47,
      height: 20,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "1718",
  position: "top",
  section: 1,
  actionBtn: {
    position: {
      x: 34,
      y: 433,
      width: 88,
      height: 56,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 130,
      y: 464,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 34,
      y: 433,
      width: 88,
      height: 56,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 66,
      y: 236,
      width: 46,
      height: 16,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "2221",
  position: "top",
  section: 2,
  actionBtn: {
    position: {
      x: 426,
      y: 41,
      width: 58,
      height: 81,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 401,
      y: 127,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 426,
      y: 41,
      width: 58,
      height: 81,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 235,
      y: 36,
      width: 27,
      height: 46,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "2322",
  position: "top",
  section: 2,
  actionBtn: {
    position: {
      x: 475,
      y: 83,
      width: 68,
      height: 73,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 447,
      y: 157,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 475,
      y: 83,
      width: 68,
      height: 73,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 258,
      y: 56,
      width: 37,
      height: 39,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "2423",
  position: "top",
  section: 2,
  actionBtn: {
    position: {
      x: 515,
      y: 150,
      width: 79,
      height: 62,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 477,
      y: 208,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 515,
      y: 150,
      width: 79,
      height: 62,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 274,
      y: 97,
      width: 47,
      height: 24,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "2524",
  position: "top",
  section: 2,
  actionBtn: {
    position: {
      x: 539,
      y: 215,
      width: 84,
      height: 57,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 496,
      y: 261,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 539,
      y: 215,
      width: 84,
      height: 57,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 285,
      y: 123,
      width: 47,
      height: 24,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "2625",
  position: "top",
  section: 2,
  actionBtn: {
    position: {
      x: 559,
      y: 272,
      width: 86,
      height: 58,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 510,
      y: 313,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 559,
      y: 272,
      width: 86,
      height: 58,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 290,
      y: 153,
      width: 47,
      height: 20,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "2726",
  position: "top",
  section: 2,
  actionBtn: {
    position: {
      x: 575,
      y: 355,
      width: 86,
      height: 57,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 530,
      y: 392,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 575,
      y: 355,
      width: 86,
      height: 57,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 298,
      y: 194,
      width: 47,
      height: 20,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "2827",
  position: "top",
  section: 2,
  actionBtn: {
    position: {
      x: 582,
      y: 432,
      width: 88,
      height: 56,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 540,
      y: 464,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 582,
      y: 432,
      width: 88,
      height: 56,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 304,
      y: 236,
      width: 46,
      height: 16,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "3132",
  position: "bottom",
  section: 3,
  actionBtn: {
    position: {
      x: 387,
      y: 1011,
      width: 57,
      height: 83,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 371,
      y: 978,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 387,
      y: 1011,
      width: 57,
      height: 83,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 224,
      y: 507,
      width: 24,
      height: 47,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "3233",
  position: "bottom",
  section: 3,
  actionBtn: {
    position: {
      x: 431,
      y: 994,
      width: 69,
      height: 73,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 409,
      y: 960,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 431,
      y: 994,
      width: 69,
      height: 73,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 246,
      y: 498,
      width: 31,
      height: 43,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "3334",
  position: "bottom",
  section: 3,
  actionBtn: {
    position: {
      x: 469,
      y: 955,
      width: 81,
      height: 61,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 439,
      y: 930,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 469,
      y: 955,
      width: 81,
      height: 61,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 264,
      y: 479,
      width: 40,
      height: 36,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "3435",
  position: "bottom",
  section: 3,
  actionBtn: {
    position: {
      x: 493,
      y: 900,
      width: 86,
      height: 57,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 461,
      y: 885,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 493,
      y: 900,
      width: 86,
      height: 57,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 274,
      y: 455,
      width: 47,
      height: 26,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "3536",
  position: "bottom",
  section: 3,
  actionBtn: {
    position: {
      x: 515,
      y: 848,
      width: 87,
      height: 57,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 477,
      y: 830,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 515,
      y: 848,
      width: 87,
      height: 57,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 283,
      y: 427,
      width: 47,
      height: 26,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "3637",
  position: "bottom",
  section: 3,
  actionBtn: {
    position: {
      x: 545,
      y: 761,
      width: 88,
      height: 58,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 497,
      y: 753,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 545,
      y: 761,
      width: 88,
      height: 58,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 292,
      y: 383,
      width: 47,
      height: 22,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "3738",
  position: "bottom",
  section: 3,
  actionBtn: {
    position: {
      x: 561,
      y: 677,
      width: 90,
      height: 57,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 513,
      y: 680,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 561,
      y: 677,
      width: 90,
      height: 57,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 299,
      y: 344,
      width: 47,
      height: 19,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "4131",
  position: "bottom",
  section: 3,
  actionBtn: {
    position: {
      x: 325,
      y: 1018,
      width: 57,
      height: 87,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 335,
      y: 984,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 325,
      y: 1018,
      width: 57,
      height: 87,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 198,
      y: 510,
      width: 18,
      height: 49,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "4241",
  position: "bottom",
  section: 4,
  actionBtn: {
    position: {
      x: 258,
      y: 1013,
      width: 57,
      height: 82,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 298,
      y: 978,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 258,
      y: 1013,
      width: 57,
      height: 82,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 169,
      y: 507,
      width: 24,
      height: 47,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "4342",
  position: "bottom",
  section: 4,
  actionBtn: {
    position: {
      x: 201,
      y: 994,
      width: 69,
      height: 73,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 261,
      y: 960,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 201,
      y: 994,
      width: 69,
      height: 73,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 139,
      y: 498,
      width: 31,
      height: 43,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "4443",
  position: "bottom",
  section: 4,
  actionBtn: {
    position: {
      x: 152,
      y: 955,
      width: 81,
      height: 61,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 231,
      y: 930,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 152,
      y: 955,
      width: 81,
      height: 61,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 112,
      y: 479,
      width: 40,
      height: 36,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "4544",
  position: "bottom",
  section: 4,
  actionBtn: {
    position: {
      x: 124,
      y: 901,
      width: 86,
      height: 57,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 209,
      y: 885,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 124,
      y: 901,
      width: 86,
      height: 57,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 94,
      y: 455,
      width: 47,
      height: 26,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "4645",
  position: "bottom",
  section: 4,
  actionBtn: {
    position: {
      x: 101,
      y: 847,
      width: 87,
      height: 57,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 193,
      y: 830,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 101,
      y: 847,
      width: 87,
      height: 57,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 87,
      y: 427,
      width: 47,
      height: 26,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "4746",
  position: "bottom",
  section: 4,
  actionBtn: {
    position: {
      x: 71,
      y: 761,
      width: 88,
      height: 58,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 173,
      y: 753,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 71,
      y: 761,
      width: 88,
      height: 58,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 77,
      y: 383,
      width: 47,
      height: 22,
      zIndex: 1
    },
    isActive: false
  }
}, {
  name: "4847",
  position: "bottom",
  section: 4,
  actionBtn: {
    position: {
      x: 54,
      y: 677,
      width: 90,
      height: 57,
      zIndex: 3
    },
    isActive: false,
    type: "activeBtn"
  },
  connection: {
    position: {
      x: 157,
      y: 680,
      width: 35,
      height: 35,
      zIndex: 2
    },
    isActive: false
  },
  separation: {
    position: {
      x: 54,
      y: 677,
      width: 90,
      height: 57,
      zIndex: 3
    },
    isActive: false
  },
  miniImplant: {
    position: {
      x: 69,
      y: 344,
      width: 47,
      height: 19,
      zIndex: 1
    },
    isActive: false
  }
}];
export const availableConnectionsTypes = {
  "11": ["1112", "2111"],
  "12": ["1213", "1112"],
  "13": ["1314", "1213"],
  "14": ["1415", "1314"],
  "15": ["1516", "1415"],
  "16": ["1617", "1516"],
  "17": ["1718", "1617"],
  "18": ["1718"],
  "21": ["2221", "2111"],
  "22": ["2322", "2221"],
  "23": ["2423", "2322"],
  "24": ["2524", "2423"],
  "25": ["2625", "2524"],
  "26": ["2726", "2625"],
  "27": ["2827", "2726"],
  "28": ["2827"],
  "31": ["4131", "3132"],
  "32": ["3132", "3233"],
  "33": ["3233", "3334"],
  "34": ["3334", "3435"],
  "35": ["3435", "3536"],
  "36": ["3536", "3637"],
  "37": ["3637", "3738"],
  "38": ["3738"],
  "41": ["4241", "4131"],
  "42": ["4342", "4241"],
  "43": ["4443", "4342"],
  "44": ["4544", "4443"],
  "45": ["4645", "4544"],
  "46": ["4746", "4645"],
  "47": ["4847", "4746"],
  "48": ["4847"]
};
const surgical2DMarksList = ["healthyTooth", "oldEsteticTooth", "newEsteticTooth", "emptyTooth", "implantPosition", "boneAugmentation"];
export function surgicalPart2DFilter(formula) {
  function filterTooth(toothList) {
    if (!toothList) {
      return [];
    }

    return toothList.map(toothInfo => {
      return { ...toothInfo,
        toothElementsActive: toothInfo.toothElementsActive.filter(el => surgical2DMarksList.includes(el))
      };
    });
  }

  return {
    tooth: filterTooth(formula.tooth),
    prosthesis: formula.prosthesis || [],
    sinuses: formula.sinuses || [],
    gaps: [],
    jointDisease: []
  };
}
const surgicalTotalInfoList = ["boneAugmentation"];
export function surgicalPartTotalInfoFilter(formula) {
  function filterTooth(toothList) {
    if (!toothList) {
      return [];
    }

    return toothList.map(toothInfo => {
      return { ...toothInfo,
        toothElementsActive: toothInfo.toothElementsActive.filter(el => surgicalTotalInfoList.includes(el))
      };
    });
  }

  return {
    tooth: filterTooth(formula.tooth),
    prosthesis: [],
    sinuses: formula.sinuses || [],
    gaps: [],
    jointDisease: []
  };
}
const surgicalBillList = ["boneAugmentation"];
export function surgicalPartBillFilter(formula) {
  function filterTooth(toothList) {
    if (!toothList) {
      return [];
    }

    return toothList.map(toothInfo => {
      return { ...toothInfo,
        toothElementsActive: toothInfo.toothElementsActive.filter(el => surgicalBillList.includes(el))
      };
    });
  }

  return {
    tooth: filterTooth(formula.tooth),
    prosthesis: [],
    sinuses: formula.sinuses || [],
    gaps: [],
    jointDisease: []
  };
}
export function getAvailableSidesByStep(stepId) {
  switch (stepId) {
    //Extra crowns
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation_extraCrownsSelect":
      return ["top", "bottom", "base", "implants", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation_extraCrownsSelect":
      return ["top", "bottom", "base", "implants", "abutments", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation_extraCrownsSelect":
      return ["top", "bottom", "base", "implants", "multiunitAbutments", "screws", "fixedProstheses"];
    //ExtraVeneers

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation_extraCrownsSelect_extraVeneersSelect":
      return ["top", "bottom", "base", "implants", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation_extraCrownsSelect_extraVeneersSelect":
      return ["top", "bottom", "base", "implants", "abutments", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation_extraCrownsSelect_extraVeneersSelect":
      return ["top", "bottom", "base", "implants", "multiunitAbutments", "screws", "fixedProstheses"];
    //ImplantationSelectFalseTeeth

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation":
      return ["top", "bottom", "base", "implants", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation":
      return ["top", "bottom", "base", "implants", "abutments", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation":
      return ["top", "bottom", "base", "implants", "multiunitAbutments", "screws", "fixedProstheses"];
    //ImplantationSelectFalseTeethConnections

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection":
      return ["top", "bottom", "base", "implants", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection":
      return ["top", "bottom", "base", "implants", "abutments", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection":
      return ["top", "bottom", "base", "implants", "multiunitAbutments", "screws", "fixedProstheses"];
    //Finish step

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_screwFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection_finishStep":
      return ["top", "bottom", "base", "implants", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_cementFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection_finishStep":
      return ["top", "bottom", "base", "implants", "abutments", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_implantation_implantFixation_multiunutFixation_extraCrownsSelect_extraVeneersSelect_compositeRestorations_implantationSelectFalseTeethConnection_finishStep":
      return ["top", "bottom", "base", "implants", "multiunitAbutments", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant4_cementImplantProsthesisFixationTop4":
    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant6_cementImplantProsthesisFixationTop6":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant4_cementImplantProsthesisFixationBottom4":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant6_cementImplantProsthesisFixationBottom6":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant4_cementImplantProsthesisFixationBoth4":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant6_cementImplantProsthesisFixationBoth6":
      return ["top", "bottom", "base", "implants", "abutments", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant4_multiunitImplantProsthesisFixationTop4":
    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant6_multiunitImplantProsthesisFixationTop6":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant4_multiunitImplantProsthesisFixationBottom4":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant6_multiunitImplantProsthesisFixationBottom6":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant4_multiunitImplantProsthesisFixationBoth4":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant6_multiunitImplantProsthesisFixationBoth6":
      return ["top", "bottom", "base", "implants", "multiunitAbutments", "screws", "fixedProstheses"];

    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant4_telescopImplantProsthesisFixationTop4":
    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopImplant_adentyImplantProsthesisFixation_adentyTopImplant6_telescopImplantProsthesisFixationTop6":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant4_telescopImplantProsthesisFixationBottom4":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomImplant_adentyImplantProsthesisFixation_adentyBottomImplant6_telescopImplantProsthesisFixationBottom6":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant4_telescopImplantProsthesisFixationBoth4":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothImplant_adentyImplantProsthesisFixation_adentyBothImplant6_telescopImplantProsthesisFixationBoth6":
      return ["top", "bottom", "base", "implants", "abutments", "screws", "fixedProstheses", "removableProsthetics"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseTop_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBottom_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_bugelBase_bugelBaseBoth_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseTop_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBottom_extraCrownsSelect_compositeRestorations_finishStep":
    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_removableProsthetics_plasticBase_plasticBaseBoth_extraCrownsSelect_compositeRestorations_finishStep":
      return ["top", "bottom", "base", "fixedProstheses", "removableProsthetics"];

    case "PSP_ET_e1_e2_adenty_adentyTop_adentyTopGum":
    case "PSP_ET_e1_e2_adenty_adentyBottom_adentyBottomGum":
    case "PSP_ET_e1_e2_adenty_adentyBoth_adentyBothGum":
      return ["top", "bottom", "base", "removableProsthetics"];

    case "PSP_ET_e1_e2_adenty_partial_decayedTeeth_prostheticsPrepare_prosthesisSelect_fixedProsthetics_suspendedToothSelect_extraCrownsSelect_extraVeneersSelect_compositeRestorations_toothConnections_finishStep":
    case "PSP_EM_e1_e2_estetic_esCustomVeneers_esCustomCrowns_esCustomRestorations_smileSelect_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical6Top_esTypicalVeneers_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical8Top_esTypicalVeneers_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical10Top_esTypicalVeneers_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypicalBottom_esTypicalVeneers_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical6Top_esTypicalCrowns_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical8Top_esTypicalCrowns_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical10Top_esTypicalCrowns_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypicalBottom_esTypicalCrowns_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical6Top_esTypicalRestorations_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical8Top_esTypicalRestorations_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypical10Top_esTypicalRestorations_finishStep":
    case "PSP_EM_e1_e2_estetic_esTypicalSelect_esTypicalBottom_esTypicalRestorations_finishStep":
      return ["top", "bottom", "base", "fixedProstheses"];

    case "PSP_OM_e1_e2_orthodonticSelect_orthodonticTreatment_orthoBasicTreatment_finishStep":
    case "PSP_OM_e1_e2_orthodonticSelect_orthodonticTreatment_orthoChildTreatment_singleJawApparate_finishStep":
    case "PSP_OM_e1_e2_orthodonticSelect_orthodonticTreatment_orthoChildTreatment_doubleJawApparate_finishStep":
      return ["top", "bottom", "base", "orthoSystems"];

    case "PSP_lowSinus_sinusLifting_boneAugmentation_finishStep":
      return ["softTissue", "skull", "prosthesisAll", "implants"];

    case "PSP_TM_e1_e2_therapySelect":
    case "PSP_TM_e1_e2_therapySelect_toothDetailing":
    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing":
    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView":
    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment":
    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment_treatmentView":
    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment_treatmentView_restorationSelect":
    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment_treatmentView_restorationSelect_restorationDetailing":
    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment_treatmentView_restorationSelect_restorationDetailing_canalFilling":
    case "PSP_TM_e1_e2_therapySelect_toothDetailing_rootDetailing_clinicalView_autoTreatment_treatmentView_restorationSelect_restorationDetailing_canalFilling_finishStep":
      return ["top", "bottom"];

    default:
      return ["top", "bottom", "base"];
  }
}
 const metaList = {
    "/": {
        en: {
            title: "Home",
            desc: '',
            keys: "Ukraine, Ukrainian business, Dentistry, Support Ukraine, Dentist's consultation",
            url: "/",
            // img: require("../assets/images/post.jpg"),
        },
    },
     "/sign-in": {
         en: {
             title: "Sign in",
             desc: '',
             keys: "Ukraine, Ukrainian business, Dentistry, Support Ukraine, Dentist's consultation",
             url: "/sign-in",
             // img: require("../assets/images/post.jpg"),
         },
     },
     "/sign-up": {
         en: {
             title: "Sign up",
             desc: '',
             keys: "Ukraine, Ukrainian business, Dentistry, Support Ukraine, Dentist's consultation",
             url: "/sign-up",
             // img: require("../assets/images/post.jpg"),
         },
     },
     "/account": {
         en: {
             title: "Account",
             desc: '',
             keys: "Ukraine, Ukrainian business, Dentistry, Support Ukraine, Dentist's consultation",
             url: "/account",
             // img: require("../assets/images/post.jpg"),
         },
     },
};

export default metaList
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShow: false,
};

export const screenSaverSlice = createSlice({
  name: "screenSaver",
  initialState,
  reducers: {
    setScreenSaver: (state, action) => {
      state.isShow = action.payload;
    },
  },
});

export const { setScreenSaver } = screenSaverSlice.actions;

export default screenSaverSlice.reducer;

import { prosthesisTypes } from "./prosthesis";
import { gapsTypes } from "./gaps";
const top = ["11", "12", "13", "14", "15", "16", "17", "18", "21", "22", "23", "24", "25", "26", "27", "28"];

function getPositionByName(name) {
  if (top.includes(name)) {
    return "top";
  }

  return "bottom";
}

function getToothSection(name) {
  let elId = parseInt(name);

  if (elId < 20) {
    return 1;
  } else if (elId < 30) {
    return 2;
  } else if (elId < 40) {
    return 3;
  } else {
    return 4;
  }
}

function getGapsSection(name) {
  let gap = gapsTypes.find(el => el.name === name);
  return gap ? gap.section : 1;
}

const FormulaTotalInformationData = ({
  tooth,
  prosthesis,
  jointDisease,
  gaps
}) => {
  let prostheticsTooth = {
    top: [],
    bottom: []
  };
  let infoTooth = [];

  if (tooth) {
    infoTooth = tooth.map(el => {
      if (!el.hasOwnProperty("position")) {
        return { ...el,
          position: getPositionByName(el.name)
        };
      }

      return el;
    });
    infoTooth = infoTooth.map(el => {
      let toothElementsActive = [...el.toothElementsActive];

      if (toothElementsActive.includes("prostheticsTooth")) {
        toothElementsActive = toothElementsActive.filter(el => el !== "prostheticsTooth");
        prostheticsTooth[el.position].push(el.name);
      }

      if (toothElementsActive.includes("healthyTooth")) {
        // if( toothElementsActive.length === 1){}
        toothElementsActive = toothElementsActive.filter(el => el !== "healthyTooth");
      } // If need to remove old estetic tooth


      if (toothElementsActive.includes("oldEsteticTooth")) {
        toothElementsActive = toothElementsActive.filter(el => el !== "oldEsteticTooth");
      }

      return { ...el,
        elSection: getToothSection(el.name),
        toothElementsActive
      };
    }).filter(item => item.toothElementsActive.length > 0);
  }

  const upperJaw = infoTooth.filter(item => item.position === "top");
  const lowerJaw = infoTooth.filter(item => item.position === "bottom");
  const prosthesisTop = !prosthesis ? [] : prosthesis.filter(el => prosthesisTypes[el].position === "top");
  const prosthesisBottom = !prosthesis ? [] : prosthesis.filter(el => prosthesisTypes[el].position === "bottom");
  let jointDiseaseList = [];

  if (jointDisease) {
    jointDiseaseList = jointDisease.filter(item => ["jawLeft", "jawRight"].indexOf(item) !== -1);
  }

  let gapsList = [];

  if (gaps) {
    gapsList = gaps.map(item => {
      let activity = [];

      if (typeof item.separation === "boolean") {
        if (item.separation) {
          activity.push("separation");
        }
      } else if (typeof item.separation === "object") {
        if (item.separation.isActive) {
          activity.push("separation");
        }
      }

      if (typeof item.connection === "boolean") {
        if (item.connection) {
          activity.push("connection");
        }
      } else if (typeof item.connection === "object") {
        if (item.connection.isActive) {
          activity.push("connection");
        }
      }

      if (typeof item.miniImplant === "boolean") {
        if (item.miniImplant) {
          activity.push("miniImplant");
        }
      } else if (typeof item.miniImplant === "object") {
        if (item.miniImplant.isActive) {
          activity.push("miniImplant");
        }
      }

      return { ...item,
        activity,
        elSection: getGapsSection(item.name)
      };
    });
  }

  const gapsTop = gapsList.filter(el => el.position === "top" && el.activity.length);
  const gapsBottom = gapsList.filter(el => el.position === "bottom" && el.activity.length);
  return {
    upperJaw,
    lowerJaw,
    prostheticsTooth,
    prosthesisTop,
    prosthesisBottom,
    jointDiseaseList,
    gapsTop,
    gapsBottom
  };
};

export default FormulaTotalInformationData;